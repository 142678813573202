import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IScore} from '../interfaces/journal/score.interface';


@Pipe({
  name: 'sortMarks'
})
export class SortMarksPipe implements PipeTransform {

  transform(marks: IScore<{
    id: number,
    is_score: boolean,
    name: string,
    short_name: string
  }>[], ...args: unknown[]): unknown {
    const sorted_marks = _.orderBy(marks, ['score_type'], ['asc']);
    return sorted_marks;
  }

}
