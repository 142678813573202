import { AfterViewInit, Component, EventEmitter, forwardRef, Injectable, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const I18N_VALUES = {
  ua: {
    weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'],
    months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
  }
};

// Define a service holding the language. You probably already have one if your app is i18ned.
@Injectable()
export class I18n {
  language = 'ua';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct) {
    return '';
  }
}

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerI18nComponent),
  multi: true
};


@Component({
  selector: 'datepicker-i18n',
  templateUrl: './datepicker-i18n.component.html',
  styleUrls: ['./datepicker-i18n.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class DatepickerI18nComponent  {

  @Input() minDate: {
    year: number, month: number, day: number
  };
  @Input() maxDate: {
    year: number, month: number, day: number
  };

  value: string | NgbDate = '';

  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _i18n: I18n) {
  }

  onChange = (item) => {
  };

  onTouched = () => {

  };

  registerOnChange(fn: (item) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(v): void {
    if (v instanceof NgbDate) {
      this.value = v;
    }

    this.onChange(this.value);
  }

  set language(language: string) {
    this._i18n.language = language;
  }

  get language() {
    return this._i18n.language;
  }

}
