import {Base} from '../base.model';
import {ParentModel} from '../users/parent.model';
import {ServiceModel} from './service.model';
import {ServiceErrorModel} from './serviceError.model';

export class StudentBalanceModel extends Base {

  uuid: string;
  first_name: string;
  last_name: string;
  surname: string;
  gender: string;
  is_active: boolean;
  photo: string;
  phone: string;
  parents: ParentModel[];
  services: ServiceModel[];
  service_errors: ServiceErrorModel[];
  class: any;
  email: string;
  birthday: string;
  student_account: {
    external_id: number,
    count_payments: number,
    guarant_payment: number,
    discount: number,
    discount_other: boolean,
  };
  is_edit: boolean;

  constructor(items) {
    super();
    if (items.parents) {
      super.loadRelations({
        parents: [ParentModel],
      }, items);
    }

    if (items.services) {
      super.loadRelations({
        services: [ServiceModel],
      }, items);
    }

    if (items.service_errors) {
      super.loadRelations({
        service_errors: [ServiceErrorModel],
      }, items);
    }

    super.loadFields(items);
  }
}
