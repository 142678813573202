import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AuthService} from '../../+shared/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {ManagementService} from '../../functional-modules/management/management.service';
import {SchoolSettingsModel} from '../../+shared/models/school-management/school-settings.model';
import {HelperService} from '../../+shared/services/helper.service';

@Component({
  templateUrl: './set-new-password.component.html',
  styleUrls: ['../../+shared/style/auth-shared.scss', '../password-recovery.component.scss']
})
export class SetNewPasswordComponent implements OnInit {

  form: FormGroup;
  formError: any = {
    required: HelperService.formErrors.requiredField
  };
  emailKey = '';
  isMarkedErrors = false;
  wasSent = false;
  backUrl: string;
  schoolInfo: SchoolSettingsModel;

  constructor(public fb: FormBuilder,
              public router: Router,
              public activatedRouter: ActivatedRoute,
              public userService: AuthService,
              public managementService: ManagementService) {
    this.managementService.setMetaTitle('Відновлення паролю');
  }

  ngOnInit(): void {
    this.activatedRouter.params.subscribe(params => {
      this.emailKey = params['key'];
    });

    this.activatedRouter.params.pipe(switchMap(({backUrl}) => {
      this.backUrl = backUrl || '/';
      return this.managementService.getSchoolSettings();
    })).subscribe((school_info) => {
      this.schoolInfo = school_info;
    });

    this.form = this.fb.group({
      newPasswordSection: this.fb.group({
          newPassword: ['', Validators.compose([Validators.required, Validators.pattern(HelperService.passwordPattern)])],
          checkNewPassword: ['', Validators.required]
        },
        {
          validator: this.checkNewPasswords('newPassword', 'checkNewPassword')
        })
    });
  }

  getFieldState(field) {
    if (!this.form || !this.isMarkedErrors) {
      return null;
    }
    const form = this.form.get('newPasswordSection');
    return form.get(field).valid && form.valid;
  }

  checkNewPasswords = (newPas, confirmPas): ValidatorFn => (control: AbstractControl) => {
    if (control.get(newPas).value === control.get(confirmPas).value) {
      return null;
    }
    return {newPasswordsValidation: {same: false}};
  }

  getErrorMessage(control) {
    const controlHasError = this.form.get('newPasswordSection').get(control).errors;
    const groupHasError = this.form.get('newPasswordSection').errors;
    if (controlHasError && controlHasError.pattern) {
      if (this.form.get('newPasswordSection').get(control).value.length < 6) {
        return HelperService.passwordLengthError;
      } else {
        return HelperService.passwordError;
      }
    } else if (controlHasError && controlHasError.required) {
      return this.formError.required;
    } else if (!controlHasError && groupHasError && groupHasError.newPasswordsValidation) {
      return 'Паролі не співпадають';
    }
  }

  send() {
    if (this.form.valid) {
      const password = this.form.get('newPasswordSection').get('newPassword').value;

      this.userService.updateForgetPassword(password, this.emailKey).subscribe(response => {
        this.wasSent = true;
      }, error => {
        alert('Помилка. Спробуйте надіслати код для відновлення паролю щераз.');
      });
      return;
    } else {
      this.isMarkedErrors = true;
    }
  }

}
