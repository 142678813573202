import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RootService } from '../../../../../+shared/services/root.service';

@Component({
  selector: 'class-schedule-header',
  templateUrl: './class-schedule-header.component.html',
  styleUrls: ['./class-schedule-header.component.scss']
})
export class ClassScheduleHeaderComponent {

  @Input() data: any;

  @Output() onBtnClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(public rootService: RootService) { }

  onBtnClickHandler(index) {
    this.onBtnClick.emit(index);
  }

}
