import { Injectable } from '@angular/core';
import { AjaxService } from '../../+shared/services/ajax.service';
import { RootService } from '../../+shared/services/root.service';
import { AuthService } from '../../+shared/services/auth.service';
import { NgxSmartModalService } from 'ngx-smart-modal';


@Injectable()
export class ParentsService {

  tabs = [];

  constructor(public ajaxService: AjaxService,
              public rootService: RootService,
              public authService: AuthService,
              public ngxSmartModalService: NgxSmartModalService) {

    this.tabs = [
      {
        path: this.getSectionPath('list/teachers'),
        name: 'Вчителі',
      },
      {
        path: this.getSectionPath('list/staff-administration'),
        name: 'Адміністрація',
      },
      {
        path: this.getSectionPath('list/staff-service'),
        name: 'Обслуговуючий персонал',
      },
      {
        path: this.getSectionPath('list/staff-technical'),
        name: 'Інші фахівці',
      }
    ];
  }

  getSectionPath(path) {
    const section = `${this.rootService.role.getSectionPath()}/parents`;
    return `${section}/${path}`;
  }

}

