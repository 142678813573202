import {HelperService} from '../services/helper.service';
import * as moment from 'moment';

export class Base {

  loadFields(itemData?) {
    if (itemData !== undefined) {
      for (const index in itemData) {
        let attr = itemData[index];

        if (typeof attr === 'number') {
          attr = String(attr);
        }

        this[index] = attr === null ? '' : attr;
      }
    }
  }

  loadRelations(obj, itemData) {
    for (const index in obj) {
      const attr = obj[index];

      if (typeof attr === 'function') {
        itemData[index] = new attr(itemData[index]);
      } else {

        // object
        const objArrays = [];
        if (itemData[index]) {
          itemData[index].forEach(i => {
            const model = new attr[0](i);
            objArrays.push(model);
          });
        } else {
          if (attr.length) {
            objArrays.push(new attr[0]());
          }
        }
        itemData[index] = objArrays;
      }
    }
  }

  formatPhone(propName) {
    this[propName] = HelperService.formatPhone(this[propName]);
  }

  formatDate(date, format) {
    if (date) {
      const [year, month, day] = date.split('-');
      return moment(new Date(`${year}/${month}/${day}`)).locale('uk').format(format);
    }
  }
}
