import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {SchoolSettingsModel} from '../../../models/school-management/school-settings.model';
import {Subscription} from 'rxjs';
import {RootService} from '../../../services/root.service';
import * as _ from 'lodash';
import {HelperService} from '../../../services/helper.service';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  selector: 'app-general-info',
  templateUrl: './school-profile.component.html',
  styleUrls: ['./school-profile.component.scss', '../../../style/modules-shared.scss']
})
export class SchoolProfileComponent implements OnInit, OnDestroy {

  loaded = false;
  info: SchoolSettingsModel;
  loadingSubscription: Subscription;
  daysArray = [
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    'Сб',
    'Нд'
  ];

  constructor(public fb: FormBuilder,
              public rootService: RootService,
              public ngxSmartModalService: NgxSmartModalService,
              public changeDetectorRef: ChangeDetectorRef,
              public managementService: ManagementService) {
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnDestroy() {
    this.dropLoading();
  }

  load() {
    this.loaded = false;
    HelperService.upScroll();
    this.loadingSubscription = this.managementService.getSchoolSettings().subscribe((data) => {
      this.info = new SchoolSettingsModel(data);
      this.loaded = true;
    });
  }

  dropLoading() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
      this.loadingSubscription = null;
    }
  }

  edit() {
    this.ngxSmartModalService.resetModalData('general-info-popup');
    this.ngxSmartModalService.getModal('general-info-popup').setData({
      onSave: () => {
        this.load();
      },
    });
    this.ngxSmartModalService.getModal('general-info-popup').open();
  }

  get workDays() {
    if (!this.info.work_days) {
      return '&ndash;';
    }

    return this.info.work_days.split(',').filter((dayNum) => +dayNum <= 5).map((day) => {
      return this.daysArray[(+day) - 1];
    }).join(', ');
  }

  get workSaturday() {
    if (!this.info.work_days.split(',').filter((dayNum) => +dayNum === 6).length) {
      return '_';
    }

    return this.info.work_days.split(',').filter((dayNum) => +dayNum === 6).map((day) => {
      return 'Сб';
    }).join(', ');
  }

  get workSunday() {
    if (!this.info.work_days.split(',').filter((dayNum) => +dayNum === 0).length) {
      return '_';
    }

    return this.info.work_days.split(',').filter((dayNum) => +dayNum === 0).map((day) => {
      return 'Нд';
    }).join(', ');
  }

  get type() {
    return _.find(this.rootService.schoolTypes, ['id', this.info.type]);
  }

  get accreditation() {
    return _.find(this.rootService.schoolAccreditations, ['id', this.info.accreditation]);
  }

}
