import { Component, OnInit } from '@angular/core';
import { AuthService } from '../+shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RootService } from '../+shared/services/root.service';
import { Location } from '@angular/common';
import { HelperService } from '../+shared/services/helper.service';
import { ManagementService } from '../functional-modules/management/management.service';
import { RoleModel } from '../+shared/models/role.model';
import * as _ from 'lodash';

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['../+shared/style/auth-shared.scss', './registration.component.scss'],
})
export class RegistrationComponent implements OnInit {

  form: FormGroup;
  wasSubmitted = false;
  wasRegistered = false;
  roleId: number;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  backUrl: string;
  formErrors: any = {
    first_name: HelperService.formErrors.requiredField,
    last_name: HelperService.formErrors.requiredField,
    email: HelperService.formErrors.incorrectFormat,
    gender: HelperService.formErrors.requiredField,
    phone: HelperService.getErrMessage(HelperService.formErrors.incorrectFormat, ` (приклад - ${HelperService.phoneSample})`)
  };
  wrongData = false;
  sendingReq = false;
  roleModel: RoleModel;

  constructor(public userService: AuthService,
              public rootService: RootService,
              public activatedRouter: ActivatedRoute,
              public managementService: ManagementService,
              private location: Location,
              public router: Router,
              public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.activatedRouter.params.subscribe(({roleId, backUrl}) => {
      this.sendingReq = false;
      this.wasRegistered = false;
      this.roleId = roleId;
      this.roleModel = _.find(this.rootService.allRoles, ['id', this.roleId]);
      this.backUrl = backUrl || '/';
      this.wasSubmitted = false;
      this.initForm();

    });

  }

  initForm() {
    this.form = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      phone:
          this.isRequiredPhone()
              ?
          [null, Validators.compose([Validators.required, Validators.minLength(21), Validators.pattern(HelperService.phonePattern)])]
              :
          [null, Validators.compose([Validators.minLength(21), Validators.pattern(HelperService.phonePattern)])],
      gender: ['1']
    });
  }

  isRequiredPhone() {
    return this.roleModel && (this.roleModel.isTeacher() || this.roleModel.isWorker() || this.roleModel.isAdministrator() || this.roleModel.isParent());
  }

  getFieldState(field) {
    return this.wasSubmitted ? this.form.get(field).valid : null;
  }

  getRoleName() {
    return this.roleId ? this.rootService.getRoleById(this.roleId).getFullName(true).toLocaleLowerCase() : null;
  }

  choseRole(roleId) {
    this.router.navigate(['/registration', {roleId, backUrl: '/registration'}]);
  }

  saveUser() {
    this.wasSubmitted = true;

    if (this.form.valid) {
      const formData: any = this.form.value;

      if (!formData.phone || formData.phone.length !== 21) {
        delete formData.phone;
      }
      this.sendingReq = true;
      this.userService.registrateUser({
        ...formData,
        role_id: this.roleId
      }).subscribe((rez) => {
        this.sendingReq = false;
        this.wasRegistered = true;
      }, (err) => {
        this.sendingReq = false;
        this.wrongData = true;

        if ('email' in err.error) {
          this.formErrors.email = 'данна адреса вже зайнята';
        }

        if ('phone' in err.error) {
          this.formErrors.phone = 'данний номер телефону вже зайнятий';
        }
      });
    } else {
      this.wrongData = false;
      this.formErrors.email = HelperService.formErrors.incorrectFormat;
      this.formErrors.phone = HelperService.formErrors.incorrectFormat;
    }
  }
}
