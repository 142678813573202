import {Component, EventEmitter, forwardRef, HostListener, Input, Output, Self} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl, RadioControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss'],
})
export class RadioBtnComponent {

  @Input() _fieldValue;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();

  value: any;

  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.ngControl.control.valueChanges.subscribe(value => {
      // Check to ensure the value wasn't already set (Template driven forms)
      if (this.value === value) return;
      this.writeValue(value);
    });
  }

  @HostListener('click')
  click() {
    this.onTouched();
  }

  onChange = (item) => {

  };

  onTouched = () => {

  };

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: (item) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  select() {
    this.value = this._fieldValue;
    this.onChange(this.value);
  }

}
