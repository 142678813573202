import { ElementRef, Injectable } from '@angular/core';
import {AjaxService} from '../../+shared/services/ajax.service';
import {RootService} from '../../+shared/services/root.service';
import { filter, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, interval, timer } from 'rxjs';
import * as moment from 'moment';
import { AuthService } from '../../+shared/services/auth.service';
import { IPersonalWeekSchedule } from '../../+shared/interfaces/schedule/personal-week-schedule.interface';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
const audio = new Audio('/assets/sounds/mixkit-clear-announce-tones-2861.mp3');

@Injectable()
export class MyCabinetService {

  changeWeek$ = new BehaviorSubject(null);
  updateSchedule$ = new BehaviorSubject(null);
  tabs = [];
  openLesson: boolean;
  mySchedule: IPersonalWeekSchedule;
  toastsIds = [];

  constructor(public rootService: RootService,
              public ajaxService: AjaxService,
              public authService: AuthService,
              private toastrService: ToastrService,
              public router: Router) {
    this.rootService.roleChanging$.pipe(filter(role => !!role)).subscribe((role) => {
      if (this.rootService.role.getRoleType() === 'teacher') {
        this.tabs = [{
          path: this.getSectionPath('/schedule'),
          name: 'Мій розклад',
        }];

        if (role.teacher_class_id) {
          this.tabs.push({
            path: this.getSectionPath(`/class-schedule/${role.teacher_class_id}`),
            name: 'Розклад класу',
          });
        }

        this.tabs.push({
          path: this.getSectionPath('/homeworks'),
          name: 'Завдання самопідготовки для перевірки',
        });

        this.tabs.push({
          path: this.getSectionPath('/control-works'),
          name: 'Контрольні роботи для перевірки',
        });

        this.tabs.push({
          path: this.getSectionPath('/calendar-plannings'),
          name: 'Календарне планування',
        });

      } else {
        this.toastsIds.forEach(id => {
          this.toastrService.clear(id);
        });
        this.toastsIds = [];
        this.tabs = [
          /*
        {
          path: this.getSectionPath('/schedule'),
          name: 'Розклад',
        },
        {
          path: this.getSectionPath('/tasks'),
          name: 'Завдання',
        },
        {
          path: this.getSectionPath('/calendar-plannings'),
          name: 'Календарне планування',
        },
        {
          path: this.getSectionPath('/gallery'),
          name: 'Галерея',
        }*/
        ];
      }
    });
  }

  checkEndOfLesson() {
    if (this.mySchedule) {
      const currDate = moment();

      this.mySchedule.schedule.forEach((l: any) => {
        const duration = moment.duration(currDate.diff(l.momentObject));
        // check if lesson was just finished
        if (Math.floor(duration.asMinutes()) === 0) {
            const [year, month, date] = l.date.split('-');
            const toast = this.toastrService.info(`
              ${date}.${month}.${year} о ${l.end_time}
              ${l.schedule_object.type === 'lesson' ? 'закінчився урок' : 'закінчилась подія'}  "${l.schedule_object.name}" у класі "${l.class_object.class_name} (${l.class_object.grade.name})"`, `Подія закінчилась`, {
              timeOut: ((1000 * 60) * 60) * 24,
              closeButton: true,
              extendedTimeOut: ((1000 * 60) * 60) * 24
            });
            toast.onTap.subscribe(() => {
              this.router.navigate([this.getSectionPath('/schedule'), l.id]);
            });
            this.toastsIds.push(toast.toastId);
            audio.play();
          }
      });
    }
  }

  runLessonsRefresher(element) {
    this.toastrService.overlayContainer = element;
    this.getScheduleData(this.authService.user.uuid).pipe(
            takeWhile(() => this.rootService.role.isTeacher())
        ).subscribe((rez) => {
          rez.schedule = rez.schedule.map(l => {
            const [year, month, date] = l.date.split('-');
            const [h, m] = l.end_time.split(':');
            const momentObject = moment(`${year}-${month}-${date}`);

            momentObject.set('hour', h);
            momentObject.set('minute', m);

            return {
              ...l,
              momentObject
            };
          });
          this.mySchedule = rez;
          this.checkEndOfLesson();

          // get data in the beginning of next minute
          timer(moment().endOf('minute').diff(moment(), 'milliseconds')).pipe(
                  takeWhile(() => this.rootService.role.isTeacher())
              ).subscribe(() => {
                this.runLessonsRefresher(element);
              });
    });
  }

  getSectionPath(path) {
    const section = `${this.rootService.role.getSectionPath()}/my-cabinet`;
    return `${section}/${path}`;
  }

  getScheduleData(user_uuid, date_from = null) {
    return this.ajaxService.get(`/schedule/for-user/${user_uuid}/`, {start_date: date_from});
  }

  getClassScheduleData(class_id, date_from = null) {
    return this.ajaxService.get(`/schedule/for-class/${class_id}/`, {start_date: date_from});
  }

  createNewEvent(event_data) {
    return this.ajaxService.post('/schedule/staff-schedule/', event_data);
  }

  deleteEvent(event_id) {
    return this.ajaxService.delete('/schedule/staff-schedule/' + event_id + '/');
  }

  addInvitedToEvent(event_id, users_list) {
    return this.ajaxService.put(`/schedule/staff-schedule/${event_id}/member/`, users_list);
  }

  getAllHomeworks() {
    return this.ajaxService.get('/teacher/homework/');
  }

  getAllLessonThemeHomeworks(lesson_id, class_id, class_group_id) {
    if (class_id) {
      return this.ajaxService.get(`/teacher/homework/lesson-themes/${lesson_id}/`, {class_id});
    } else {
      return this.ajaxService.get(`/teacher/homework/lesson-themes/${lesson_id}/`, {class_group_id});
    }
  }

  getAllLessonThemeControls(lesson_id, class_id, class_group_id) {
    if (class_id) {
      return this.ajaxService.get(`/teacher/controlwork/lesson-themes/${lesson_id}/`, {class_id});
    } else {
      return this.ajaxService.get(`/teacher/controlwork/lesson-themes/${lesson_id}/`, {class_group_id});
    }
  }

  getClassHomeworks(theme_id, class_id, class_group_id) {
    if (class_id) {
      return this.ajaxService.get(`/teacher/homework/${theme_id}/`, {class_id});
    } else {
      return this.ajaxService.get(`/teacher/homework/${theme_id}/`, {class_group_id});
    }
  }

  getClassControlWorks(theme_id, class_id, class_group_id) {
    if (class_id) {
      return this.ajaxService.get(`/teacher/controlwork/${theme_id}/`, {class_id});
    } else {
      return this.ajaxService.get(`/teacher/controlwork/${theme_id}/`, {class_group_id});
    }
  }

  getStudentHomeworks(student_uuid, theme_id, for_teacher = false) {
    return this.ajaxService.get(`/student/homework-comment/${student_uuid}/${theme_id}/`, {for_teacher});
  }

  getStudentControlWork(student_uuid, theme_id, for_teacher = false) {
    return this.ajaxService.get(`/student/controlwork-comment/${student_uuid}/${theme_id}/`, {for_teacher});
  }

  removeOneLesson(schedule_id) {
    return this.ajaxService.delete(`/schedule/remove-one-lesson/${schedule_id}/`);
  }

  addHomeworkComment(type, uuid, theme_id, data) {
    if (type === 'teacher') {
      return this.ajaxService.post(`/teacher/homework-comment/`, data);
    }
    return this.ajaxService.post(`/student/homework-comment/${uuid}/${theme_id}/`, data);
  }

  addControlComment(type, uuid, theme_id, data) {
    if (type === 'teacher') {
      return this.ajaxService.post(`/teacher/controlwork-comment/`, data);
    }

    return this.ajaxService.post(`/student/controlwork-comment/${uuid}/${theme_id}/`, data);
  }

  deleteHomeworkComment(id) {
    return this.ajaxService.delete(`/student/homework-comment/remove/${id}/`);
  }

  deleteControlComment(id) {
    return this.ajaxService.delete(`/student/controlwork-comment/remove/${id}/`);
  }

  getControlWorks() {
    return this.ajaxService.get(`/teacher/controlwork/`);
  }
}
