import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-icon-btn',
  templateUrl: './icon-btn.component.html',
  styleUrls: ['./icon-btn.component.scss']
})
export class IconBtnComponent implements OnInit, OnInit, OnChanges {

  @ViewChild('wrap') wrap: ElementRef;

  @Input() src: string;
  @Input() iconSize: string[];
  @Input() container: string[] = ['36px', '36px'];
  @Input() colorStates: string[] = [];
  @Input() bcgStates: string[] = [];
  @Input() border = '#dbe5f0';
  @Input() boxShadow: string;
  @Input() menuList = [];
  @Input() menuWidth = 0;

  isOpen = false;
  iconColor: string;
  bcgColor: string;
  renderList: any = [];

  @Output() selectMenuItem: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.highlightBcg(this.bcgStates[0]);
    this.highlightIcon(this.colorStates[0]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuList && changes.menuList.currentValue) {
      const data = _.cloneDeep(changes.menuList.currentValue);
      if (!_.isEqual(this.renderList, data)) {
        this.renderList = data;
      }
    }
  }

  @HostBinding('class.opened') get valid() {
    return this.isOpen;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.wrap.nativeElement.contains(targetElement);

    if (!clickedInside) {
      if (this.isOpen) {
        this.highlightBcg(this.bcgStates[0]);
        this.highlightIcon(this.colorStates[0]);
      }
      this.isOpen = false;
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.highlightBcg(this.bcgStates[1]);
    this.highlightIcon(this.colorStates[1] || this.colorStates[0]);
  }

  @HostListener('mousedown')
  onMouseDown() {
    this.highlightBcg(this.bcgStates[2]);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.isOpen) {
      this.highlightBcg(this.bcgStates[1]);
      this.highlightIcon(this.colorStates[1] || this.colorStates[0]);
    } else {
      this.highlightBcg(this.bcgStates[0]);
      this.highlightIcon(this.colorStates[0]);
    }
  }

  highlightIcon(color) {
    this.iconColor = color;
  }

  highlightBcg(color) {
    this.bcgColor = color;
  }

  onBtnClick() {
    if (this.menuList.length) {
      this.isOpen = !this.isOpen;
    }
  }

  chooseVariant(index) {
    this.selectMenuItem.emit(index);
    this.isOpen = false;
  }
}
