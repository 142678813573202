import { Component, Input } from '@angular/core';

@Component({
  selector: 'round-btn',
  templateUrl: './round-btn.component.html',
  styleUrls: ['./round-btn.component.scss']
})
export class RoundBtnComponent {

  @Input() active: boolean | number = 0;
  @Input() text: string;
  @Input() colors: string[] = [];

  constructor() {
  }
}
