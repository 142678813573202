import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { NgSelectComponent } from '@ng-select/ng-select';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DropdownComponent implements OnInit, OnChanges {

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Input() clearable = false;
  @Input() disabled = false;
  @Input() label: string;
  @Input() selected: any;
  @Input() list: any[] = [];
  @Input() invalidMessage: string;
  @Input() state: boolean = null;
  @Input() required = false;
  @Input() theme = 'regular';
  @Input() searchable = false;
  @Input() idProp = 'id';
  @Input() textProp = 'text';
  @Input() searchText = '';
  @Input() type: 'single' | 'multiple' = 'single';
  @Input() placeholder: string;
  @Input() additionalClass: any = null;
  @Input() clearId = '0';
  @Input() extraItems: number;
  @Input() maxHeight = 240;
  @Input() maxVisible = 2;

  @Output() onChangeValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeMultipleValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropdown') dropdown;

  constructor() {
  }

  ngOnInit(): void {
    if (this.theme === 'filter') {
      this.clearable = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchText) {
      if (this.ngSelectComponent) {
        this.ngSelectComponent.handleClearClick();
        this.ngSelectComponent.filter(changes.searchText.currentValue);
      }
    }

    if (changes.list) {
      if (this.ngSelectComponent) {
        this.ngSelectComponent.detectChanges();
      }
    }
  }

  @HostBinding('class.opened') isOpen = false;

  @HostListener('click')
  click() {
    this.onTouched();
  }

  onChange = (item) => {

  };

  onTouched = () => {

  };

  writeValue(v): void {
    if (_.isArray(v)) {
      if (v.includes('all') && v.length >= 2 && this.selected.includes('all')) {
        v = v.filter(val => val !== 'all');
      } else if (this.selected && !this.selected.includes('all') && v.includes('all')) {
        v = v.filter(val => val === 'all');
      }

      this.selected = v;
    } else {
      if (!v) {
        this.selected = null;
      } else {
        this.selected = typeof v === 'object' ? v.id : v;
      }

      if (this.selected === this.clearId) {
        this.ngSelectComponent.handleClearClick();
        this.selected = null;
      }

    }

    if (this.ngSelectComponent) {
      this.ngSelectComponent.blur();
    }
    this.onChange(this.selected);
    this.onChangeValue.emit(this.selected);
  }

  registerOnChange(fn: (item) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  toggleOpeningState(isOpen) {
    if (!isOpen) {
      this.changeValue.emit(this.selected);
    }
    this.isOpen = isOpen;
  }

  clear() {
    this.changeValue.emit(this.selected);
  }

  changeMultiSelectValue(v) {
    if (!(v instanceof Event)) {
      this.writeValue(v.map(i => i.id));
    }
    this.changeValue.emit(this.selected);
  }

  getLabel() {
    if (this.invalidMessage && this.state === false) {
      return this.label ? this.label + ' - ' + this.invalidMessage : this.invalidMessage;
    } else {
      return this.label;
    }
  }

  closeDropdown() {
    this.onChangeMultipleValue.emit(this.selected);
    return this.dropdown.close();
  }

}
