import {Pipe, PipeTransform} from '@angular/core';
import {PupilModel} from '../models/users/pupil.model';

@Pipe({
  name: 'lastNameSort'
})
export class LastNamesSortPipe implements PipeTransform {

  transform(value: PupilModel[], ...args: unknown[]): unknown {
    return value.sort((a, b) => a.last_name.localeCompare(b.last_name));
  }
}
