import {
  AfterViewChecked, ChangeDetectorRef,
  Component, EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
} from '@angular/core';
import {IPersonalWeekSchedule} from '../../interfaces/schedule/personal-week-schedule.interface';
import {IScheduleLesson} from '../../interfaces/schedule/schedule-lesson.interface';
import {IDropdownData} from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';
import {Subscription, zip} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MyCabinetService} from '../../../functional-modules/my-cabinet/my-cabinet.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import {RootService} from '../../services/root.service';
import {ManagementService} from '../../../functional-modules/management/management.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-personal-schedule',
  templateUrl: './personal-schedule.component.html',
  styleUrls: ['./personal-schedule.component.scss']
})
export class PersonalScheduleComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  @Input() myCabinet: boolean;
  @Input() userUuid: string;
  @Input() classId: string;
  @Input() userRole: string;
  @Input() classSchedule = false;
  @Input() showPlus = false;

  mySchedule: IPersonalWeekSchedule;
  rows = [];
  curDay: {
    date: string,
    week_day: number
  };
  curDate: string;
  curMonth: string;
  curWeekDay = window.localStorage.getItem('current-week-day');
  curTime: string;
  viewScheduleMode: 'week' | 'day' = 'week';
  daySchedule: IScheduleLesson[];
  dayGroupedLessons = [];
  emptyDaySchedule: any;
  monthsDrop: IDropdownData<any>[] = [];
  showEventId: number;
  curUser = window.localStorage.getItem('uuid');
  subs: Subscription;
  form: FormGroup;
  lastWorkingDay: number;
  firstWorkingDay: number;

  loaded: boolean;
  scheduleLoaded: boolean;
  weekDays = [
    'Понеділок',
    'Вівторок',
    'Ceреда',
    'Четвер',
    'П`ятниця',
    'Cубота',
    'Неділя'
  ];
  calls = [];
  possibleCalls = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30',
    '12:00', '12:30', '13:00', '13:30',
    '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00',
    '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'];
  months = [
    {
      id: 9,
      name: 'Вересень',
      name2: 'вересня'
    },
    {
      id: 10,
      name: 'Жовтень',
      name2: 'жовтня'
    },
    {
      id: 11,
      name: 'Листопад',
      name2: 'листопада'
    },
    {
      id: 12,
      name: 'Грудень',
      name2: 'грудня'
    },
    {
      id: 1,
      name: 'Січень',
      name2: 'січня'
    },
    {
      id: 2,
      name: 'Лютий',
      name2: 'лютого'
    },
    {
      id: 3,
      name: 'Березень',
      name2: 'березня'
    },
    {
      id: 4,
      name: 'Квітень',
      name2: 'квітня'
    },
    {
      id: 5,
      name: 'Травень',
      name2: 'травня'
    },
    {
      id: 6,
      name: 'Червень',
      name2: 'червня'
    },
    {
      id: 7,
      name: 'Липень',
      name2: 'липня'
    },
    {
      id: 8,
      name: 'Серпень',
      name2: 'серпня'
    },
  ];
  innerWidth: any;
  members: any;
  isActive = false;
  interval: any;
  timeStampPosition: number;
  semesters = [];
  daysSchedule = [];

  @Output() onChangeWeek: EventEmitter<any> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.viewScheduleMode = 'day';
    } else {
      this.viewScheduleMode = 'week';
    }
  }

  constructor(public myCabinetService: MyCabinetService,
              public ngxSmartModalService: NgxSmartModalService,
              public classesService: ClassesService,
              public managementService: ManagementService,
              public rootService: RootService,
              public authService: AuthService,
              public changeDetectorRef: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    if (window.innerWidth < 768) {
      this.viewScheduleMode = 'day';
    }
    this.innerWidth = window.innerWidth;
    this.loaded = false;
    this.managementService.getSchoolSemesters().subscribe((rez) => {
      this.semesters = rez;
      this.subs = this.myCabinetService.updateSchedule$.pipe(switchMap(() => {
        this.loaded = false;
        return this.myCabinetService.changeWeek$.pipe(switchMap((date_from) => {
          this.onChangeWeek.emit(date_from);
          // load date from local storage 12 hours
          if (!date_from) {
            const stored_date_from = localStorage.getItem('schedule-week-date');
            const date_timestamp = localStorage.getItem('schedule-week-timestamp');
            if (stored_date_from) {
              if (parseInt(date_timestamp) + 12 * 60 * 60 > new Date().getTime()) {
                date_from = stored_date_from;
                localStorage.setItem('schedule-week-timestamp', '' + new Date().getTime());
              } else {
                localStorage.setItem('schedule-week-date', null);
                localStorage.setItem('schedule-week-timestamp', null);
              }
            }
          } else {
            localStorage.setItem('schedule-week-date', date_from);
            localStorage.setItem('schedule-week-timestamp', '' + new Date().getTime());
          }

          this.scheduleLoaded = false;

          if (this.classId) {
            return this.myCabinetService.getClassScheduleData(this.classId, date_from);
          } else {
            return this.myCabinetService.getScheduleData(this.userUuid, date_from);
          }
        }));
      })).subscribe((my_schedule) => {
        const currMonth = new Date().getMonth();
        const currDate = new Date().getDate();
        const currYear = new Date().getFullYear();
        const startTimes = [];
        const endTimes = [];

        if (this.managementService.schoolSettings.start_work_week) {
          const [startHW, startMW] = this.managementService.schoolSettings.start_work_week.split(':');
          const [endHW, endMW] = this.managementService.schoolSettings.end_work_week.split(':');

          startTimes.push(new Date(currYear, currMonth, currDate, +startHW, +startMW));
          endTimes.push(new Date(currYear, currMonth, currDate, +endHW, +endMW));
        }

        if (this.managementService.schoolSettings.start_work_sat) {
          const [startHSt, startMSt] = this.managementService.schoolSettings.start_work_sat.split(':');
          const [endHSt, endMSt] = this.managementService.schoolSettings.end_work_sat.split(':');

          startTimes.push(new Date(currYear, currMonth, currDate, +startHSt, +startMSt));
          endTimes.push(new Date(currYear, currMonth, currDate, +endHSt, +endMSt));
        }

        if (this.managementService.schoolSettings.start_work_sun) {
          const [startHSn, startMSn] = this.managementService.schoolSettings.start_work_sun.split(':');
          const [endHSn, endMSn] = this.managementService.schoolSettings.end_work_sun.split(':');

          startTimes.push(new Date(currYear, currMonth, currDate, +startHSn, +startMSn));
          endTimes.push(new Date(currYear, currMonth, currDate, +endHSn, +endMSn));
        }


        const startTime = new Date(startTimes.map(d => d.getTime()).sort()[0]);
        const endTime = new Date(_.last(endTimes.map(d => d.getTime()).sort()));

        const startIndex = this.possibleCalls.findIndex(item => {
          const [h, m] = item.split(':');
          const dateTime = new Date(currYear, currMonth, currDate, +h, +m);
          return dateTime.getTime() >= startTime.getTime();
        });

        const endIndex = this.possibleCalls.findIndex(item => {
          const [h, m] = item.split(':');
          const dateTime = new Date(currYear, currMonth, currDate, +h, +m);
          return dateTime.getTime() > endTime.getTime();
        });

        this.calls = this.possibleCalls.slice(startIndex === 0 ? startIndex : startIndex - 1 , endIndex + 1);
        this.mySchedule = my_schedule;

        this.lastWorkingDay = this.mySchedule.current_week_days[this.mySchedule.current_week_days.length - 1].week_day || 7;
        this.firstWorkingDay = this.mySchedule.current_week_days[0].week_day;

        this.curTime = this.mySchedule.timeline.time;
        this.getDateInfo();
        this.getDataForDropdowns();
        this.initForm();

        this.tableRows();
        this.getTimeLinePosition(this.curTime);
        this.interval = setInterval(() => {
          const [hours, minutes] = this.curTime.split(':');

          if (minutes === '59') {
            this.curTime = (+hours + 1).toString() + ':' + '00';
            this.getTimeLinePosition(this.curTime);
          } else {
            this.curTime = hours + ':' + (+minutes + 1).toString();
            this.getTimeLinePosition(this.curTime);
          }
        }, 60000);
        this.scheduleLoaded = true;
        this.loaded = true;
        this.formDaysArray();
      });
    });
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.myCabinetService.updateSchedule$.next(null);
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.myCabinetService.changeWeek$.next(null);
    window.localStorage.removeItem('current-week-day');
    this.loaded = false;
    clearInterval(this.interval);
    this.myCabinetService.openLesson = undefined;
  }

  formDaysArray() {
    this.daysSchedule = [];

    this.mySchedule.current_week_days.forEach((day) => {
      const daySchedule = this.getDaySchedule(day);
      const lessons = daySchedule.map((lesson, i) => {
        lesson.top = this.getElemPosition(lesson.start_time);
        lesson.slotLessons = this.getSlotLessons(lesson);
        lesson.lessonInPast =  this.isLessonInPast(lesson);
        lesson.hasWeekSubgroup = this.hasWeekSubgroup(daySchedule, i, lesson);
        lesson.isLink = this.isLink(lesson.schedule_object.name);

        return lesson;
      });
      
      this.daysSchedule.push(lessons);
    });
  }

  getDataForDropdowns() {
    const semester1Start = this.semesters[0].start_date;
    const semester1End = this.semesters[0].end_date;
    const semester2StartDate = this.semesters[1].start_date;
    const semester2End = this.semesters[1].end_date;

    this.months.forEach(month => {
      if ((new Date(semester1Start).getMonth() + 1) <= month.id && (new Date(semester1End).getMonth() + 1) >= month.id) {
        this.monthsDrop.push({
          id: semester1Start.split('-')[0] + '-' + (('' + month.id).length === 1 ? '0' + month.id : month.id),
          text: month.name
        });
      } else {
        this.monthsDrop.push({
          id: semester2End.split('-')[0] + '-' + (('' + month.id).length === 1 ? '0' + month.id : month.id),
          text: month.name
        });
      }
    });
  }

  /* day schedule for template */
  getDaySchedule(day) {
    const dayScheduleItems = this.mySchedule.schedule.filter(event => {
      return event.date === day.date;
    }).map((event: any) => {
      const date = new Date(event.date);
      date.setHours(event.start_time.split(':')[0]);
      date.setMinutes(event.start_time.split(':')[1]);
      event.timestamp = date.getTime();

      return event;
    }).sort((ev1, ev2) => {
      if (ev1.timestamp === ev2.timestamp) {
        return 0;
      }
      return ev1.timestamp > ev2.timestamp ? 1 : -1;
    });
    return dayScheduleItems;
  }

  getDateInfo() {
    const curWeekDay = this.mySchedule.current_week_days.find(day => {
      return day.date === moment().format('YYYY-MM-DD');
    });
    /* journal lesson modal */
    if (curWeekDay && this.userRole !== 'parent') {
      const daySchedule = this.mySchedule.schedule.filter(day_schedule => {
        return day_schedule.date === curWeekDay.date;
      });
      const hasLesson = daySchedule.find(schedule_item => {
        const start_time = new Date();
        const end_time = new Date();
        const cur_time = new Date();
        start_time.setHours(+schedule_item.start_time.split(':')[0], +schedule_item.start_time.split(':')[1]);
        end_time.setHours(+schedule_item.end_time.split(':')[0], +schedule_item.end_time.split(':')[1]);
        return cur_time > start_time && cur_time < end_time && schedule_item.schedule_object.type === 'lesson';
      });
      if (this.myCabinetService.openLesson === undefined) {
        this.myCabinetService.openLesson = true;
      }
      if (hasLesson && this.myCabinetService.openLesson && !this.classId) {
        this.openLessonModal(hasLesson.id);
      }
    }
    if (!window.localStorage.getItem('current-week-day') && curWeekDay) {
      window.localStorage.setItem('current-week-day', String(curWeekDay.week_day));
      this.curWeekDay = window.localStorage.getItem('current-week-day');
      this.getDateSchedule(this.curWeekDay);
    } else if (!window.localStorage.getItem('current-week-day') && !curWeekDay) {
      window.localStorage.setItem('current-week-day', String(this.mySchedule.current_week_days[0].week_day));
    } else {
      this.getDateSchedule(window.localStorage.getItem('current-week-day'));
    }

    const firstDateOfWeek = this.mySchedule.current_week_days[0];
    this.curMonth = firstDateOfWeek.date.split('-').slice(0, -1).join('-');
  }

  initForm() {
    this.form = this.fb.group({
      month: [this.curMonth]
    });
  }

   removeLesson(scheduleId, e) {
     e.stopPropagation();
     if (confirm('Видалення уроку приведе до стирання всіх оцінок, завдань для самопідготовки та відвідувань на уроці.')) {
       if (confirm('Ви впевнені що хочете видалити цей урок?')) {
         this.myCabinetService.removeOneLesson(scheduleId).subscribe(() => {
           this.myCabinetService.updateSchedule$.next(null);
         });
       }}
   }

  onChangeMonth(month) {
    if (month !== this.curMonth) {
      const date = new Date(`${month.split('-').join('/')}/1`);

      while (date.getDay() !== 1) {
        date.setDate(date.getDate() + 1);
      }

      const dateFrom = month + '-' + date.getDate();
      this.myCabinetService.changeWeek$.next(dateFrom);
    } else {
      return;
    }
  }

  changeView(type) {
    this.showEventId = null;
    if (type === 'day') {
      this.getDateSchedule(this.curWeekDay);
    }
    this.viewScheduleMode = type;
  }

  changeDay(type) {
    this.showEventId = null;

    if (type === 'prev') {
      if (window.localStorage.getItem('current-week-day') === String(this.firstWorkingDay)) {
        window.localStorage.setItem('current-week-day', String(this.lastWorkingDay));
        this.curWeekDay = window.localStorage.getItem('current-week-day');
        this.myCabinetService.changeWeek$.next(this.mySchedule.prev_week);
      } else {
        this.curWeekDay = window.localStorage.getItem('current-week-day');
        window.localStorage.setItem('current-week-day', String(+this.curWeekDay - 1));
        this.curWeekDay = window.localStorage.getItem('current-week-day');
        this.getDateSchedule(+this.curWeekDay);
      }
    } else {
      if (window.localStorage.getItem('current-week-day') === String(this.lastWorkingDay)) {
        window.localStorage.setItem('current-week-day', String(this.firstWorkingDay));
        this.curWeekDay = window.localStorage.getItem('current-week-day');
        this.myCabinetService.changeWeek$.next(this.mySchedule.next_week);
      } else {
        this.curWeekDay = window.localStorage.getItem('current-week-day');
        window.localStorage.setItem('current-week-day', String(+this.curWeekDay + 1));
        this.curWeekDay = window.localStorage.getItem('current-week-day');
        this.getDateSchedule(+this.curWeekDay);
      }
    }
  }

  goToCurrentWeekDay() {
    const type = (this.innerWidth > 768 && this.viewScheduleMode === 'week') ? 'week' : 'day';

    let currDate = new Date();
    const firstDayOfWeek = currDate.getDate() - currDate.getDay() + 1;
    const firstDayOfWeekDate = moment(new Date(currDate.setDate(firstDayOfWeek))).format('YYYY-MM-DD');
    if (type === 'day') {
      currDate = new Date();
      window.localStorage.setItem('current-week-day', String(currDate.getDay()));
    }
    this.myCabinetService.changeWeek$.next(firstDayOfWeekDate);
  }

  getDateSchedule(current_week_day) {
    this.daySchedule = this.mySchedule.schedule.filter(schedule => {
      return +current_week_day === 7 ? 0 === schedule.week_day : schedule.week_day === +current_week_day;
    });
    const groupedLessons = _.groupBy(this.daySchedule, (daySchedule) => {
      return this.getElemPosition(daySchedule.start_time);
    });

    this.dayGroupedLessons = [];
    _.forOwn(groupedLessons, (items, key) => {
      this.dayGroupedLessons.push({
        top: key,
        items
      });
    });

    if (!this.daySchedule.length) {
      const todaySchedule = this.mySchedule.current_week_days.filter(schedule => {
        return +current_week_day === 7 ? 0 === schedule.week_day : schedule.week_day === +current_week_day;
      });

      todaySchedule.length ? this.emptyDaySchedule = todaySchedule : this.emptyDaySchedule = this.mySchedule.current_week_days;
    }
  }

  hasWeekSubgroup(lessons, index, data) {
    const searchIndex = lessons.findIndex((item, itemIndex) => {
      const targetLessonTimeStart = moment().set({ hour: data.start_time.split(':')[0], minute: data.start_time.split(':')[1] });
      const itemFoundStart = moment().set({ hour: item.start_time.split(':')[0], minute: item.start_time.split(':')[1] })

      const isCorrectTime = targetLessonTimeStart.unix() <= itemFoundStart.unix()

      return index !== itemIndex && item.date === data.date && isCorrectTime && (item.start_time === data.start_time || item.order_num === data.order_num); // Disabled: && item.end_time === data.end_time
    });

    if (searchIndex !== -1) {
      if (index < searchIndex) {
        return (searchIndex - index) === 1 ? 'has-subgroup first' : '';
      } else {
        return ((index - searchIndex) === 1)  ? 'has-subgroup second' : '';
      }
    }

    return '';
  /*
    const searchIndex = lessons.findIndex((item, itemIndex) => {
      return +index !== +itemIndex && item.date === data.date && (item.start_time === data.start_time || item.order_num === data.order_num); // Disabled: && item.end_time === data.end_time
    });

    if (searchIndex !== -1) {
      if (index < searchIndex) {
        return (searchIndex - index) === 1 ? 'has-subgroup first' : '';
      } else {
        return ((index - searchIndex) === 1)  ? 'has-subgroup second' : '';
      }
    }

    return '';

   */
  }

  getSlotLessons(event) {
    return  this.getDaySchedule({date: event.date})
        .filter((item: any) => {
          const targetLessonTimeStart = moment().set({ hour: event.start_time.split(':')[0], minute: event.start_time.split(':')[1] });
          const itemFoundStart = moment().set({ hour: item.start_time.split(':')[0], minute: item.start_time.split(':')[1] });

          const isCorrectTime = targetLessonTimeStart.unix() <= itemFoundStart.unix();

          return isCorrectTime && item.order_num === event.order_num;
        }).length;
  }

  getElemPosition(lesson_start_time) {
    const day_start = moment.utc(this.calls[0], 'HH:mm');
    const lesson_start = moment.utc(lesson_start_time, 'HH:mm');
    return lesson_start.diff(day_start, 'minutes');
  }

  getTimeLinePosition(cur_time) {
    const day_start = moment.utc(this.calls[0], 'HH:mm');
    const day_end = moment.utc(this.calls[this.calls.length - 1], 'HH:mm');
    const current_time = moment.utc(cur_time, 'HH:mm');

    const isWorkingHours = day_start < current_time && day_end > current_time;
    if (isWorkingHours) {
      this.timeStampPosition = current_time.diff(day_start, 'minutes');
    } else {
      this.timeStampPosition = null;
    }

    return this.timeStampPosition;
  }

  isLink(name) {
    const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    const regex = new RegExp(expression);

    return name.match(regex);
  }

  tableRows() {
    this.calls.forEach(() => {
      while (this.rows.length < (this.calls.length * 3)) {
        this.rows.push('');
      }
    });
  }

  changeWeekDay(direction) {
    const type = (this.innerWidth > 768 && this.viewScheduleMode === 'week') ? 'week' : 'day';

    if (type === 'day') {
      this.changeDay(direction);
    } else {
      if (direction === 'next') {
        this.myCabinetService.changeWeek$.next(this.mySchedule.next_week);
      } else {
        this.myCabinetService.changeWeek$.next(this.mySchedule.prev_week);
      }
    }
  }

  openLessonModal(schedule_id, add_materials?, ev?) {
    if (schedule_id) {
      window.localStorage.setItem('open-lesson-from', 'schedule');
      this.router.navigate([schedule_id], {relativeTo: this.route});
      if (ev) {
        ev.stopPropagation();
      }
      this.showEventId = null;
    }
  }

  openAddEvent(type, selected_date?) {
    this.ngxSmartModalService.resetModalData('add-new-event-popup');
    if (type === 'week') {
      this.ngxSmartModalService.getModal('add-new-event-popup').setData(this.curDate);
      this.ngxSmartModalService.getModal('add-new-event-popup').open();
    } else {
      this.ngxSmartModalService.getModal('add-new-event-popup').setData(selected_date);
      this.ngxSmartModalService.getModal('add-new-event-popup').open();
    }
  }

  close(isOpen) {
    if (!isOpen) {
      this.showEventId = null;
    }
  }

  openScheduleEventInfo(event_info_to_show, ev = null) {
    if (ev) {
      ev.stopPropagation();
    }
    const userRole = this.rootService.role.getRoleType();
    if (userRole === 'teacher' && event_info_to_show.schedule_object.type === 'lesson' && (this.classSchedule ?  !this.rootService.role.isTutor() : true)) {
      const lessonStartTime = new Date(event_info_to_show.date);
      const now = new Date();
      lessonStartTime.setHours(event_info_to_show.start_time.split(':')[0], event_info_to_show.start_time.split(':')[1]);
      const sectionPath = `${this.rootService.role.getSectionPath()}/my-cabinet/schedule/${event_info_to_show.id}`;

      if (lessonStartTime > now) {
        window.localStorage.setItem('open-lesson-from', 'schedule');

        // User that view schedule is the same as lesson teacher
        if (this.authService.user.uuid === event_info_to_show.user.uuid) {
          this.router.navigate([sectionPath], {queryParams: {page: 2}});
        } else {
          this.router.navigate([sectionPath]);
        }
      } else {
        window.localStorage.setItem('open-lesson-from', 'schedule');
        this.router.navigate([sectionPath]);
      }
      this.showEventId = null;
      return;
    } else if ((userRole === 'parent' || userRole === 'pupils') && event_info_to_show.schedule_object.type === 'lesson' && event_info_to_show.schedule_object.theme_id) {
      const sectionPath = `${this.rootService.role.getSectionPath()}/tasks/${event_info_to_show.schedule_object.theme_id}`;

      if (event_info_to_show.class_object.type === 'class_group') {
        this.router.navigate([sectionPath], {
          queryParams: {
            class_group: event_info_to_show.class_object.id,
            lesson: event_info_to_show.schedule_object.id
          }
        });
      } else {
        this.router.navigate([sectionPath], {queryParams: {lesson: event_info_to_show.schedule_object.id}});
      }
    } else {
      if (event_info_to_show.members) {
        const departments = event_info_to_show.members.department_list.map(item => {
          item.name = `Кафедра ${item.name}`;
          return item;
        });

        this.members = [
          ...event_info_to_show.members.class_list,
          ...event_info_to_show.members.user_list,
          ...departments
        ];
      }
      this.showEventId = event_info_to_show.id;
    }
  }

  openHiddenSlotLessons(event_info_to_show) {
    const [year, month, date] = event_info_to_show.date.split('-');
    const lessons = this.getDaySchedule({date: event_info_to_show.date}).filter((l: any) => {
      return (l.start_time === event_info_to_show.start_time || l.order_num === event_info_to_show.order_num);
    });
    this.ngxSmartModalService.resetModalData('slot-lessons-popup');
    this.ngxSmartModalService.getModal('slot-lessons-popup').setData({
      title: `Часовий слот №${event_info_to_show.order_num} (${date}.${month}.${year})`,
      lessons,
      canRemove: this.rootService.role.isAdmin(),
      onRemove: (scheduleId) => {
        this.myCabinetService.removeOneLesson(scheduleId).subscribe(() => {
          this.myCabinetService.updateSchedule$.next(null);
        });
      },
      onOpenEvent: (data) => {
        this.openScheduleEventInfo(data);
      }
    });
    this.ngxSmartModalService.getModal('slot-lessons-popup').open();
  }

  makeActive() {
    this.isActive = !this.isActive;
  }

  isLessonInPast(event_info) {
    const lessonFinishedTime = new Date(event_info.date);
    const now = new Date();
    lessonFinishedTime.setHours(event_info.end_time.split(':')[0], event_info.end_time.split(':')[1]);

    return lessonFinishedTime < now;
  }

  closeScheduleEventInfo(ev) {
    ev.stopPropagation();
    this.showEventId = null;
  }

  deleteScheduleEvent(ev, event_id) {
    ev.stopPropagation();
    this.myCabinetService.deleteEvent(event_id).subscribe(response => {
          this.showEventId = null;
          this.myCabinetService.updateSchedule$.next(response);
        });
  }

  getFormattedData(date, week_day) {
    let [year, month, day] = date.split('-');
    if (day.split('')[0] === '0') {
      day = day.split('')[1];
    }
    month = this.months.find(month_of_year => {
      return month_of_year.id === +month;
    });
    const cur_week_day = week_day === 0 ? 'Неділя' : this.weekDays[week_day - 1];
    return day + ' ' + month.name2 + ',' + ' ' + cur_week_day;
  }

  get hasPlusButton() {
    return !this.rootService.role.isParent() && !this.rootService.role.isStudent() && !this.classId;
  }

}
