import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
// import * as Sentry from "@sentry/angular";
// import {Integrations} from "@sentry/tracing";
import 'zone.js';

if (environment.production) {
  enableProdMode();

  // Sentry.init({
  //   dsn: 'https://6b2b8b965d1f4e128512a57bb415aa89@o349351.ingest.sentry.io/5595275',
  //   autoSessionTracking: true,
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: ['localhost', /^\//],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //   ],
  //
  //   // We recommend adjusting this value in production, or using tracesSampler
  //   // for finer control
  //   tracesSampleRate: 1.0,
  // });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
