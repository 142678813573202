import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { mapTo, switchMap, take, tap, toArray } from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MyCabinetService} from '../../../functional-modules/my-cabinet/my-cabinet.service';
import {IHomeworkChat} from '../../interfaces/journal/homework-chat.interface';
import {AuthService} from '../../services/auth.service';
import {UserModel} from '../../models/user.model';
import {UsersService} from '../../../functional-modules/users/users.service';
import { concat, of, Subject, zip } from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {RootService} from '../../services/root.service';
import * as _ from 'lodash';
import { HelperService } from '../../services/helper.service';
import { ClassesService } from '../../../functional-modules/classes/classes.service';
import { LibraryService } from '../../../functional-modules/library/library.service';

@Component({
  selector: 'app-homework-chat',
  templateUrl: './homework-chat.component.html',
  styleUrls: ['./homework-chat.component.scss', '../../style/modules-shared.scss']
})
export class HomeworkChatComponent implements OnInit {
  loaded = false;
  isMiddle = false;
  hasJournalPermission: boolean;
  isMiddleSchool: boolean;
  isTeacherRole: boolean;
  submitted: boolean;
  submittedControl: boolean;
  form: FormGroup;
  controlForm: FormGroup;
  scheduleId: string;
  studentUuid: string;
  themeId: string;
  homeworkChat: IHomeworkChat;
  controlChat: IHomeworkChat;
  myInfo: UserModel;
  parentsData: any[] = [];
  studentInfo: UserModel;

  canvas: any;
  size: number[];
  isControlWork = false;

  @Input() mode: 'teacher' | 'student';
  @Input() theme_id: number;
  @Input() classInfo: any;
  @Input() isControl: boolean;
  @Output() prepareTill: EventEmitter<any> = new EventEmitter<any>();


  constructor(public myCabinetService: MyCabinetService,
              private authService: AuthService,
              private userService: UsersService,
              public rootService: RootService,
              private ngxSmartModalService: NgxSmartModalService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private changeDetectionRef: ChangeDetectorRef,
              public libraryService: LibraryService,
              public classesService: ClassesService) {
    this.scheduleId = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.themeId = params.themeId || this.theme_id;

      if (params.uuid) {
        this.studentUuid = params.uuid;
        this.getControlStatus(params).subscribe((theme: any) => {
          this.load();
        });
      } else {
        if (this.rootService.role.isParent()) {
          this.studentUuid = window.localStorage.getItem('selected-parents-child');
        } else if (this.rootService.role.isStudent()) {
          this.studentUuid = this.authService.user.uuid;
        }

        this.isControlWork = this.isControl;
        this.load();
      }
    });
  }

  load() {
    if (this.mode === 'teacher' && this.route.snapshot.queryParams.notifId) {
      this.userService.updateNotificationStatus(this.route.snapshot.queryParams.notifId).subscribe();
    }

    const requests = [
      this.myCabinetService.getStudentHomeworks(this.studentUuid, this.themeId, this.mode === 'teacher'),
      this.userService.getUserAccount(this.studentUuid)
    ];
    this.loaded = false;

    if (this.rootService.role.isStudent()) {
      requests.push(this.userService.getPupilAccount(this.authService.user.uuid));
    }

    if (this.isControlWork) {
      requests.push(
          this.myCabinetService.getStudentControlWork(this.studentUuid, this.themeId, this.mode === 'teacher').pipe(
              tap((rez) => {
                this.controlChat = rez;
              }),
              mapTo(null)
          )
      );
    }

    zip(...requests)
        .subscribe(([homeworksData, studentData, studentAccount]) => {
      this.authService.getNotifications();
      this.homeworkChat = homeworksData;
      this.parentsData = studentAccount ? studentAccount.parents : [];
      this.studentInfo = studentData;
      this.updateDate();
      this.sendPrepareTill();

      /* myInfo for teachers and parents (for parents the value of myInfo is info of selected child ) */
      this.mode === 'teacher' ? this.myInfo = this.authService.user : this.myInfo = this.studentInfo;
      this.hasJournalPermission = this.rootService.role.hasPermission('journal_edit');
      this.mode === 'teacher' ? this.isMiddleSchool = +this.homeworkChat.class.grade.name > 4 :
        this.isMiddleSchool = +this.classInfo.grade.name > 4;
      this.initForm();
    });
  }

  getControlStatus(params) {
    return zip(
        this.classesService.getJournalDataByScheduleID(params.id),
        this.classesService.getStudentScheduleLessonData(params.uuid, params.id),
    ).pipe(
        switchMap(([scheduleData, scheduleLesson]) => {
          const class_object = scheduleLesson.class_object;
          let req;

          if (scheduleData.journal) {
            if (class_object.type === 'class_group') {
              req = this.libraryService.getThemeContentById(scheduleData.journal.theme.id, null, class_object.id, this.rootService.role.isParent() || this.rootService.role.isStudent());
            } else {
              req = this.libraryService.getThemeContentById(scheduleData.journal.theme.id, class_object.id, null, this.rootService.role.isParent() || this.rootService.role.isStudent());
            }
          }

          return req;
        })
    ).pipe(
        tap((theme: any) => {
          this.isControlWork = theme.is_control;
      })
    );
  }

  sendPrepareTill() {
    this.prepareTill.emit(this.homeworkChat.prepare_till);
  }

  updateDate() {
    const today = new Date();
    this.homeworkChat.comments.forEach(comment => {
      if (comment.type !== 'score') {
        const commentTime = new Date(comment.content.created_at);
        const timeDif = Math.floor(Math.abs(today.getTime() - commentTime.getTime()) / 36e5);

        if (!_.isNaN(timeDif)) {
          if (timeDif < 1) {
            comment.content.created_at = commentTime.getHours() + ':' +
                (commentTime.getMinutes() < 10 ? '0' + commentTime.getMinutes() : commentTime.getMinutes());
          } else if (timeDif > 12) {
            comment.content.created_at = commentTime.getDate() + '.' + commentTime.getMonth() + ' ' +
                commentTime.getHours() + ':' + (commentTime.getMinutes() < 10 ? '0' + commentTime.getMinutes() : commentTime.getMinutes());
          } else {
            comment.content.created_at = timeDif + ' год.';
          }
        }
      } else {
        comment.content.created_at = comment.content.created_at.split(' ')[0];
      }

      if (comment.content.text) {
        comment.content.text = comment.content.text.split(' ').map((word: string) => {
          if (HelperService.isLink(word)) {
            return `<a href="${word}" target="_blank">${word}</a>`;
          } else {
            return word;
          }
        }).join(' ');
      }
    });

    if (this.isControlWork) {
      this.controlChat.comments.forEach(comment => {
        if (comment.type !== 'score') {
          const commentTime = new Date(comment.content.created_at);
          const timeDif = Math.floor(Math.abs(today.getTime() - commentTime.getTime()) / 36e5);

          if (!_.isNaN(timeDif)) {
            if (timeDif < 1) {
              comment.content.created_at = commentTime.getHours() + ':' +
                  (commentTime.getMinutes() < 10 ? '0' + commentTime.getMinutes() : commentTime.getMinutes());
            } else if (timeDif > 12) {
              comment.content.created_at = commentTime.getDate() + '.' + commentTime.getMonth() + ' ' +
                  commentTime.getHours() + ':' + (commentTime.getMinutes() < 10 ? '0' + commentTime.getMinutes() : commentTime.getMinutes());
            } else {
              comment.content.created_at = timeDif + ' год.';
            }
          }
        } else {
          comment.content.created_at = comment.content.created_at.split(' ')[0];
        }

        if (comment.content.text) {
          comment.content.text = comment.content.text.split(' ').map((word: string) => {
            if (HelperService.isLink(word)) {
              return `<a href="${word}" target="_blank">${word}</a>`;
            } else {
              return word;
            }
          }).join(' ');
        }
      });
    }
  }

  initForm() {
    this.form = null;
    this.form = this.formBuilder.group({
      comment: [''],
      files: this.formBuilder.array([])
    });

    if (this.isControlWork) {
      this.controlForm = null;
      this.controlForm = this.formBuilder.group({
        comment: [''],
        files: this.formBuilder.array([])
      });
    }

    this.loaded = true;
  }

  canRemoveHomework(item) {
    if (this.rootService.role.isParent()) {
      return (this.myInfo.uuid === item.user.uuid || this.authService.user.uuid === item.user.uuid) && item.content.id && item.content.is_new;
    } else if (this.rootService.role.isStudent()) {
      return (this.authService.user.uuid === item.user.uuid  || this.parentsData.some((p) => p.user.uuid === item.user.uuid)) && item.content.id && item.content.is_new;
    } else {
      return this.myInfo.uuid === item.user.uuid && item.content.id && item.content.is_new;
    }
  }

  get files() {
    return this.form.get('files') as FormArray;
  }

  get controlFiles() {
    return this.controlForm.get('files') as FormArray;
  }

  goToStudentsHomework() {
    this.router.navigate([this.myCabinetService.getSectionPath(`schedule/${this.scheduleId}`)], {queryParams: {page: 3}});
  }

  anotherStudent(type) {
    if (type === 'prev') {
      this.router.navigate([
        this.myCabinetService.getSectionPath(`schedule/${this.scheduleId}/theme/${this.themeId}/student/${this.homeworkChat.prev_student_uuid}`)
      ]);
    } else {
      this.router.navigate([
        this.myCabinetService.getSectionPath(`schedule/${this.scheduleId}/theme/${this.themeId}/student/${this.homeworkChat.next_student_uuid}`)
      ]);
    }
  }

  updateStudentScore() {
    if (this.isTutorWithoutPermission) {
      return;
    }

    const mark_data = {
      journal: this.homeworkChat.journal_id,
      score_type: this.homeworkChat.score.score_type,
      student: this.studentInfo,
      student_name: this.studentInfo.first_name,
      student_surname: this.studentInfo.last_name,
      grade_id: this.homeworkChat.class.grade.id,
      date: null,
      can_add: true,
      can_edit: true,
      student_score: this.homeworkChat.score,
      has_journal_permission: this.hasJournalPermission,
      is_middle_school: this.isMiddleSchool
    };

    this.ngxSmartModalService.resetModalData('student-assessment');
    this.ngxSmartModalService.getModal('student-assessment').setData({
      mark_data,
      onSave: () => this.load()
    });
    this.ngxSmartModalService.getModal('student-assessment').open();
  }

  updateControlScore() {
    const mark_data = {
      journal: this.controlChat.journal_id,
      score_type: this.controlChat.score.score_type,
      student: this.studentInfo,
      student_name: this.studentInfo.first_name,
      student_surname: this.studentInfo.last_name,
      grade_id: this.controlChat.class.grade.id,
      date: null,
      can_add: true,
      can_edit: true,
      student_score: this.controlChat.score,
      has_journal_permission: this.hasJournalPermission,
      is_middle_school: this.isMiddleSchool
    };
    this.ngxSmartModalService.resetModalData('student-assessment');
    this.ngxSmartModalService.getModal('student-assessment').setData({
      mark_data,
      onSave: () => this.load()
    });
    this.ngxSmartModalService.getModal('student-assessment').open();
  }

  loadNewFile(fileData, isControl = false) {
    fileData.forEach((file) => {
      const size = file.file.size / (1024 * 1024);
      if (size <= 50) {
        if (!isControl) {
          (this.form.get('files') as FormArray).push(
              new FormControl(file)
          );
        } else {
          (this.controlForm.get('files') as FormArray).push(
              new FormControl(file)
          );
        }
      } else {
        alert('Розмір файлу "' + file.file.name + '" перевищує 50Mb');
      }
    });

    if (!isControl) {
      // automatically send files
      if ((this.form.get('files') as FormArray).length) {
        this.createNewComment(isControl);
      }
    } else {
      // automatically send files
      if ((this.controlForm.get('files') as FormArray).length) {
        this.createNewComment(isControl);
      }
    }
  }

  removeNewFiles(index) {
    (this.form.get('files') as FormArray).removeAt(index);
  }

  createNewComment(isControl = false) {
    this.changeDetectionRef.detectChanges();
    const sendData = {
      text: !isControl ? this.form.get('comment').value : this.controlForm.get('comment').value,
    };

    const obs = [];
    if (this.mode === 'teacher') {
      sendData['lesson_planning_theme'] = this.themeId;
      sendData['student_uuid'] = this.studentUuid;
    }


    if (!isControl) {
      this.submitted = true;
      if (this.files.controls.length) {
        this.files.controls.forEach(control => {
          sendData['file_url'] = control.value.file;
          obs.push(this.myCabinetService.addHomeworkComment(this.mode, this.studentUuid, this.themeId, sendData));
        });
      }
      if (this.form.get('comment').value && !this.files.controls.length) {
        obs.push(this.myCabinetService.addHomeworkComment(this.mode, this.studentUuid, this.themeId, sendData));
      }
    } else {
      this.submittedControl = true;
      if (this.controlFiles.controls.length) {
        this.controlFiles.controls.forEach(control => {
          sendData['file_url'] = control.value.file;
          obs.push(this.myCabinetService.addControlComment(this.mode, this.studentUuid, this.themeId, sendData));
        });
      }
      if (this.controlForm.get('comment').value && !this.files.controls.length) {
        obs.push(this.myCabinetService.addControlComment(this.mode, this.studentUuid, this.themeId, sendData));
      }
    }

    concat(...obs).pipe(toArray()).subscribe(resp => {
      this.addCommentMessages(resp, isControl);
      this.submitted = false;
      this.submittedControl = false;
    });
  }

  addCommentMessages(data, isControl = false) {
    data.forEach(comment => {
      this.addOneMessage(comment, isControl);
    });
  }

  addOneMessage(comment, isControl = false) {
    const commentTime = new Date(comment.content.created_at);
    comment.content.created_at = commentTime.getHours() + ':' +
      (commentTime.getMinutes() < 10 ? '0' + commentTime.getMinutes() : commentTime.getMinutes());

    if (!isControl) {
      this.homeworkChat.comments.push(comment);
      this.form.get('comment').setValue('');
      this.files.clear();
    } else {
      this.controlChat.comments.push(comment);
      this.controlForm.get('comment').setValue('');
      this.controlFiles.clear();
    }

    this.updateDate();
    setTimeout(() => {
      this.downScroll(isControl);
    }, 100);
  }

  downScroll(isControl = false) {
    if (isControl) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    } else {
      const commentHeight = document.body.querySelector('.homework-messages .chat-item:last-child').clientHeight;
      const messagesContainerScroll = (window.scrollY + document.body.querySelector('.homework-messages .chat-item:last-child').getBoundingClientRect().top) - commentHeight;
      window.scrollTo({
        top: messagesContainerScroll,
        behavior: 'smooth'
      });
    }
  }

  removeComment(comment, isControl = false) {
    this.getDeletingPermission(comment).subscribe(response => {
      if (_.isBoolean(response) && response) {
        if (!isControl) {
          const commentToDeleteIndex = this.homeworkChat.comments.findIndex(one_comment => {
            return one_comment.content.id === comment.content.id;
          });
          this.homeworkChat.comments.splice(commentToDeleteIndex, 1);

          this.myCabinetService.deleteHomeworkComment(comment.content.id).subscribe();
        } else {
          const commentToDeleteIndex = this.controlChat.comments.findIndex(one_comment => {
            return one_comment.content.id === comment.content.id;
          });
          this.controlChat.comments.splice(commentToDeleteIndex, 1);

          this.myCabinetService.deleteControlComment(comment.content.id).subscribe();
        }
      } else {
        return;
      }
    });
  }

  getDeletingPermission(comment) {
    const deleting$: Subject<void> = new Subject();
    const result$: Subject<any> = new Subject();

    this.ngxSmartModalService.resetModalData('deleting-popup');
    this.ngxSmartModalService.getModal('deleting-popup').setData({
      title: 'Видалення',
      text: comment.content.text && !comment.content.file_url ? 'Видалити цей коментар?' : 'Видалити завантажений файл?',
      buttons: [{text: 'Скасувати', value: false, theme: 'white_gray'}, {text: 'Видалити', value: true, theme: 'blue'}],
      deleting$,
      onResult: (value) => {
        result$.next(value);
        this.ngxSmartModalService.getModal('deleting-popup').close();
      }
    });
    this.ngxSmartModalService.getModal('deleting-popup').onClose.subscribe(() => {
      result$.next({deleting$, value: false});
    });
    this.ngxSmartModalService.getModal('deleting-popup').open();

    return result$.pipe(take(1));
  }

  isImage(url) {
    if (url) {
      const ext = url.split(/[#?]/)[0].split('.').pop().trim();
      return ['jpg', 'jpeg', 'gif', 'png'].indexOf(ext.toLowerCase()) !== -1;
    }
  }

  fileTypePath(url) {
    const ext = url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
    if (['doc', 'docx'].indexOf(ext) !== -1) {
      return '/assets/images/icons/file-doc.svg';
    } else if (['mp4', 'mov', 'avi', '3gp', 'ogg', 'mpg', 'mpeg', 'wmv', 'webm'].indexOf(ext) !== -1) {
      return '/assets/images/icons/file-video.svg';
    } else if (ext === 'pdf') {
      return '/assets/images/icons/file-pdf.svg';
    } else if (ext === 'zip') {
      return '/assets/images/icons/file-zip.svg';
    }
    return '/assets/images/icons/file-icon.svg';
  }

  checkHomeWorkOrView(comment) {
    if (this.mode !== 'student') {
      let newFiles;
      const curFile = {
        file_url: comment.content.file_url,
        id: comment.content.id,
      };

      if (!comment.content.is_checked) {
        // Open all new student files for check
        newFiles = this.homeworkChat.comments.filter(
          userComment => this.isImage(userComment.content.file_url) && !userComment.content.is_checked
        ).map(userComment => {
          return {
            file_url: userComment.content.file_url,
            id: userComment.content.id,
          };
        });
      } else {
        // Open just this one file for edit
        newFiles = [curFile];
      }

      window.localStorage.setItem('homework-files', JSON.stringify(newFiles));
      window.localStorage.setItem('current-homework-file', JSON.stringify(curFile));
      window.localStorage.setItem('is-control', '0');
      this.router.navigate(['check-homework'], {relativeTo: this.route});
      return;
    }

    this.openMaterialsPopup(comment.content.file_url, this.mode);
  }

  checkControlWork(comment) {
    if (this.mode !== 'student') {
      let newFiles;
      const curFile = {
        file_url: comment.content.file_url,
        id: comment.content.id,
      };

      if (!comment.content.is_checked) {
        // Open all new student files for check
        newFiles = this.controlChat.comments.filter(
            userComment => this.isImage(userComment.content.file_url) && !userComment.content.is_checked
        ).map(userComment => {
          return {
            file_url: userComment.content.file_url,
            id: userComment.content.id,
          };
        });
      } else {
        // Open just this one file for edit
        newFiles = [curFile];
      }

      window.localStorage.setItem('homework-files', JSON.stringify(newFiles));
      window.localStorage.setItem('current-homework-file', JSON.stringify(curFile));
      window.localStorage.setItem('is-control', '1');
      this.router.navigate(['check-homework'], {relativeTo: this.route});
      return;
    }

    this.openMaterialsPopup(comment.content.file_url, this.mode);
  }

  openMaterialsPopup(file, mode) {
    const files = [file];
    this.ngxSmartModalService.resetModalData('homeworks-popup');
    this.ngxSmartModalService.getModal('homeworks-popup').setData(
      {
        role: mode,
        photos: files,
        selected_photo: file,
        showHomeworkButtons: false
      }
    );
    this.ngxSmartModalService.getModal('homeworks-popup').open();
  }

  get isTutorWithoutPermission() {
    return this.rootService.role.isTutor() && !this.rootService.role.hasPermission('journal_edit');
  }
}
