import { Component, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true
};

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CheckboxComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() view: string;
  @Input() text;
  @Input() size: string[] = ['16px', '16px'];
  @Input() isSelected = false;
  @Input() main: boolean = false;
  @Input() count: number;
  @Input() withUserPhoto: boolean = false;
  @Input() withClassPhoto: boolean = false;
  @Input() photo: string;
  @Input() initials: string[] = [];
  @Input() disabled = false;

  value: boolean | number = true;

  @Output() changeState: EventEmitter<any> = new EventEmitter();
  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSelected) {
      this.value = changes.isSelected.currentValue;
    }
  }

  @HostListener('click')
  click() {
    this.onTouched();
  }

  @HostListener('change')
  change() {
    this.onTouched();
  }

  onChange = (item) => {
  };

  onTouched = () => {

  };

  writeValue(v: boolean | number): void {
    this.value = v;
  }

  onToggleVal(v: boolean | number) {
    this.onTouched();
    this.writeValue(this.value);
    this.onChange(this.value);
    this.changeState.emit(this.value);
  }

  registerOnChange(fn: (item) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
