import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../+shared/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {ManagementService} from '../functional-modules/management/management.service';
import {SchoolSettingsModel} from '../+shared/models/school-management/school-settings.model';

@Component({
  templateUrl: './password-recovery.component.html',
  styleUrls: ['../+shared/style/auth-shared.scss', './password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  form: FormGroup;
  email = new FormControl(null, Validators.compose([
    Validators.required,
    Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  ]));
  wasSubmitted = false;
  wasSent = false;
  backUrl: string;
  errorMessage: string;
  wrongData = false;
  sendingReq = false;
  schoolInfo: SchoolSettingsModel;

  constructor(public fb: FormBuilder,
              public router: Router,
              public activatedRouter: ActivatedRoute,
              public userService: AuthService,
              public managementService: ManagementService) {
    this.managementService.setMetaTitle('Відновлення паролю');
  }

  ngOnInit(): void {
    this.activatedRouter.params
      .pipe(switchMap(({backUrl}) => {
        this.backUrl = backUrl || '/';
        return this.managementService.getSchoolSettings();
      }))
      .subscribe((school_info) => {
        this.schoolInfo = school_info;
      });
  }

  isValidField() {
    if (this.wrongData) {
      return false;
    }
    return this.wasSubmitted ? this.email.valid : null;
  }

  send() {
    this.wasSubmitted = true;

    if (this.email.valid) {
      this.sendingReq = true;
      this.userService.recoveryPassword(this.email.value).subscribe(() => {
        this.sendingReq = false;
        this.wasSent = true;
      }, () => {
        this.sendingReq = false;
        this.wrongData = true;
        this.errorMessage = 'email не знайдено в системі';
      });
    } else {
      this.errorMessage = 'невірний формат';
    }
  }

}
