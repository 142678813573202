import {Injectable} from '@angular/core';
import {AjaxService} from '../+shared/services/ajax.service';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {RootService} from '../+shared/services/root.service';
import {tap} from 'rxjs/operators';
import {PupilModel} from '../+shared/models/users/pupil.model';
import {ClassModel} from '../+shared/models/school-management/class.model';
import {AuthService} from '../+shared/services/auth.service';
import {UsersService} from '../functional-modules/users/users.service';
import {FinanceRequestModel} from '../+shared/models/finances/financeRequest.model';
import {FinancesService} from '../functional-modules/management/finances/finances.service';

@Injectable({
  providedIn: 'root'
})
export class ParentsService {

  changeChild$ = new ReplaySubject(1);
  changeTasksFilter$ = new BehaviorSubject({
    type: window.localStorage.getItem('tasks-type'),
    page: window.localStorage.getItem('tasks-page'),
    search: window.localStorage.getItem('tasks-search'),
  });
  childInfo: {
    user: PupilModel,
    user_class: ClassModel,
    finance: FinanceRequestModel
  };
  selectedChild = window.localStorage.getItem('selected-parents-child');

  constructor(public ajaxService: AjaxService,
              public authService: AuthService,
              public financesService: FinancesService,
              public usersService: UsersService,
              public rootService: RootService) {
    this.changeChild$.subscribe((child_info: { user: PupilModel, user_class: ClassModel, finance: FinanceRequestModel }) => {
      if (child_info) {
        this.childInfo = child_info;
      }
    });

    this.authService.auth$.subscribe((isAuth) => {
      if (isAuth && this.authService.user.getHighestRole().name === 'student') {
        this.usersService.getPupilAccount(this.authService.user.uuid).subscribe(pupil => {
          this.changeChild$.next({user: this.authService.user, user_class: pupil.class_object});
        });
      }
    });
  }

  getSectionPath(path) {
    const section = `${this.rootService.role.getSectionPath()}`;
    return `${section}/${path}`;
  }

  getChildren(parent_uuid) {
    return this.ajaxService.get('/student/parent-student/' + parent_uuid + '/')
      .pipe(
        tap((children) => {
          this.childInfo = children.find(child => child.user.uuid === this.selectedChild);
        })
      );
  }

  getLastStudentScores(student_uuid) {
    return this.ajaxService.get('/journal/student-last-scores/' + student_uuid + '/');
  }

  getActiveStudentHomeworks(student_uuid, page?, just_next?) {
    return this.ajaxService.get(`/student/homework-tasks/${student_uuid}/active/`, {page, just_next});
  }

  getUnfinishedStudentHomeworks(student_uuid, page?) {
    return this.ajaxService.get(`/student/homework-tasks/${student_uuid}/unfinished/`, {page});
  }

  getWithScoresStudentHomeworks(student_uuid, page?, search?) {
    return this.ajaxService.get(`/student/homework-tasks/${student_uuid}/with-score/`, {page, search});
  }

  isRedPaymentBanner() {
    let isNegativeBalance = false;

    this.childInfo.finance.balances.forEach(service => {
      if (service.is_section) {
        let balance = service.balance + service.paid;
        if (service.is_active) {
          balance -= service.actual_price;
        }

        if (balance < 0) {
          isNegativeBalance = true;
        }
      } else {
        if (this.financesService.calculateSchoolPrice(service, this.childInfo.finance.student_account) < 0) {
          isNegativeBalance = true;
        }
      }
    });

    return isNegativeBalance;
  }

  paymentBannerVisible() {
    if (this.childInfo && this.childInfo.finance && this.childInfo.finance.balances) {
      // TODO: remove it
      // if (window.localStorage.getItem('uuid') != '0401e496-1df4-4b77-afdc-7de2f874d5e9' && window.localStorage.getItem('uuid') != '7fa03052-cea6-49e7-ab7a-9db997cf7bb3') {
      //   return false;
      // }
      // end

      let visible = false;

      this.childInfo.finance.balances.forEach(service => {
        if (service.is_section) {
          let balance = service.balance + service.paid;
          if (service.is_active) {
            balance -= service.actual_price;
          }

          if (balance !== 0) {
            visible = true;
          }
        } else {
          if (this.financesService.calculateSchoolPrice(service, this.childInfo.finance.student_account) !== 0) {
            visible = true;
          }
        }
      });

      return visible;
    }

    return false;
  }

}
