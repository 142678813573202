import {Component} from '@angular/core';
import {ManagementService} from '../../../functional-modules/management/management.service';

@Component({
  selector: 'app-about-school',
  templateUrl: './about-school.component.html',
  styleUrls: ['./about-school.component.scss', '../../style/modules-shared.scss']
})
export class AboutSchoolComponent {

  constructor(public managementService: ManagementService) {
    this.managementService.setMetaTitle('Про школу');
  }

}
