import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {HelperService} from '../../services/helper.service';

@Component({
  selector: 'img-uploader',
  templateUrl: './img-uploader.component.html',
  styleUrls: ['./img-uploader.component.scss']
})
export class ImgUploaderComponent {

  @Input() url: string;
  @Input() size: 'lg' | 'sm' = 'lg';
  @Input() icon: 'add' | 'edit';
  @Input() default: string | '' = '';
  @Input() placeholder: string;

  @Output() uploadImage: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('input') input: ElementRef;

  mimeTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

  constructor() {
  }

  uploadNewImage(target, event) {
    const file = target.files[0];
    if (file && this.checkFile(file)) {
      HelperService.getDataImageFromFile(file as File).then((url: string) => {
        this.uploadImage.emit({
          file, url
        });
      });
    }
    event.preventDefault();
    event.stopPropagation();
  }

  checkFile(file) {
    return HelperService.isAllowedMimeType(file as File, this.mimeTypes) && HelperService.isAllowedSize(file, 5120);
  }

  getUrl() {
    if (this.url) {
      return this.url;
    }

    if (this.default === null) {
      return false;
    }

    return this.default || '/assets/images/icons/user-avatar-icon.svg';
  }

  openFileSelect() {
    this.input.nativeElement.click();
  }

}
