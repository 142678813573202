import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(public userService: AuthService,
              public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.canLoadAuthGuard().pipe(
      map((isAuth) => !isAuth),
      tap((isAllowed) => {
        if (!isAllowed) {
          this.router.navigateByUrl('/');
        }
        return isAllowed;
      })
    );
  }
}
