import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UsersService } from '../../../users/users.service';
import { RootService } from '../../../../+shared/services/root.service';
import * as _ from 'lodash';
import { PollsService } from '../../polls.service';
import * as moment from 'moment';

@Component({
    selector: 'poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss', '../../../../+shared/style/modules-shared.scss']
})
export class PollComponent implements OnInit {

    @Input() type = 'voter';
    @Input() data;
    @Input() destroy = false;

    selectedChoice = null;
    pollType = '';
    destroyTimeout = null;
    destroyed = false;
    classesString = '';
    voters = [];
    openedVoters = false;

    @Output() refresh: EventEmitter<any> = new EventEmitter();
    @Output() editQuestion: EventEmitter<any> = new EventEmitter();

    constructor(public usersService: UsersService,
                public rootService: RootService,
                public route: ActivatedRoute,
                public pollService: PollsService,
                public ngxSmartModalService: NgxSmartModalService) {
    }

    ngOnInit(): void {
        this.selectedChoice = this.data.choices.find((c: any) => {
          return c.user_vote;
        });

        this.pollType = this.getTypeText();
        this.classesString = this.getClassesString();
        this.voters = [];

        if (this.type === 'admin') {
            this.data.choices.forEach(choice => {
                choice.votes = choice.votes.map(vote => {
                    vote.answer = choice.answer;
                    return vote;
                });
                this.voters.push(...choice.votes);
            });

            this.voters.sort((vote1, vote2) => {
                const firstTime = moment(vote1.created_at || vote1.updated_at).unix();
                const secondTime = moment(vote2.created_at || vote2.updated_at).unix();

                return  firstTime > secondTime ? 1 : -1;
            });
        }

        if (this.destroy && this.isVoted) {
            this.destroyed = true;
        }
    }

    get getGender() {
        if (!this.data.gender) {
            return 'Усі гендери';
        }

        return _.find(this.rootService.genders, ['id', String(this.data.gender)]).text;
    }

    getClassesString() {
        if (this.pollService.allClasses.every(id => !!_.find(this.data.classes, ['id', +id]))) {
            return 'Усі класи';
        }

        return this.data.classes.map((i) => {
            return /\d/.test(i.grade.name) ? `${i.grade.name}-й ${i.name} (${i.grade.education_group})` : `${i.grade.name} ${i.name} (${i.grade.education_group})`
        }).join(', ');
    }

    getTypeText() {
        if (!this.rootService.role.isAdmin()) {
            return  null;
        }

        if (this.pollService.allRoles.length === this.data.roles.length) {
            return 'Усі ролі';
        } else {
            let category;

            _.forOwn(this.pollService.rolesParams, (rolesIds: string[], key) => {
                if (this.data.roles.every(id => rolesIds.includes(String(id)))) {
                    category = key;
                }
            });

            switch (category) {
                case 'teachers':
                    return 'Вчителів';
                case 'parents':
                    return 'Батьків';
                case 'students':
                    return 'Учнів';
                case 'administration':
                    return 'Адміністрації';
                case 'others':
                    return 'Інших фахівців';
            }
        }
    }

    act(rez) {
        switch (this.actions[rez].text) {
            case 'Редагувати опитування':
                this.onEditQuestion();
                break;
            case 'Публікувати опитування':
                this.publish(true);
                break;
            case 'Приховати опитування':
                this.publish(false);
                break;
            case 'Закрити опитування':
                this.close(true);
                break;
            case 'Відкрити опитування':
                this.close(false);
                break;
            case 'Видалити опитування':
                this.delete();
        }
    }

    onEditQuestion() {
        this.editQuestion.emit(this.data.id);
    }

    publish(publish) {
        const classes = _.map(this.data.classes, 'id');
        this.pollService.updateQuestion({...this.data, is_published: publish, classes}).subscribe(() => {
            this.data.is_published = publish;
        });
    }

    close(finish) {
        const classes = _.map(this.data.classes, 'id');

        this.pollService.updateQuestion({
            ...this.data,
            classes,
            is_finished: finish
        }).subscribe(() => {
            this.data.is_finished = finish;
        });
    }

    delete() {
        this.pollService.deleteQuestion(this.data).subscribe(() => {
            this.refresh.emit();
        });
    }

    toggleLike(choiceId) {
        if (!this.data.is_finished) {
            let voteId;

            this.data.choices.forEach((c: any) => {
                if (c.user_vote) {
                    voteId = c.user_vote;
                    c.user_vote = null;
                }
            });

            _.find(this.data.choices, ['id', choiceId]).user_vote = voteId;
            this.launchDestroy();
            this.pollService.vote(choiceId, voteId).subscribe((rez) => {
                _.find(this.data.choices, ['id', choiceId]).user_vote = rez.id;
                this.pollService.updatePolls();
            });
        }
    }

    launchDestroy() {
        if (this.destroy) {
            if (this.destroyTimeout) {
                clearTimeout(this.destroyTimeout);
                this.destroyTimeout = null;
            }

            this.destroyTimeout = setTimeout(() => {
                this.destroyTimeout = null;
                this.destroyed = true;
            }, 3000);
        }
    }

    getPercentage(choiceId) {
        const allVotes = this.getVotesCount;
        let targetVotes;

        if (!this.rootService.role.isAdmin()) {
            targetVotes = _.find(this.data.choices, ['id', choiceId]).user_votes_count;
        } else {
            targetVotes = _.find(this.data.choices, ['id', choiceId]).votes.length;
        }
        if (targetVotes === 0 || allVotes === 0) {
            return 0;
        }

        return Math.round(targetVotes / (allVotes / 100));
    }
    
    get isVoted() {
        return this.data.choices.some(c => !!c.user_vote);
    }

    get actions() {
        const actions = [{text: 'Редагувати опитування'}];

        if (!this.data.is_published) {
            actions.push({text: 'Публікувати опитування'});
        }

        if (this.data.is_published) {
            actions.push({text: 'Приховати опитування'});
        }

        if (!this.data.is_finished) {
            actions.push({text: 'Закрити опитування'});
        }

        if (this.data.is_finished) {
            actions.push({text: 'Відкрити опитування'});
        }

        actions.push({text: 'Видалити опитування'});

        return actions;
    }

    get getVotesCount() {
        const initialValue = 0;

        if (!this.rootService.role.isAdmin()) {
            return this.data.choices.reduce(
                (accumulator, currentValue) => accumulator + currentValue.user_votes_count,
                initialValue,
            );
        } else {
            return this.data.choices.reduce(
                (accumulator, currentValue) => accumulator + currentValue.votes.length,
                initialValue,
            );
        }

    }
}
