import {Injectable} from '@angular/core';
import {AjaxService} from '../../../+shared/services/ajax.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class FinancesService {
  public newCount$ = new BehaviorSubject<any>(0);
  public reportMonth: string;

  constructor(public ajaxService: AjaxService) {
  }

  getNewStudentsCount() {
    this.ajaxService.get(`/finance/new-students-count/`).subscribe(result => {
      this.newCount$.next(result.count);
    });
  }

  getStudentsList(filters?) {
    return this.ajaxService.get(`/finance/student-balances/`, filters);
  }

  getCurrentMonth() {
    return this.ajaxService.get(`/finance/current-month/`);
  }

  exportAllSettings() {
    return this.ajaxService.get(`/finance/settings-export/`);
  }

  getStudentBalance(uuid) {
    return this.ajaxService.get(`/finance/student-balances/${uuid}/`);
  }

  getBalanceLogList(filters?) {
    return this.ajaxService.get(`/finance/balance-log/`, filters);
  }

  exportBalanceLogList(filters?) {
    return this.ajaxService.get(`/finance/balance-log-export/`, filters);
  }

  getFinanceAccount(id) {
    return this.ajaxService.get(`/finance/account/${id}/`);
  }

  getAdminFinanceNotifications() {
    return this.ajaxService.get(`/finance/notifications/`);
  }

  getFinancesAccounts() {
    return this.ajaxService.get('/finance/account/');
  }

  updateFinanceAccount(data) {
    return this.ajaxService.put(`/finance/account/${data.id}/`, data);
  }

  addFinanceAccount(data) {
    return this.ajaxService.post(`/finance/account/`, data);
  }

  deleteFinanceAccount(id) {
    return this.ajaxService.delete(`/finance/account/${id}/`);
  }

  repeatActSend(id) {
    return this.ajaxService.put(`/finance/balance-log/${id}/`, {});
  }

  removeItemLog(id) {
    return this.ajaxService.delete(`/finance/balance-log/${id}/`, {});
  }

  serviceSetFinanceAccount(accountId, sectionId) {
    return this.ajaxService.put(`/finance/select-account/${accountId}/`, {
      section: sectionId
    });
  }

  changeSchoolService(value, serviceId) {
    return this.ajaxService.put(`/finance/school-service/${serviceId}/`, {
      value
    });
  }

  setStudentExternalId(student) {
    return this.ajaxService.put(`/finance/student-external-id/${student.uuid}/`, {
      id: student.student_account.external_id
    });
  }

  formatPrice(price) {
    return new Intl.NumberFormat('uk-UA',
      {style: 'currency', currency: 'UAH'}
    ).format(price);
  }

  updatePrice(uuid, prices, sendManually = false) {
    return this.ajaxService.put(`/finance/student-balances/`, {
      uuid,
      services: JSON.stringify(prices),
      send_act_manually: sendManually
    });
  }

  updateGuarantPayment(serviceId, price) {
    return this.ajaxService.put(`/finance/student-guarant-payment/`, {
      serviceId,
      price,
    });
  }

  formatDate(service, column) {
    return new Intl.DateTimeFormat('ru', {
      day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
    }).format(Date.parse(service[column]));
  }

  getApiEndpoints() {
    return this.ajaxService.get(`/finance/api-endpoint/`);
  }

  apiTestEndpoint(endpoint, update, requestData) {
    let postData = null;
    if (requestData && Object.keys(requestData).length) {
      postData = JSON.stringify(requestData);
    }

    return this.ajaxService.put(`/finance/api-endpoint/${endpoint.id}/`, {
      url: endpoint.url,
      header_key: endpoint.header_key,
      header_value: endpoint.header_value,
      auth_login: endpoint.auth_login,
      auth_password: endpoint.auth_password,
      request_data: postData,
      update
    });
  }

  updateServiceStatus(serviceId, uuid, contract, isActive) {
    return this.ajaxService.put(`/finance/service-status/${serviceId}/`, {
      uuid,
      contract,
      is_active: isActive
    });
  }

  calculateSchoolShouldPay(service, student_account) {
    // if (parseInt(student_account.count_payments) === 3 && this.reportMonth !== 'серпень') {
    //   return service.month_price * 3;
    // }
    return service.month_price;
  }

  calculateSchoolPrice(service, student_account) {
    let result = service.balance + service.paid;
    if (service.is_active) {
      const shouldAlreadyPay = this.calculateSchoolShouldPay(service, student_account);
      result -= shouldAlreadyPay;
    }
    return result;
  }

  totalBalance(services, student_account) {
    let totalBalance = 0;

    services.forEach(service => {
      if (service.is_section) {
        totalBalance += parseFloat(service.balance);
        totalBalance += parseFloat(service.paid);
        if (service.is_active) {
          totalBalance -= parseFloat(service.actual_price);
        }
      } else {
        totalBalance += this.calculateSchoolPrice(service, student_account);
      }
    });

    return totalBalance;
  }

  getParentBannerStatus() {
    return this.ajaxService.get(`/finance/parent-banner-status/`);
  }

  changeParentBannerStatus(status) {
    return this.ajaxService.put(`/finance/parent-banner-status/`, {
      status
    });
  }

}
