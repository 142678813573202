import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @Input() text = '';
  @Input() placeholder = '';
  @Input() minStringLength = 3;
  @Input() delay = 300;
  
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  private searchTimeout = null;

  constructor() {
  }


  onSearch() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.search.emit(this.text);
    }, this.delay);
  }

  onSearchBtnClick() {
    if (this.text) {
      this.text = '';
      this.onSearch();
    }
  }

}
