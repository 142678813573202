import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ElementRef} from '@angular/core';
import {MyCabinetService} from '../../../functional-modules/my-cabinet/my-cabinet.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HelperService} from '../../services/helper.service';
import Painterro from 'painterro';
import { RootService } from '../../services/root.service';
import { UsersService } from '../../../functional-modules/users/users.service';
import { AuthService } from '../../services/auth.service';

declare let Zwibbler;

@Component({
  selector: 'app-image-redactor',
  templateUrl: './image-redactor.component.html',
  styleUrls: ['./image-redactor.component.scss', '../../style/modules-shared.scss']
})
export class ImageRedactorComponent implements OnInit, OnDestroy {

  zwibblerObj;
  loaded = false;
  ctx;
  canvas: any;
  size: number[];
  currentPhoto: {
    file_url: string,
    id: number
  };
  allPhotos: {
    file_url: string,
    id: number
  }[];
  slideIndex = 1;
  painterro = null;
  isControl = false;

  constructor(private myCabinetService: MyCabinetService,
              private changeDetectionRef: ChangeDetectorRef,
              private router: Router,
              public rootService: RootService,
              public authService: AuthService,
              public myElement: ElementRef,
              private route: ActivatedRoute) {
  }

  async ngOnInit() {
    await this.waitForZwibblerLoad();

    this.currentPhoto = JSON.parse(window.localStorage.getItem('current-homework-file'));
    this.allPhotos = JSON.parse(window.localStorage.getItem('homework-files'));
    this.isControl = window.localStorage.getItem('is-control') === '1';
    this.slideIndex = this.allPhotos.findIndex(photo => {
      return photo.file_url === this.currentPhoto.file_url;
    });
    this.loadHomeWork(this.currentPhoto.file_url);

    window.addEventListener("touchstart", this.touchHandler, false);
  }

  touchHandler(event){
    if (event.touches.length > 1){
      event.preventDefault();
    }
  }

  waitForZwibblerLoad() {
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if ('Zwibbler' in window) {
          clearInterval(interval);
          resolve('');
          return;
        }
      }, 100);
    });
  }

  ngOnDestroy() {
    if (this.ctx) {
      this.ctx.destroy();
      this.ctx = null;
    }

    if (this.painterro) {
      this.painterro.hide();
    }
  }

  loadHomeWork(photo) {
    this.loaded = false;
    this.changeDetectionRef.detectChanges();
    this.drawImageFromWebUrl(photo).then(({url, size}) => {
      this.initEditor(url, size);
      this.loaded = true;
    });
  }

  drawImageFromWebUrl(source) {
    const $this = this;

    return new Promise((res) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';

      img.onload = function () {
        $this.canvas = document.createElement('canvas');
        const ctx = $this.canvas.getContext('2d');

        $this.canvas.height = (this as any).height;
        $this.canvas.width = (this as any).width;
        ctx.drawImage(this as any, 0, 0);

        const imgRatio = $this.canvas.width / $this.canvas.height;

        // mobile
        let editorWidth;
        if (window.innerWidth >= 1280) {
          editorWidth = (window.innerWidth - 210) * 0.75;
        } else {
          editorWidth = (window.innerWidth) * 0.9;
        }
        const editorHeight = Math.floor(editorWidth / imgRatio);

        $this.canvas.width = 1000;
        $this.canvas.height = Math.floor($this.canvas.width / imgRatio);
        ctx.drawImage(img, 0, 0, $this.canvas.width, $this.canvas.height);

        res({
          url: $this.canvas.toDataURL('image/jpg'),
          size: [editorWidth, editorHeight]
        });
      };

      img.setAttribute('src', source);
    });
  }

  initEditor(imgUrl, size: number[]) {
    this.size = size;
    setTimeout(() => {
      this.painterro = Painterro({
        id: 'homeworkWrap',
        language: 'ua',
        defaultTool: 'brush',
        fixMobilePageReloader: true,
        hiddenTools: ['crop', 'rect', 'ellipse', 'save', 'open', 'close', 'bucket'],
        translation: {
          name: 'ua',
          strings: {
            lineColor: 'Л',
            lineColorFull: 'Колір лінії',
            fillColor: 'З',
            fillColorFull: 'Колір заливки',
            alpha: 'П',
            alphaFull: 'Прозорість',
            lineWidth: 'Ш',
            lineWidthFull: 'Ширина лінії',
            arrowLength: 'Ш',
            arrowLengthFull: 'Ширина стрілки',
            eraserWidth: 'Ш',
            eraserWidthFull: 'Ширина ластику',
            textColor: 'Ц',
            textColorFull: 'Колір тексту',
            fontSize: 'Р',
            fontSizeFull: 'Розмір шрифта',
            fontStrokeSize: 'Ш',
            fontStrokeSizeFull: 'Ширина штриху',
            fontIsBold: '<b>Ж</b>',
            fontIsBoldFull: 'Жирний',
            fontIsItalic: '<i>К</i>',
            fontIsItalicFull: 'Курсив',
            shadowOn: 'Т',
            shadowOnFull: 'Тінь',
            fontStrokeAndShadow: 'О&Т',
            fontStrokeAndShadowFull: 'Обводка и Тінь',
            fontName: 'Ш',
            fontNameFull: 'Назва шрифту',
            textStrokeColor: 'КО',
            textStrokeColorFull: 'Колір обводки',
            apply: 'Застосувати',
            cancel: 'Відмінити',
            close: 'Закрити',
            clear: 'Очистити',
            width: 'Ширина',
            height: 'Висота',
            keepRatio: 'Зберегти співвідношення ширини/высоти',
            fillPageWith: 'Заполнити сторінку поточним кольором фону',
            pixelSize: 'П',
            pixelSizeFull: 'Розмір пікселю',
            resizeScale: 'Масштаб',
            resizeResize: 'Змінити розмір',
            backgroundColor: 'Колір фону сторінки',
            pixelizePixelSize: 'Пікселизація розміру пікселя',
            language: 'Мова',
            wrongPixelSizeValue:
                'Неправильний розмір пікселя. Ви можете ввести, наприклад \'20%\', що означає, що середній розмір пікселю буде 1/5 від' +
                'сторони вибраної області або \'4\', что означає 4 пікселю',
            tools: {
              crop: 'Обрізали зображення до виділеної області',
              pixelize: 'Зробити пікселізація виділеної області',
              rect: 'Намалювати прямокутник',
              ellipse: 'Намалювати елліпс',
              line: 'Намалювати лінію',
              arrow: 'Намалювати стрілку',
              rotate: 'Повернуть сторінку',
              save: 'Зберегти зображення',
              load: 'Завантажити зображення',
              text: 'Вставити текст',
              brush: 'Пензель',
              resize: 'Змінити розмір або масштаб',
              open: 'Відкрити зображення',
              select: 'Вибрати область',
              close: 'Закрити редактор',
              eraser: 'Ластик',
              settings: 'Налаштування',
              undo: 'Відмнити',
              redo: 'Повторити',
            },
            pasteOptions: {
              fit: 'Замінити все',
              extend_down: 'Додати вниз',
              extend_right: 'Додати вправо',
              extend_left: 'Додати влево',
              extend_top: 'Додати вверх',
              extend: 'Додати',
              over: 'Вставити',
              how_to_paste: 'Як вставити?',
            }
          }
        },
        saveHandler: (rez, done) => {
          if (this.router.url.includes('do-homework')) {
            let studentUUID;

            if (this.rootService.role.isStudent()) {
               studentUUID = this.authService.user.uuid;
            } else {
              studentUUID = window.localStorage.getItem('selected-parents-child');
            }

            const themeId = this.route.snapshot.params.lessonId;
            const data = {
              file_url: new File([rez.asBlob()], "image.jpeg", {
                type: rez.asBlob().type,
              }),
            };
            if (!this.isControl) {
              this.myCabinetService.addHomeworkComment('student', studentUUID, themeId, data).subscribe(() => {
                done(true);
                this.updateHomeworks();
              });
            } else {
              this.myCabinetService.addControlComment('student', studentUUID, themeId, data).subscribe(() => {
                done(true);
                this.updateHomeworks();
              });
            }
          } else {
            const data = {
              file_url: new File([rez.asBlob()], "image.jpeg", {
                type: rez.asBlob().type,
              }),
              student_uuid: this.route.snapshot.params.uuid,
              lesson_planning_theme: this.route.snapshot.params.themeId,
              student_comment_id: this.allPhotos[this.slideIndex].id
            };
            if (!this.isControl) {
              this.myCabinetService.addHomeworkComment('teacher', null, null, data).subscribe(() => {
                done(true);
                this.updateHomeworks();
              });
            } else {
              this.myCabinetService.addControlComment('teacher', this.route.snapshot.params.uuid, this.route.snapshot.params.themeId, data).subscribe(() => {
                done(true);
                this.updateHomeworks();
              });
            }
          }
        }
      }).show(imgUrl);
    }, 500);
  }

  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  getCanvasWidth() {
    return (this.size[0]) + 'px';
  }

  showSlides(n) {
    if (n >= this.allPhotos.length) {
      this.slideIndex = 0;
    }
    if (n < 0) {
      this.slideIndex = this.allPhotos.length - 1;
    }

    window.localStorage.setItem('current-homework-file', JSON.stringify(this.allPhotos[this.slideIndex]));
    this.loadHomeWork(this.allPhotos[this.slideIndex].file_url);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const key = event.key;

    if (key === 'ArrowLeft' && this.allPhotos.length) {
      this.plusSlides(-1);
    }

    if (key === 'ArrowRight' && this.allPhotos.length) {
      this.plusSlides(+1);
    }
  }

  save() {
    this.loaded = false;
    this.painterro.save();
    this.changeDetectionRef.detectChanges();
  }

  updateHomeworks() {
    this.allPhotos.splice(this.slideIndex, 1);
    window.localStorage.setItem('homework-files', JSON.stringify(this.allPhotos));
    if (this.allPhotos.length) {
      if (this.allPhotos[this.slideIndex]) {
        this.loadHomeWork(this.allPhotos[this.slideIndex].file_url);
      } else {
        this.slideIndex = 0;
        this.loadHomeWork(this.allPhotos[this.slideIndex].file_url);
      }
      window.localStorage.setItem('current-homework-file', JSON.stringify(this.allPhotos[this.slideIndex]));
    } else {
      this.cancel();
    }
  }

  cancel() {
    if (this.router.url.includes('do-homework')) {
      const params = {
        lesson: this.route.snapshot.queryParams.lesson
      };

      if (this.route.snapshot.queryParams.class_group) {
        params['class_group'] = this.route.snapshot.queryParams.class_group;
      }

      this.router.navigate(['parents', 'tasks', this.route.snapshot.params.lessonId], {
        queryParams: params
      });
    } else {
      this.router.navigate(['teachers/my-cabinet/schedule', this.route.snapshot.params.id, 'theme', this.route.snapshot.params.themeId, 'student', this.route.snapshot.params.uuid]);
    }
  }

}
