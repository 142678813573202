import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {AjaxService} from '../../+shared/services/ajax.service';
import {SchoolSettingsModel} from '../../+shared/models/school-management/school-settings.model';
import {concatMap, filter, map, mapTo, tap} from 'rxjs/operators';
import {of, zip} from 'rxjs';
import {RootService} from '../../+shared/services/root.service';
import {ParentsService} from '../../parents/parents.service';
import {SettingsContactModel} from '../../+shared/models/school-management/settings-contact.model';
import {UserModel} from '../../+shared/models/user.model';
import {Title} from '@angular/platform-browser';
import {PupilsService} from '../../pupils/pupils.service';

@Injectable()
export class ManagementService {

  schoolSettings: SchoolSettingsModel;
  schoolContacts: SettingsContactModel;
  schoolKeyPersons: { principal: UserModel, vice_principal: UserModel };
  scheduleTabs = [];
  aboutSchoolTabs = [];
  programsTabs = [];
  savedSchedule = null;

  constructor(public ajaxService: AjaxService,
              public rootService: RootService,
              private titleService: Title,
              public pupilsService: PupilsService,
              public parentsService: ParentsService) {
    this.rootService.roleChanging$.pipe(filter(role => !!role)).subscribe(() => {
      this.programsTabs = [
        {
          path: this.getSectionPath('programs/education-plan'),
          name: 'Навчальні плани'
        },
        /*
        {
          path: this.getSectionPath('programs/methodological-work'),
          name: 'Методологічна робота'
        }
         */
      ];

      this.scheduleTabs = [
        {
          path: this.getSectionPath('schedule/lessons-schedule'),
          name: 'Розклад уроків',
        },
        /*
        {
          path: this.getSectionPath('schedule/cabinets-schedule'),
          name: 'Розклад Кабінетів',
        },
        {
          path: this.getSectionPath('schedule/nominal-schedule'),
          name: 'Іменний розклад',
        },
         */
        {

          path: this.getSectionPath('schedule/education-schedule'),
          name: 'Графік навчання '
        },
        {
          path: this.getSectionPath('schedule/day-schedule'),
          name: 'Режим дня',
        },
      ];

      if (this.rootService.role.getRoleType() === 'parent' || this.rootService.role.getRoleType() === 'teacher') {
        this.aboutSchoolTabs = [
          {
            name: 'Загальна інформація',
            path: this.parentsService.getSectionPath('about-school/school-profile')
          },
          {
            name: 'Контактна інформація',
            path: this.parentsService.getSectionPath('about-school/contacts')
          },
          {
            name: 'Правила школи',
            path: this.parentsService.getSectionPath('about-school/school-rules')
          },
          {
            name: 'Графік навчання',
            path: this.parentsService.getSectionPath('about-school/education-schedule')
          }
        ];
      } else if (this.rootService.role.getRoleType() === 'pupils') {
        this.aboutSchoolTabs = [
          {
            name: 'Загальна інформація',
            path: this.pupilsService.getSectionPath('about-school/school-profile')
          },
          {
            name: 'Контактна інформація',
            path: this.pupilsService.getSectionPath('about-school/contacts')
          },
          {
            name: 'Правила школи',
            path: this.pupilsService.getSectionPath('about-school/school-rules')
          },
          {
            name: 'Графік навчання',
            path: this.pupilsService.getSectionPath('about-school/education-schedule')
          }
        ];
      } else {
        this.aboutSchoolTabs = [
          {
            name: 'Загальна інформація',
            path: this.getSectionPath('about-school/school-profile')
          },
          {
            name: 'Контактна інформація',
            path: this.getSectionPath('about-school/contacts')
          },
          {
            name: 'Правила школи',
            path: this.getSectionPath('about-school/school-rules')
          },
          {
            name: 'Кабінети',
            path: this.getSectionPath('about-school/material-base')
          },
        ];
      }
    });
  }

  setMetaTitle(title) {
    if (this.schoolSettings?.name) {
      title = title + ' - ' + this.schoolSettings.name;
    }
    this.titleService.setTitle(title);
  }


  checkSchoolSettings() {
    if (this.schoolSettings && this.schoolContacts && this.schoolKeyPersons) {
      return of(this.schoolSettings.hasName() && this.schoolContacts.haveKeyProps() && this.schoolKeyPersons.principal);
    }
    return this.setSchoolSettings();
  }

  setSchoolSettings() {
    return zip(this.getSchoolSettings(), this.getSchoolContacts()).pipe(
      tap(([schoolSettings, schoolContacts]) => {
        this.schoolSettings = new SchoolSettingsModel(schoolSettings);
        this.schoolContacts = new SettingsContactModel(_.omit(schoolContacts, ['principal', 'vice_principal']));
        this.schoolKeyPersons = {
          principal: schoolContacts.principal ? new UserModel(schoolContacts.principal) : null,
          vice_principal: schoolContacts.vice_principal ? new UserModel(schoolContacts.vice_principal) : null
        };
      }),
      map(() => {
        return this.schoolSettings.hasName() && this.schoolContacts.haveKeyProps() && this.schoolKeyPersons.principal;
      })
    );
  }

  getSectionPath(path) {
    const section = `${this.rootService.role.getSectionPath()}/management`;

    return `${section}/${path}`;
  }

  getSchoolSettings() {
    return this.ajaxService.get('/school/settings/general/');
  }

  getRegions() {
    return this.ajaxService.get('/school/region/');
  }

  getSchoolContacts() {
    return this.ajaxService.get('/school/settings/contact/');
  }

  updateSchoolContacts(data) {
    return this.ajaxService.put('/school/settings/contact/', data);
  }

  updateSchoolSettings(data) {
    return this.ajaxService.put('/school/settings/general/', data).pipe(
      concatMap((rez) => {
        return this.checkSchoolSettings().pipe(mapTo(rez));
      })
    );
  }

  getFinancesPayments() {
    return this.ajaxService.get('/finance/payment/');
  }

  getFinancesSettings() {
    return this.ajaxService.get('/school/settings/finance/');
  }

  updateFinancePayment(data) {
    return this.ajaxService.put(`/finance/payment/${data.id}/`, data);
  }

  updateFinanceSettings(data) {
    return this.ajaxService.put('/school/settings/finance/', data);
  }

  getSchoolRules() {
    return this.ajaxService.get('/school/settings/rule/');
  }

  updateSchoolRules(data) {
    return this.ajaxService.put('/school/settings/rule/', data);
  }

  getSchoolSemesters() {
    return this.ajaxService.get('/school/year/semester/');
  }

  getSchoolExcludedDays() {
    return this.ajaxService.get('/school/exclude-day/');
  }

  updateSemester(data) {
    return this.ajaxService.put(`/school/year/semester/${data.id}/`, data);
  }

  updateExcludeDays(data) {
    return this.ajaxService.put(`/school/exclude-day/${data.id}/`, data);
  }

  addExcludeDays(data) {
    return this.ajaxService.post(`/school/exclude-day/`, data);
  }

  deleteExcludeDays(data) {
    return this.ajaxService.delete(`/school/exclude-day/${data.id}/`);
  }

  getGradeLessons(filter_grade?, with_events?) {
    return this.ajaxService.get('/school/grade-lesson/', {filter_grade, with_events});
  }

  addGradeLesson(data) {
    return this.ajaxService.post(`/school/grade-lesson/`, data);
  }

  updateGradeLesson(data) {
    return this.ajaxService.put(`/school/grade-lesson/${data.id}/`, data);
  }

  deleteGradeLesson(id) {
    return this.ajaxService.delete(`/school/grade-lesson/${id}/`);
  }

  getAvailableScheduleParams(classId, data) {
    return this.ajaxService.post(`/schedule/planning-other-available/${classId}/`, data);
  }

  getConflictDetails(classId, data) {
    return this.ajaxService.post(`/schedule/planning-other-available/${classId}/`, data, true);
  }

  getSchoolRooms(filters) {
    return this.ajaxService.get('/school/classroom/', filters);
  }

  addSchoolRoom(data) {
    return this.ajaxService.post('/school/classroom/', data);
  }

  updateSchoolRoom(data) {
    return this.ajaxService.put(`/school/classroom/${data.id}/`, data);
  }

  deleteSchoolRoom(id) {
    return this.ajaxService.delete(`/school/classroom/${id}/`);
  }

  getLessons() {
    return this.ajaxService.get('/school/lesson/');
  }

  getRoomsFloorsInfo() {
    return this.ajaxService.get('/school/classroom-type-floor/');
  }

  getAllUsers(type) {
    return this.ajaxService.get(`/user/all/${type}/name-list/`);
  }

  getGrades(with_schedule_call = false) {
    return this.ajaxService.get('/school/grade/', {with_schedule_call});
  }

  getCalls() {
    return this.ajaxService.get('/schedule/calls/');
  }

  getClassesScheduleProgress() {
    return this.ajaxService.get('/schedule/class-progress/');
  }

  setScheduleStep(class_id, last_step) {
    return this.ajaxService.put(`/schedule/class-progress/${class_id}/`, {
      class_id,
      last_step
    });
  }

  getDefaultDaySchedule(grade) {
    return this.ajaxService.get(`/schedule/calls/${grade}/default/`);
  }

  getDaySchedule(grade) {
    return this.ajaxService.get(`/schedule/calls/${grade}/`);
  }

  addDaySchedule(grade, calls) {
    return this.ajaxService.post(`/schedule/calls/${grade}/`, {
      calls
    }, true);
  }

  getAllTeachers() {
    return this.ajaxService.get('/teacher/list-all/');
  }

  getClassRoms() {
    return this.ajaxService.get('/school/classroom-education/');
  }

  addClassGroup(data) {
    return this.ajaxService.post('/student/class-group/', data);
  }

  updateClassGroup(data) {
    return this.ajaxService.put('/student/class-group/', data);
  }

  getClassGroups(filter_class?) {
    return this.ajaxService.get(`/student/class-group/`, filter_class ? {filter_class} : null);
  }

  getClassLessons(filter_class?) {
    return this.ajaxService.get('/student/class-lesson/', filter_class ? {filter_class} : null);
  }

  addClassLesson(data) {
    return this.ajaxService.post(`/student/class-lesson/`, data);
  }

  updateScheduleWeeks(grade, schedule_weeks: string) {
    return this.ajaxService.put(`/school/grade-schedule-weeks/${grade}/`, {id: grade, schedule_weeks});
  }

  getSchedulePlaning(class_id, refreshBuffer = false) {
    if (!refreshBuffer && this.savedSchedule) {
      return of(this.savedSchedule);
    }

    return this.ajaxService.get(`/schedule/planning/${class_id}/`).pipe(concatMap((rez) => {
      if (refreshBuffer || !this.savedSchedule) {
        this.savedSchedule = rez;
      }

      return of(this.savedSchedule);
    }));
  }

  setSchedulePlaning(class_id, data) {
    return this.ajaxService.post(`/schedule/planning/${class_id}/`, data, true);
  }

  getScheduleConflicts(class_id, data?) {
    return this.ajaxService.post(`/schedule/planning-all-not-available/${class_id}/`, data);
  }

  getGradeEvents(filter_grade, kitchen_events = false) {
    return this.ajaxService.get('/school/grade-event/', {filter_grade, kitchen_events});
  }


  addGradeEvent(data) {
    return this.ajaxService.post('/school/grade-event/', data);
  }

  updateGradeEvent(data) {
    return this.ajaxService.put(`/school/grade-event/${data.id}/`, data);
  }

  deleteGradeEvent(id) {
    return this.ajaxService.delete(`/school/grade-event/${id}/`);
  }

  replaceTeacher(newUuid, scheduleId) {
    return this.ajaxService.put(`/schedule/planning-replace-other/teacher/${scheduleId}/`, {
      new_uuid: newUuid,
      schedule_id: scheduleId
    });
  }

  replaceClassroom(newClassroom, scheduleId) {
    return this.ajaxService.put(`/schedule/planning-replace-other/classroom/${scheduleId}/`, {
      new_classroom: newClassroom,
      schedule_id: scheduleId
    });
  }

  deleteSecondWeekSchedule(classId) {
    return zip(this.getSchedulePlaning(classId), this.getClassesScheduleProgress()).pipe(
      concatMap(([scheduleData, classesProgress]) => {
        const publish = _.find(classesProgress, ['class.id', +classId]).is_published;

        return this.setSchedulePlaning(classId, {
          publish,
          schedule: scheduleData.schedule.filter(({week_num}) => week_num === 1).map((item) => {
            if (item.lesson) {
              return {
                week_num: item.week_num,
                week_day: item.week_day,
                lesson: item.lesson.id,
                start_diff_minutes: item.start_diff_minutes,
                count_minutes: item.count_minutes,
                break_minutes: item.break_minutes,
                class_group: item.group ? item.group.id : null,
                classroom: item.classroom ? item.classroom.id : null,
                order_num: item.order_num,
                teacher_uuid: item.teacher_user.uuid
              };
            } else {
              return {
                week_num: item.week_num,
                week_day: item.week_day,
                event: item.event.id,
                start_diff_minutes: item.start_diff_minutes,
                count_minutes: item.count_minutes,
                break_minutes: item.break_minutes,
                order_num: item.order_num
              };
            }
          })
        }).pipe(
          mapTo(scheduleData.schedule.some(({week_num}) => +week_num === 2))
        );
      }),
    );
  }

  resolveTeacherInConflict(data) {
    return this.ajaxService.put(`/schedule/planning-replace-other/teacher/${data.scheduleId}/`, {new_uuid: data.uuid});
  }

  resolveClassroomInConflict(data) {
    return this.ajaxService.put(`/schedule/planning-replace-other/classroom/${data.scheduleId}/`, {new_classroom: data.newClassroom});
  }

  addWeekLesson(class_id, data) {
    return this.ajaxService.post(`/schedule/add-one-lesson/${class_id}/`, data);
  }

  generateJournalDay(date) {
    return this.ajaxService.post('/journal/_test-generate-journal-day/', {date});
  }


}
