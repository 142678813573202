import {Base} from '../base.model';
import {UserModel} from '../user.model';
import {PupilModel} from '../users/pupil.model';

export class ClassModel extends Base {

  id: string;
  name: string;
  logo: string;
  photo: string;
  grade: {
    id: number;
    name: string;
    education_group: string
  };
  teacher_user: UserModel;
  tutor_user?: UserModel;
  students?: PupilModel[];
  classroom: string;

  constructor(items) {
    super();
    super.loadFields(items);

    if (items && items.teacher_user) {
      super.loadRelations({
        teacher_user: UserModel,
      }, items);
      super.loadFields(items);
    }
  }

}
