import {Base} from '../base.model';
import * as _ from 'lodash';
import {Validators} from '@angular/forms';
import {SemesterModel} from './semester.model';
import {GradeLessonModel} from './grade-lesson.model';

export class SchoolSettingsModel extends Base {

  name: string;
  description: string;
  logo_url: string | File;
  photo_url: string | File;
  type?: string;
  start_work_week: string;
  end_work_week: string;
  start_work_sat: string;
  end_work_sat: string;
  start_work_sun: string;
  end_work_sun: string;
  work_days: string;
  accreditation: string;
  facebook_url: string;
  is_online: boolean;
  is_uniform: boolean;
  is_finance_integration: boolean;
  is_currency: boolean;
  website_url: string;
  website_url_href: string;
  semesters: SemesterModel[];

  constructor(items) {
    super();
    super.loadRelations({
      semesters: [SemesterModel],
    }, items);
    super.loadFields(items);

    this.type = this.type || '1';
    this.accreditation = this.accreditation || '1';
    this.logo_url = this.logo_url ? this.logo_url + '?timestamp=' + new Date().getTime() : null;
  }

  hasWorkDays(type: 'weekdays' | 'saturday' | 'sunday') {
    switch (type) {
      case 'weekdays':
        return this.work_days && (this.work_days || '').split(',').some(d => (+d) < 6);
      case 'saturday':
        return this.work_days && (this.work_days || '').split(',').some(d => (+d) === 6);
      case 'sunday':
        return this.work_days && (this.work_days || '').split(',').some(d => (+d) === 0);
    }
  }

  hasName() {
    return !!this.name;
  }


}
