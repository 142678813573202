import { Injectable, } from '@angular/core';
import { AjaxService } from '../../+shared/services/ajax.service';

@Injectable()
export class KitchenService {

  tabs = [];

  constructor(public ajaxService: AjaxService) {

  }

  getMenu() {
    return this.ajaxService.get('/kitchen/menu/');
  }

  updateMenu(data) {
    return this.ajaxService.post('/kitchen/menu/', data, true);
  }
}
