import {AfterViewInit, Component} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {FormBuilder} from '@angular/forms';
import {StudentBalanceModel} from '../../models/finances/studentBalance.model';
import {ManagementService} from '../../../functional-modules/management/management.service';
import {FinancesService} from '../../../functional-modules/management/finances/finances.service';

@Component({
  selector: 'services-popup',
  templateUrl: './services-popup.component.html',
  styleUrls: [
    './services-popup.component.scss',
    '../../style/user.scss',
    '../../style/popups-shared.scss'
  ]
})
export class ServicesPopupComponent implements AfterViewInit {

  modalData: any = {};
  userData: StudentBalanceModel;
  loaded = false;
  wasSubmitted = false;
  openedIndex: number;
  currentMonth: string;
  isChanged = false;
  saving: boolean;
  saveErrors = {};

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public financesService: FinancesService,
              public fb: FormBuilder) {
  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('services-popup')
      .onOpen.subscribe(() => {
      this.wasSubmitted = false;
      this.modalData = this.ngxSmartModalService.getModal('services-popup').getData();
      this.currentMonth = this.modalData['uuid'];

      this.financesService.getStudentBalance(this.modalData['uuid']).subscribe(response => {
        if (response.result) {
          response.result.student_account.discount_other = this.isCheckedOtherDiscount(response.result.student_account.discount);
          this.userData = new StudentBalanceModel(response.result);
          this.loaded = true;
          this.isChanged = false;
          this.openedIndex = null;
        } else {
          alert('Server error!');
        }
      });
    });
  }

  isCheckedOtherDiscount(discount) {
    return [0, 5, 7, 10, 30].indexOf(discount) === -1;
  }

  toggleServiceDetails(index) {
    if (this.openedIndex === index) {
      this.openedIndex = null;
    } else {
      this.openedIndex = index;
    }
  }

  changePrice(service = null, student_account = null) {
    this.isChanged = true;
    if (service) {
      const discountPrice = (service.full_price / 100) * student_account.discount;
      service.actual_price = service.full_price - discountPrice;

      if (parseInt(student_account.count_payments) === 12) {
        student_account.guarant_payment = service.actual_price / 12;
      } else {
        student_account.guarant_payment = service.actual_price / 9;
      }
    }
  }

  switchDiscountType(student_account) {
    student_account.discount_other = !student_account.discount_other;
  }

  formatDate(date) {
    return new Intl.DateTimeFormat('ru', {
      day: 'numeric', month: 'numeric', year: 'numeric'
    }).format(Date.parse(date));
  }

  save() {
    this.wasSubmitted = true;
    const userUUID = this.userData.uuid;
    const servicePrices = {};

    this.userData.services.forEach(service => {
      if (service.is_active) {
        if (service.is_section) {
          servicePrices[service.id] = {
            actual_price: service.actual_price,
            contract_id: service.contract_id
          };
        } else {
          const studentAccount = this.userData.student_account;
          servicePrices[service.id] = {
            full_price: service.full_price,
            actual_price: service.actual_price,
            guarant_payment: studentAccount.guarant_payment,
            discount: studentAccount.discount,
            count_payments: studentAccount.count_payments,
            contract_id: service.contract_id
          };
        }
      }
    });

    this.financesService.updatePrice(userUUID, servicePrices).subscribe((result) => {
      this.wasSubmitted = false;
      if (this.modalData.onSave) {
        this.modalData.onSave();
      }
      if (result) {
        this.saveErrors = {};
        for (const key in result) {
          if (!result.hasOwnProperty(key)) {
            continue;
          }
          const obj = result[key];
          if (obj['Код відповіді'] !== 200) {
            this.saveErrors[key] = obj['Помилка'];
          }
        }

        if (!Object.keys(this.saveErrors).length) {
          this.cancel();
        }
      } else {
        alert('Server error!');
      }
    }, () => {
      this.wasSubmitted = false;
      if (this.modalData.onSave) {
        this.modalData.onSave();
      }
      alert('Server error!');
    });
  }

  priceInfoText(count_payments) {
    if (this.financesService.reportMonth !== 'серпень') {
      if (parseInt(count_payments) === 3) {
        return ' за 3 місяці';
      }
      return ' за місяць';
    }
  }

  cancel() {
    this.ngxSmartModalService.getModal('services-popup').close();
  }

}
