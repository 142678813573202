import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {RootService} from '../../../services/root.service';
import {UsersService} from '../../../../functional-modules/users/users.service';
import {AuthService} from '../../../services/auth.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, first, takeWhile, tap} from 'rxjs/operators';
import {HelperService} from '../../../services/helper.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Observer, of, zip} from 'rxjs';
import * as _ from 'lodash';
import {UserModel} from '../../../models/user.model';

@Component({
  selector: 'contacts-info-popup',
  templateUrl: './contacts-info-popup.component.html',
  styleUrls: ['./contacts-info-popup.component.scss', '../../../style/popups-shared.scss']
})
export class ContactsInfoPopupComponent implements AfterViewInit, OnDestroy {

  work = true;
  loaded = false;
  modalData: any = {};
  wasSubmitted = false;
  files: any = {};
  contactsFormError: any = {
    email: HelperService.formErrors.incorrectFormat,
    phone: HelperService.getErrMessage(HelperService.formErrors.incorrectFormat, `<br> (приклад - ${HelperService.phoneSample})`),
    first_name: HelperService.formErrors.requiredField,
    last_name: HelperService.formErrors.requiredField,
    gender: HelperService.formErrors.requiredField,
    principal_email: HelperService.formErrors.incorrectFormat,
    vice_principal_email: HelperService.formErrors.incorrectFormat,
  };
  personsFormGroup: {
    principal: FormGroup,
    vice_principal: FormGroup
  };
  persons: {
    principal: UserModel,
    vice_principal: UserModel
  };
  savedPersons: {
    principal: UserModel,
    vice_principal: UserModel
  };
  regions = [];
  currentDate: NgbDate;
  minBirthDate;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public rootService: RootService,
              public usersService: UsersService,
              public changeDetectorRef: ChangeDetectorRef,
              public authService: AuthService,
              public fb: FormBuilder) {
  }


  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('contacts-info-popup')
      .onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.initForm();
      });
  }

  ngOnDestroy() {
    this.work = false;
  }

  initForm() {
    const currDate = new Date();

    this.modalData = this.ngxSmartModalService.getModal('contacts-info-popup').getData();
    this.wasSubmitted = false;
    this.loaded = false;
    this.currentDate = new NgbDate(currDate.getFullYear(), currDate.getMonth() + 1, currDate.getDate());
    this.minBirthDate = new NgbDate(1900, 1, 1);

    zip(
      this.managementService.getSchoolContacts(),
      this.loadRegions()
    ).subscribe(([data]) => {
      this.persons = {
        principal: data.principal ? new UserModel(_.cloneDeep(data.principal)) : new UserModel(),
        vice_principal: data.vice_principal ? new UserModel(_.cloneDeep(data.vice_principal)) : new UserModel(),
      };

      this.savedPersons = {
        principal: data.principal ? new UserModel(_.cloneDeep(data.principal)) : new UserModel(),
        vice_principal: data.vice_principal ? new UserModel(_.cloneDeep(data.vice_principal)) : new UserModel(),
      };

      this.personsFormGroup = {
        principal: this.fb.group({
          photo: [this.persons.principal ? this.persons.principal.photo : ''],
          first_name: [this.persons.principal ? this.persons.principal.first_name : '', Validators.required],
          last_name: [this.persons.principal ? this.persons.principal.last_name : '', Validators.required],
          surname: [this.persons.principal ? this.persons.principal.surname : ''],
          birthday: [this.persons.principal ? HelperService.getNgbDateFormat(this.persons.principal.birthday) : ''],
          gender: [this.persons.principal.gender || '1', Validators.required],
          email: [
            this.persons.principal ? this.persons.principal.email : '',
            Validators.pattern(HelperService.emailPattern),
            this.getEmailValidation.bind(this, this.persons.principal ? this.persons.principal.email : '')
          ],
          phone: [
            this.persons.principal ? this.persons.principal.phone : '',
            Validators.compose([Validators.minLength(21), Validators.pattern(HelperService.phonePattern)]),
            this.getPhoneValidation.bind(this, this.persons.principal ? this.persons.principal.phone : '')
          ]
        }),
        vice_principal: this.fb.group({
          photo: [this.persons.vice_principal ? this.persons.vice_principal.photo : null],
          first_name: [this.persons.vice_principal ? this.persons.vice_principal.first_name : '', Validators.required],
          last_name: [this.persons.vice_principal ? this.persons.vice_principal.last_name : '', Validators.required],
          surname: [this.persons.vice_principal ? this.persons.vice_principal.surname : ''],
          birthday: [this.persons.vice_principal ? HelperService.getNgbDateFormat(this.persons.vice_principal.birthday) : ''],
          gender: [this.persons.vice_principal.gender || '1', Validators.required],
          email: [
            this.persons.vice_principal ? this.persons.vice_principal.email : '',
            Validators.pattern(HelperService.emailPattern),
            this.getEmailValidation.bind(this, this.persons.vice_principal ? this.persons.vice_principal.email : '')
          ],
          phone: [this.persons.vice_principal ? this.persons.vice_principal.phone : '',
            Validators.compose([Validators.minLength(21), Validators.pattern(HelperService.phonePattern)]),
            this.getPhoneValidation.bind(this, this.persons.vice_principal ? this.persons.vice_principal.phone : '')
          ]
        })
      };

      this.personsFormGroup.principal.valueChanges
        .pipe(debounceTime(200))
        .subscribe((rez) => {
          _.forOwn(rez, (val, key) => {
            this.persons.principal[key] = val;
          });
        });

      this.personsFormGroup.vice_principal.valueChanges
        .pipe(debounceTime(200))
        .subscribe((rez) => {
          _.forOwn(rez, (val, key) => {
            this.persons.vice_principal[key] = val;
          });
        });

      this.loaded = true;
    });
  }

  loadRegions() {
    return this.managementService.getRegions().pipe(tap((data) => {
      this.regions = data.map(({id, name}) => {
        return {
          id: String(id),
          text: name
        };
      });
    }));
  }

  getEmailValidation(excludedEmail, control: FormControl) {
    if (!control.value) {
      return of(null).pipe(first());
    }

    return new Observable((observer: Observer<any>) => {
      if (excludedEmail && control.value === excludedEmail) {
        observer.next(null);
      } else {
        this.usersService.getEmailStatus(control.value).subscribe(() => {
          observer.next(null);
        }, () => {
          observer.next({emailTaken: true});
        });
      }

    }).pipe(first());
  }

  getPhoneValidation(excludedPhone, control: FormControl) {
    const val = control.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

    if (!val) {
      return of(null).pipe(first());
    }

    return new Observable((observer: Observer<any>) => {
      if (excludedPhone && HelperService.formatPhone(val) === excludedPhone) {
        observer.next(null);
      } else {
        this.usersService.getPhoneStatus(val).subscribe(() => {
          observer.next(null);
        }, () => {
          observer.next({phoneTaken: true});
        });
      }
    }).pipe(first());
  }

  onEmailChange(formType) {
    const allControls = {
      principal: this.personsFormGroup.principal.get('email'),
      vice_principal: this.personsFormGroup.vice_principal.get('email')
    };
    const controlVal = allControls[formType].value;

    _.forOwn(allControls, (control, fieldName) => {
      if (formType !== fieldName) {
        if (control.value === controlVal) {
          allControls[formType].setValue('');
        }
      }
    });
  }

  onPhoneChange(formType) {
    const allControls = {
      principal: this.personsFormGroup.principal.get('phone'),
      vice_principal: this.personsFormGroup.vice_principal.get('phone')
    };
    const controlVal = (allControls[formType].value || '').replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

    _.forOwn(allControls, (control, fieldName) => {
      if (formType !== fieldName) {
        const val = control.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

        if (val === controlVal) {
          allControls[formType].setValue('');
        }
      }
    });
  }

  onLoadPhoto(type: 'vice_principal' | 'principal', data) {
    const {url, file} = data;

    this.files[type] = file;
    this.personsFormGroup[type].get('photo').setValue(url);
  }

  getContactsFieldState(field, group) {
    if (!this.wasSubmitted) {
      return null;
    }

    return this.personsFormGroup[group].get(field).valid;
  }

  cancel() {
    this.ngxSmartModalService.getModal('contacts-info-popup').close();
  }

  save() {
    this.wasSubmitted = true;

    if (this.personsFormGroup.principal.valid && this.personsFormGroup.vice_principal.valid) {
      const requests = [];

      const principal: any = _.cloneDeep(this.persons.principal);
      const vice_principal: any = _.cloneDeep(this.persons.vice_principal);

      principal.photo = this.files.principal;
      principal.role_id = _.find(this.rootService.allRoles, ['title', 'Директор']).id;
      vice_principal.photo = this.files.vice_principal;
      vice_principal.role_id = _.find(this.rootService.allRoles, ['title', 'Заступник директора']).id;


      if (this.persons.principal.birthday && this.persons.principal.birthday instanceof NgbDate) {
        principal.birthday = HelperService.getDateFromNgbDate(this.persons.principal.birthday as any);
      }

      if (this.persons.vice_principal.birthday && this.persons.vice_principal.birthday instanceof NgbDate) {
        vice_principal.birthday = HelperService.getDateFromNgbDate(this.persons.vice_principal.birthday as any);
      }

      if (!this.savedPersons.principal.uuid) {
        requests.push(this.usersService.addUserAccount(principal));
      } else {
        requests.push(this.usersService.updateUserAccount(principal));
      }

      if (!this.savedPersons.vice_principal.uuid) {
        requests.push(this.usersService.addUserAccount(vice_principal));
      } else {
        requests.push(this.usersService.updateUserAccount(vice_principal));
      }

      this.loaded = false;
      zip(...requests).subscribe(() => {
        if (this.modalData.onSave) {
          this.modalData.onSave();
        }
        this.cancel();
      });
    }
  }

}
