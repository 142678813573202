import {Injectable,} from '@angular/core';
import {AjaxService} from './ajax.service';
import {UserModel} from '../models/user.model';
import {catchError, filter, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {

  user: UserModel;
  auth$: Subject<any> = new Subject<any>();
  notificationsInterval;

  constructor(public ajaxService: AjaxService,
              public router: Router) {
  }

  registrateUser(data) {
    return this.ajaxService.post('/user/register/', data);
  }

  updateUser(data) {
    return this.ajaxService.put(`/user/account/${data.uuid}/`, data);
  }

  login(data) {
    return this.ajaxService.post('/user/login/', data).pipe(tap((loginData) => {
      _.forOwn(loginData, (val: any, k: string) => {
        window.localStorage.setItem(k, val);
      });

      this.loadUser().subscribe(() => {
        this.auth$.next(true);
        this.router.navigateByUrl(this.user.getHighestRole().getSectionPath());
      });
    }));
  }

  logout() {
    return this.ajaxService.post('/user/logout/', {
      token: window.localStorage.getItem('refresh'),
      uuid: window.localStorage.getItem('uuid')
    }).pipe(tap(() => {
      const clearCookies = [
        'access', 'uuid', 'refresh', 'child-class-id', 'schedule-week-date', 'schedule-week-timestamp', 'class-group', 'view-journal-mode',
        'open-lesson-from', 'homework-files', 'current-homework-file', 'achievements-period', 'achievements-students', 'achievements-tasks'
      ];

      clearCookies.forEach(cookie => {
        window.localStorage.removeItem(cookie);
      });

      clearInterval(this.notificationsInterval);

      this.router.navigateByUrl('/login');
      this.user = null;
      this.auth$.next(false);
    }));
  }

  loadUser() {
    if (!window.localStorage.getItem('uuid')) {
      return of(null);
    }

    return this.ajaxService.get(`/user/account/${window.localStorage.getItem('uuid')}/`)
      .pipe(
        tap((user) => {
          this.user = new UserModel(user);
          this.auth$.next(this.user);

          this.getNotifications();
          if (this.notificationsInterval) {
            clearInterval(this.notificationsInterval);
          }
          this.notificationsInterval = setInterval(() => {
            this.getNotifications();
          }, 5 * 60000);

        }), catchError((err) => {
          window.localStorage.removeItem('access');
          window.localStorage.removeItem('uuid');
          window.localStorage.removeItem('refresh');

          return of(err);
        })
      );
  }

  getNotifications() {
    let url = `/user/notification-count/${this.user.uuid}/`;
    if (window.localStorage.getItem('selected-parents-child')) {
      url += `${window.localStorage.getItem('selected-parents-child')}/`;
    }

    this.ajaxService.get(url).subscribe(result => {
      this.user.count_new.notifications = result.notifications;
      this.user.count_new.messages = result.messages;
      if (result.users) {
        this.user.count_new.users = result.users;
      }
    });
  }

  recoveryPassword(email) {
    return this.ajaxService.post('/user/password-forget/', {email});
  }

  refreshAccessToken() {
    return this.ajaxService.post('/user/token-refresh/', {
      refresh: window.localStorage.getItem('refresh')
    }).pipe(tap((rez: any) => {
      window.localStorage.setItem('access', rez.access);
    }));
  }

  getAccessToken() {
    const token = window.localStorage.getItem('access');
    return token ? 'Bearer ' + token : null;
  }

  updateForgetPassword(password, key) {
    return this.ajaxService.put(`/user/password-forget/${key}/`, {password});
  }

  canLoadAuthGuard() {
    const $res = new BehaviorSubject(null);

    if (this.user) {
      $res.next(this.user);
    } else if (this.getAccessToken()) {
      this.ajaxService.get(`/user/account/${window.localStorage.getItem('uuid')}/`).subscribe((user) => {
          this.user = new UserModel(user);
          $res.next(new UserModel(user));
        },
        () => {
          $res.next(false);
        });
    } else {
      $res.next(false);
    }

    return $res.pipe(filter(res => (typeof res === 'boolean' || res instanceof UserModel)));
  }

  isAuth() {
    return !!this.user;
  }

}
