import {Base} from '../base.model';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export class SemesterModel extends Base {
  id: string;
  name: string;
  start_date: string;
  end_date: string;

  constructor(itemData) {
    super();
    super.loadFields(itemData);
  }

  getNgDateFormat(name): NgbDate {
    const date = new Date(this[name]);

    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  setDate(dateField, date: NgbDate) {
    this[dateField] = moment(new Date(`${date.year}/${date.month}/${date.day}`)).format('YYYY-MM-DD');
  }

}
