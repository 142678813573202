import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {NgxSmartModalService} from 'ngx-smart-modal';
import {ManagementService} from '../../../functional-modules/management/management.service';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {LibraryService} from '../../../functional-modules/library/library.service';
import {MyCabinetService} from '../../../functional-modules/my-cabinet/my-cabinet.service';

import {IScheduleData, IScheduleLesson} from '../journal-lesson/journal-lesson.component';
import {IKtpTheme} from '../../interfaces/journal/ktp-theme.interface';
import {IThemeMaterial} from '../../interfaces/journal/theme-material.interface';

import { mapTo, switchMap, take, takeWhile, toArray } from 'rxjs/operators';
import * as _ from 'lodash';
import {concat, of, zip, combineLatest} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../services/auth.service';
import {RootService} from '../../services/root.service';
import {ParentsService} from '../../../parents/parents.service';
import { UsersService } from '../../../functional-modules/users/users.service';
import * as moment from 'moment';

@Component({
  selector: 'app-lesson-content',
  templateUrl: './lesson-content.component.html',
  styleUrls: ['./lesson-content.component.scss',
    '../../../functional-modules/class/class.component.scss',
    '../../style/modules-shared.scss']
})
export class LessonContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  theme: IKtpTheme;
  themeMaterials: IThemeMaterial[];
  themeHomeWorks: IThemeMaterial[];
  themeControlWorks: IThemeMaterial[];
  loaded: boolean;
  wasChanged: boolean;
  form: FormGroup;
  startedForm: FormGroup;
  lessonPlaningId: number;
  lessonName: string;
  className: string;
  classId: string;
  selClass: any;
  showThemeForm: boolean;
  addThemeForm: FormGroup;
  wasSubmitted = false;

  updateModeMaterial = false;
  updateModeHomework = false;
  updateControlWorkMode = false;
  updatedField: FormControl;
  taskType: string;
  newTask: {
    index: number,
    type: string
  };
  newTaskData: any;
  removedFiles: any[] = [];

  reqSubs = [];

  player: YT.Player;
  linkId: string;
  work = true;
  class_list: string;
  class_group_list: string;
  isGroup: boolean;
  hideMaterials = false;
  classesCopiedMat: {
    id: number,
    name: string,
    logo: string
  }[] = [];
  canModifyCopiedMaterials = false;
  sharedMaterials: number[];
  prepareTill: string;
  mimeTypes = [
    'application/pdf',
    'application/msword',
    'application/rtf',
    'text/plain',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/zip'
  ];

  @Input() themeId: {
    id: number,
    name: string
  };
  @Input() from_lesson: boolean;

  @Input() lessonThemeId: number;
  @Input() schedule: IScheduleData;
  @Input() classInfo: IScheduleLesson;
  @Input() class_id: string;
  @Input() lesson_id: string;
  @Input() isAdmins = true;
  @Input() saveMaterialChanges = false;
  @Input() studentView = false;
  @Input() isControlWork = false;
  @Input() showClassMenu = false;
  @Input() onlyView = false;
  
  painterro;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public authService: AuthService,
              public rootService: RootService,
              public formBuilder: FormBuilder,
              public classesService: ClassesService,
              public parentsService: ParentsService,
              public libraryService: LibraryService,
              private router: Router,
              public route: ActivatedRoute,
              public myCabinetService: MyCabinetService,
              private sanitize: DomSanitizer,
              public changeDetectorRef: ChangeDetectorRef,
              public userService: UsersService) {
  }

  ngOnInit(): void {
    this.getLessonContentData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lessonThemeId) {
      this.classesService.changeTheme$.next(this.themeId);
    }
    if (changes.saveMaterialChanges && !changes.saveMaterialChanges.firstChange && changes.saveMaterialChanges.currentValue) {
      this.action('save');
    }

    if (changes.isControlWork && !changes.isControlWork.firstChange) {
        if (changes.isControlWork.currentValue) {
            this.initControlWorkForm();
        } else {
            const controlWorkTasks = [];
            this.themeControlWorks = [];
            this.controlWork.controls.forEach((task) => {
                if (task.value.id) {
                    controlWorkTasks.push(this.libraryService.deleteThemeTask(task.value.id));
                }
            });
            this.form.removeControl('controlWork');
            this.form.addControl('controlWork', this.formBuilder.array([]));
            this.updateControlWorkMode = false;

            if (controlWorkTasks.length) {
                concat(...controlWorkTasks).subscribe();
            }
        }
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  initPrepare(prepareTill) {
    this.prepareTill = prepareTill;
  }

  getLessonContentData() {
    if (this.from_lesson) {
      this.classesService.changeTheme$.pipe(
        takeWhile(() => this.work),
        switchMap((theme) => {
          this.loaded = false;
          this.updateModeMaterial = false;
          this.updateModeHomework = false;
          this.updateControlWorkMode = false;
          this.form = null;
          this.removedFiles = [];
          this.reqSubs = [];
          this.showThemeForm = !theme.content && !theme.name;
          this.classInfo.class_object.type === 'class_group' ? this.isGroup = true : this.isGroup = false;
          
          if (this.isGroup) {
            return this.libraryService.getThemeContentById(theme.id, null, this.classInfo.class_object.id, this.rootService.role.isStudent() || this.rootService.role.isParent());
          } else {
            return this.libraryService.getThemeContentById(theme.id, this.classInfo.class_object.id, null, this.rootService.role.isStudent() || this.rootService.role.isParent());
          }
        })
      ).subscribe(planningData => {
        this.theme = planningData;
        if (!this.theme.id) {
          // Error - theme was removed
          document.location.reload();
        }

        if (this.isGroup) {
          this.theme.materials = this.theme.materials
            .filter(material => material.class_group_list.find(class_group => class_group.id === +this.classInfo.class_object.id));
        } else {
          this.theme.materials = this.theme.materials
            .filter(material => material.class_list.find(asingned_class => asingned_class.id === +this.classInfo.class_object.id));
        }

        this.themeMaterials = this.theme.materials ? this.theme.materials.filter(material => material.type === 1) : [];
        this.themeHomeWorks = this.theme.materials ? this.theme.materials.filter(material => material.type === 2) : [];
        this.themeControlWorks = this.theme.materials ? this.theme.materials.filter(material => material.type === 3) : [];

        this.initForm();

        this.isControlWork = this.theme.is_control;

        if (this.isControlWork) {
            this.initControlWorkForm();
        }
      });
    } else {
      combineLatest([this.classesService.changeTheme$, this.route.params]).pipe(
        takeWhile(() => this.work),
        switchMap((theme) => {
          const theme_id = this.route.snapshot.params.lessonId;
          this.loaded = false;
          this.updateModeMaterial = false;
          this.updateModeHomework = false;
          this.form = null;
          this.removedFiles = [];
          this.reqSubs = [];
          this.classId = this.route.snapshot.params.classId ? this.route.snapshot.params.classId : this.class_id;
          this.class_group_list = this.route.snapshot.queryParams.class_group;
          this.class_group_list ? this.isGroup = true : this.isGroup = false;
          const classSubs = this.classesService.getClassById(this.classId);
          const lessonSubs = this.managementService.getLessons();

          let classParam = this.classId;
          let classGroupParam = null;
          if (this.isGroup) {
            classGroupParam = this.class_group_list;
            classParam = null;
          }

          return zip(this.libraryService.getThemeContentById(theme_id, classParam, classGroupParam, this.rootService.role.isStudent() || this.rootService.role.isParent()), classSubs, lessonSubs);
        })
      ).subscribe(([planningData, simple_class, lessons]) => {
          this.theme = planningData;
          this.selClass = simple_class;
          if (this.isGroup) {
            this.theme.materials = this.theme.materials
              .filter(material => material.class_group_list.find(class_group => class_group.id === +this.class_group_list));
          } else {
            this.theme.materials = this.theme.materials
              .filter(material => material.class_list.find(asingned_class => asingned_class.id === +this.classId));
          }

          // if (!this.isAdmins) {
          //   this.hideMaterials = true;
          // }
          const lessonId = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.route.snapshot.queryParams.lesson;
          this.lessonName = lessons.find(lesson => lesson.id === +lessonId).name;
          this.className = (/\d/.test(simple_class.grade.name) ? simple_class.grade.name + '-й ' : simple_class.grade.name + ' ') + simple_class.name;
          this.themeMaterials = this.theme.materials.filter(material => material.type === 1);
          this.themeHomeWorks = this.theme.materials.filter(material => material.type === 2);
          this.themeControlWorks = this.theme.materials.filter(material => material.type === 3);

          if ((this.rootService.role.isParent() || this.rootService.role.isStudent()) && this.route.snapshot.queryParams.notifId) {
            this.userService.updateNotificationStatus(this.route.snapshot.queryParams.notifId)
                .subscribe(() => {
                    this.authService.getNotifications();
                });
          }

          this.initForm();
          this.isControlWork = this.theme.is_control;

          if (this.isControlWork) {
              this.initControlWorkForm();
          }
          this.hasChanges();
        }
      );
    }
  }

  ngOnDestroy() {
    this.work = false;
  }

  getThemeFormFieldState(field) {
    return this.wasSubmitted ? this.addThemeForm.get(field).valid : null;
  }

  saveCurrentTheme() {
    this.wasSubmitted = true;
    if (this.addThemeForm.valid) {
      const data = {
        theme: this.addThemeForm.get('theme').value,
        content: this.addThemeForm.get('content').value,
      };

      this.classesService.updateCurrentLessonTheme(data, this.theme.id).subscribe(() => {
        if (this.theme.name !== data.theme || this.theme.content !== data.content) {
          // sorry, can't change theme data :)
          document.location.reload();
        }

        this.theme.name = data.theme;
        this.theme.content = data.content;
        this.showThemeForm = false;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  initForm() {
    this.wasSubmitted = false;
    this.addThemeForm = this.formBuilder.group({
      theme: [this.theme.name, [Validators.required]],
      content: [this.theme.content],
    });

    this.canModifyCopiedMaterials = false;
    this.sharedMaterials = this.theme.materials
      .filter(material => material.class_list.length > 1 || material.class_group_list.length > 1)
      .map(material => material.id);

    if (this.theme.materials.length) {
      const classes = this.theme.materials[0].class_list.filter(classItem => {
        if (!this.from_lesson) {
          return classItem.id !== +this.classId;
        } else {
          return classItem.id !== this.classInfo.class_object.id;
        }
      });
      const classGroups = this.theme.materials[0].class_group_list.filter(classGroup => {
        if (!this.from_lesson) {
          return classGroup.id !== +this.class_group_list;
        } else {
          return classGroup.id !== this.classInfo.class_object.id;
        }
      }).map(classGroup => classGroup.class);
      this.classesCopiedMat = [...classes, ..._.uniqBy(classGroups, 'id')];
    }

    this.form = this.formBuilder.group({
      materials: this.formBuilder.array([]),
      homeWorks: this.formBuilder.array([]),
      controlWork: this.formBuilder.array([])
    });
    if (this.themeMaterials.length) {
      this.themeMaterials.forEach(material => {
        this.materials.push(
          this.formBuilder.group({
            material: [material.description],
            material_html: [material.description_html],
            id: [material.id],
            files: this.getFiles(material),
            type: 1,
            class_group_list: material.class_group_list,
            class_list: material.class_list
          })
        );
      });
    } else {
      this.materials.push(
        this.formBuilder.group({
          material: [''],
          files: this.formBuilder.array([]),
          type: 1
        })
      );
    }

    if (this.themeHomeWorks.length) {
      this.themeHomeWorks.forEach(homework => {
        this.homeWorks.push(
          this.formBuilder.group({
            material: [homework.description],
            material_html: [homework.description_html],
            id: [homework.id],
            files: this.getFiles(homework),
            type: 2,
            class_group_list: homework.class_group_list,
            class_list: homework.class_list
          })
        );
      });
    } else {
      this.homeWorks.push(
        this.formBuilder.group({
          material: [''],
          material_html: [''],
          files: this.formBuilder.array([]),
          type: 2
        })
      );
    }

    this.startedForm = _.clone(this.form);
    this.loaded = true;
  }

  initControlWorkForm() {
      if (this.themeControlWorks.length) {
          this.themeControlWorks.forEach(controlWork => {
              this.controlWork.push(
                  this.formBuilder.group({
                      material: [controlWork.description],
                      material_html: [controlWork.description_html],
                      id: [controlWork.id],
                      files: this.getFiles(controlWork),
                      type: 2,
                      class_group_list: controlWork.class_group_list,
                      class_list: controlWork.class_list
                  })
              );
          });
      } else {
          this.controlWork.push(
              this.formBuilder.group({
                  material: [''],
                  material_html: [''],
                  files: this.formBuilder.array([]),
                  type: 3
              })
          );
      }
  }

  getFiles(material) {
    const materials = this.formBuilder.array([]);
    material.files.forEach(file => {
      materials.push(this.formBuilder.group({
        material: [file.external_url || file.file_url],
        id: file.id,
        type: file.type,
      }));
    });
    return materials;
  }

  canAddNewTask(materials) {
    if (materials.length) {
      return !!materials[materials.length - 1].material || !!materials[materials.length - 1].files.length;
    } else {
      return true;
    }
  }

  addTask(type) {
    if (type === '1') {
      this.materials.push(
        this.formBuilder.group({
          material: [''],
          files: this.formBuilder.array([]),
          type: 1
        })
      );
    } else if (type === '2') {
      this.homeWorks.push(
        this.formBuilder.group({
          material: [''],
          material_html: [''],
          files: this.formBuilder.array([]),
          type: 2
        })
      );
    } else if (type === '3') {
      this.controlWork.push(
          this.formBuilder.group({
            material: [''],
            material_html: [''],
            files: this.formBuilder.array([]),
            type: 2
          })
      );
    }
  }

  get materials() {
    return this.form.controls.materials as FormArray;
  }

  get homeWorks() {
    return this.form.controls.homeWorks as FormArray;
  }

  get controlWork() {
    return this.form.controls.controlWork as FormArray;
  }

  addMaterialToTask(data, task_type, material_type, task, index) {
    this.newTask = {
      index,
      type: task_type
    };
    if (task_type === '1' && material_type === '2' && this.updateModeMaterial) {
      (this.materials.controls[index].get('files') as FormArray).push(
        this.formBuilder.group({
          material: [''],
          type: +material_type,
          show: false
        })
      );
    } else if (task_type === '1' && material_type !== '2') {
      const fileData: any = _.first(data);
      (this.materials.controls[index].get('files') as FormArray).push(
        this.formBuilder.group({
          material: [this.sanitizeUrl(fileData.url)],
          type: +material_type,
          file_url: fileData.file,
          show: true
        })
      );
    } else if (task_type === '2' && material_type === '2' && this.updateModeHomework) {
      (this.homeWorks.controls[index].get('files') as FormArray).push(
        this.formBuilder.group({
          material: [''],
          material_html: [''],
          type: +material_type,
          show: false
        })
      );
    } else if (task_type === '2' && material_type !== '2') {
      const fileData: any = _.first(data);
      (this.homeWorks.controls[index].get('files') as FormArray).push(
        this.formBuilder.group({
          material: [this.sanitizeUrl(fileData.url)],
          material_html: [this.sanitizeUrl(fileData.url)],
          type: +material_type,
          file_url: fileData.file,
          show: true
        })
      );
    }  else if (task_type === '3' && material_type === '2' && this.updateControlWorkMode) {
        (this.controlWork.controls[index].get('files') as FormArray).push(
            this.formBuilder.group({
                material: [''],
                material_html: [''],
                type: +material_type,
                show: false
            })
        );
    } else if (task_type === '3' && material_type !== '2') {
        const fileData: any = _.first(data);
        (this.controlWork.controls[index].get('files') as FormArray).push(
            this.formBuilder.group({
                material: [this.sanitizeUrl(fileData.url)],
                material_html: [this.sanitizeUrl(fileData.url)],
                type: +material_type,
                file_url: fileData.file,
                show: true
            })
        );
    }
  }

  deleteMaterial(material, task, task_type, taskIndex, fileIndex, type) {
    if (type === 'file') {
      task.controls.files.removeAt(fileIndex);
      if (material.id) {
        this.removedFiles.push(this.libraryService.deleteFileFromLessonTheme(material.id));
      }
    } else if (type === 'task') {
      if (task_type === '1') {
        this.materials.removeAt(taskIndex);
      } else if (task_type === '2') {
        this.homeWorks.removeAt(taskIndex);
      } else if (task_type === '3') {
        this.controlWork.removeAt(taskIndex);
      }

      if (task.value.id) {
        this.removedFiles.push(this.libraryService.deleteThemeTask(task.value.id));
      }
    } else {
      this.removePartOfMaterials(material);
      if (!this.updateModeMaterial && !this.updateModeHomework) {
        this.loaded = false;
        concat(...this.removedFiles)
          .pipe(toArray())
          .subscribe(() => this.classesService.changeTheme$.next(this.theme));
      }
    }
  }

  removePartOfMaterials(material) {
    if (material.lessonMaterials && material.homeWorkMaterials && material.controlWorkMaterials) {
      while (this.materials.length) {
        this.materials.removeAt(0);
      }
      while (this.homeWorks.length) {
        this.homeWorks.removeAt(0);
      }
      const reqData = {
        lesson_planning_theme: this.theme.id
      };
      this.removedFiles = [];
      this.removedFiles.push(this.libraryService.deleteAllFilesFromLessonTheme(reqData));
    } else {
        if (material.lessonMaterials) {
            while (this.materials.length) {
                this.materials.removeAt(0);
            }
            this.themeMaterials.filter(theme_material => theme_material.id)
                .forEach(theme_material => this.removedFiles.push(this.libraryService.deleteThemeTask(theme_material.id)));
        }

        if (material.homeWorkMaterials) {
            while (this.homeWorks.length) {
                this.homeWorks.removeAt(0);
            }
            this.themeHomeWorks.filter(theme_material => theme_material.id)
                .forEach(theme_material => this.removedFiles.push(this.libraryService.deleteThemeTask(theme_material.id)));
        }

        if (material.controlWorkMaterials) {
            while (this.controlWork.length) {
                this.controlWork.removeAt(0);
            }
            this.themeControlWorks.filter(theme_material => theme_material.id)
                .forEach(theme_material => this.removedFiles.push(this.libraryService.deleteThemeTask(theme_material.id)));
        }
    }
  }

  sanitizeUrl(url: string) {
    return this.sanitize.bypassSecurityTrustResourceUrl(url);
  }

  deleteLessonContent() {
    this.ngxSmartModalService.resetModalData('lesson-materials-remove');
    this.ngxSmartModalService.getModal('lesson-materials-remove').open();
    this.ngxSmartModalService.getModal('lesson-materials-remove')
      .onClose
      .pipe(take(1))
      .subscribe(() => {
        if (this.ngxSmartModalService.getModal('lesson-materials-remove').getData()) {
          this.deleteMaterial(this.ngxSmartModalService.getModal('lesson-materials-remove').getData(), null, null, null, null, 'selection');
        }
      });
  }

  editLessonMaterialContent(modeType) {
    if (modeType === 'material') {
      this.updateModeMaterial = true;
    } else if (modeType === 'homework') {
      this.updateModeHomework = true;
    } else if (modeType === 'controlWork') {
      this.updateControlWorkMode = true;
    }
  }

  copyLessonContent(theme) {
    this.ngxSmartModalService.resetModalData('copy-lesson-materials');
    this.ngxSmartModalService.getModal('copy-lesson-materials').setData({
      theme,
      grade: this.from_lesson ? this.classInfo.class_object.grade.name : this.selClass.grade.name,
      onSave: (assign_list) => {

        let class_list = '';
        let class_group_list = '';
        let material_list = '';

        assign_list.forEach(classItem => {
          if (classItem.class_groups) {
            classItem.class_groups.forEach(class_group => {
              if (class_group.selected) {
                class_group_list += class_group.id + ',';
              }
            });
          } else {
            if (classItem.selected) {
              class_list += classItem.id + ',';
            }
          }
        });

        this.theme.materials.forEach(material => {
          material_list += material.id + ',';
        });

        const data = {
          class_list: class_list.split(',').filter(item => item).join(','),
          class_group_list: class_group_list.split(',').filter(item => item).join(','),
          material_list: material_list.split(',').filter(item => item).join(','),
        };

        this.libraryService.copyLessonMaterials(data).subscribe(() => {
          this.classesService.changeTheme$.next(this.theme);
        });
      }
    });
    this.ngxSmartModalService.getModal('copy-lesson-materials').open();
  }

  clearVideoField(input_action, task_material, task, index) {
    if (input_action === 'clear') {
      task_material.controls.material.setValue('');
    } else {
      task.controls.files.removeAt(index);
    }
  }

  /* load video materials */

  checkVideo(youtube_link, task) {
    if (!youtube_link) {
      task.controls.show.setValue(false);
    } else {
      if (youtube_link.includes('https://youtu.be')) {
        this.linkId = _.last(youtube_link.split('?')[0].split('/'));
      } else {
        this.linkId = youtube_link.split('&')[0].split('=')[1];
      }
      task.controls.show.setValue(true);
      this.validVideoId(this.linkId, task);
    }
  }

  getFilesArray(files, type) {
    return files.filter(file => file.value.type === type);
  }

  /* validate youtube video id */

  validVideoId(id, task) {
    const img = new Image();
    img.src = 'http://img.youtube.com/vi/' + id + '/mqdefault.jpg';
    img.onload = () => this.checkThumbnail(img.width, task);
  }

  checkThumbnail(width, task) {
    if (width === 120) {
      task.controls.show.setValue(false);
    } else {
      task.controls.show.setValue(true);
    }
  }

  savePlayer(player) {
    this.player = player;
  }

  editCP(theme) {
    this.ngxSmartModalService.resetModalData('calendar-planning-popup');
    this.ngxSmartModalService.getModal('calendar-planning-popup').setData({
      is_public: true,
      title: 'Редагування календарно-тематичне планування',
      type: 'edit',
      teacherUuid: this.rootService.role.isTeacher() ? this.authService.user.uuid : null,
      classId: this.classId,
      id: this.theme.lesson_planning_id,
      step: 2,
      selected_theme: theme.id,
      onSave: () => {
        this.classesService.changeTheme$.next(this.theme);
      }
    });
    this.ngxSmartModalService.getModal('calendar-planning-popup').open();
  }

  focus(ev, task_description, task_type, index) {
    this.newTask = {
      index,
      type: task_type
    };
    this.updatedField = task_description;
    this.taskType = task_type;
  }

  hasChanges() {
    return !_.isEqual(
      this.form.value,
      this.startedForm.value
    );
  }


  action(type) {
    if (type === 'save') {

      if (this.theme.materials.length) {

        /* there were some materials */
        const tasksArray = [...this.form.value.materials, ...this.form.value.homeWorks, ...this.form.value.controlWork];
        const oldTasks = tasksArray.filter(task => task.id);
        const newTasks = tasksArray.filter(task => !task.id && (task.material || task.files.length));
        const oldTasksCompare = oldTasks.filter(oldTask => this.sharedMaterials.includes(oldTask.id));

        const tasksArrayStarted = [...this.startedForm.value.materials, ...this.startedForm.value.homeWorks];
        const oldTasksStartedCompare = tasksArrayStarted.filter(task => task.id).filter(task => this.sharedMaterials.includes(task.id));

        const hasCopiedModified = !_.isEqual(
          oldTasksCompare,
          oldTasksStartedCompare
        );

        if (hasCopiedModified && !this.canModifyCopiedMaterials) {
          this.ngxSmartModalService.resetModalData('modify-lesson-materials');
          this.ngxSmartModalService.getModal('modify-lesson-materials').setData({
            type: 'can-modified-copied',
            msg: `Ви внесли зміни в матеріали уроку.
              Після збереження зміни відбудуться у всіх класах, які мають копію даних матеріалів уроку.`,
            onSave: (resp) => {
              if (resp) {
                this.canModifyCopiedMaterials = true;
                this.ngxSmartModalService.getModal('modify-lesson-materials').close();
                this.action('save');
              } else {
                this.ngxSmartModalService.getModal('modify-lesson-materials').close();
                this.action('cancel');
              }
            }
          });
          this.ngxSmartModalService.getModal('modify-lesson-materials').open();
          return;
        }

        oldTasks.forEach(old_task => {
          const changedTask = this.theme.materials.find(material => material.id === old_task.id);

          if (changedTask && changedTask.description !== old_task.material) {
            if (!old_task.material && !old_task.files.length) {
              this.reqSubs.push(this.libraryService.deleteThemeTask(old_task.id));
            } else {
              const changeDescrData = {
                description: old_task.material
              };
              this.reqSubs.push(this.libraryService.editThemeTaskDescription(changeDescrData, old_task.id));
            }
          }
          old_task.files.filter(file => {
            return !file.id;
          }).forEach(file => {
            if (file.show) {
              const file_data = {
                lesson_planning_material: old_task.id,
                file_url: file.type !== 2 ? file.file_url : null,
                external_url: file.type === 2 ? file.material : null,
                type: file.type
              };
              this.reqSubs.push(this.libraryService.addNewFileToLessonTheme(file_data));
            }
          });
        });


        newTasks.forEach(task => {
            const reqData = {
              lesson_planning_theme: this.theme.id,
              description: task.material,
              type: task.type,
              class_id: !this.isGroup ? (this.from_lesson ? this.classInfo.class_object.id : this.classId) : null,
              class_group: this.isGroup ? (this.from_lesson ? this.classInfo.class_object.id : this.class_group_list) : null,
            };
            if (task.material || (!task.material && task.files.length)) {
              this.reqSubs.push(this.libraryService.addNewTaskToLessonTheme(reqData)
                .pipe(switchMap((response: { status: 'string', id: number; }) => {
                  if (task.files.length) {
                    let observables = [];
                    observables = task.files.map(file => {
                      if ((file.file_url || file.material) && file.show) {
                        const file_data = {
                          lesson_planning_material: response.id,
                          file_url: file.type !== 2 ? file.file_url : null,
                          external_url: file.type === 2 ? file.material : null,
                          type: file.type
                        };
                        return this.libraryService.addNewFileToLessonTheme(file_data);
                      }
                    });
                    return concat(...observables).pipe(toArray());
                  } else {
                    return of(null);
                  }
                })));
            }
          }
        );


        const allReq = [...this.removedFiles, ...this.reqSubs];
        if (allReq.length) {
          this.loaded = false;
          return concat(...allReq).pipe(toArray()).subscribe(() => {
            this.canModifyCopiedMaterials = false;
            this.classesService.changeTheme$.next(this.theme);
          });
        } else {
          return of(null);
        }

      } else {
        /* add new materials */
        /* there were no materials */
        const tasksArray = [...this.form.value.materials, ...this.form.value.homeWorks, ...this.form.value.controlWork];
        if (tasksArray.length) {
          this.loaded = false;
          tasksArray.forEach(task => {
              const reqData = {
                lesson_planning_theme: this.theme.id,
                description: task.material,
                type: task.type,
                class_id: !this.isGroup ? (this.from_lesson ? this.classInfo.class_object.id : this.classId) : null,
                class_group: this.isGroup ? (this.from_lesson ? this.classInfo.class_object.id : this.class_group_list) : null,
              };
              if (task.material || (!task.material && task.files.length)) {
                this.reqSubs.push(this.libraryService.addNewTaskToLessonTheme(reqData)
                  .pipe(switchMap((response: { status: 'string', id: number; }) => {
                    if (task.files.length) {
                      let observables = [];
                      observables = task.files.map(file => {
                        if ((file.file_url || file.material) && file.show) {
                          const file_data = {
                            lesson_planning_material: response.id,
                            file_url: file.type !== 2 ? file.file_url : null,
                            external_url: file.type === 2 ? file.material : null,
                            type: file.type
                          };
                          return this.libraryService.addNewFileToLessonTheme(file_data);
                        }
                      });
                      return concat(...observables).pipe(toArray());
                    } else {
                      return of(null);
                    }
                  })));
              }
            }
          );
          concat(...this.reqSubs)
            .pipe(toArray())
            .subscribe(() => this.classesService.changeTheme$.next(this.theme));
        } else {
          /* if nothing added do not create new task */
          this.initForm();
          this.updateModeMaterial = false;
          this.updateModeHomework = false;
          this.reqSubs = [];
          this.removedFiles = [];
        }
      }

    } else {
      /* cancel */
      this.initForm();
      this.updateModeMaterial = false;
      this.updateModeHomework = false;
      this.updateControlWorkMode = false;
      this.reqSubs = [];
      this.removedFiles = [];
    }
  }

  deleteAllMaterials() {
    const reqData = {
      lesson_planning_theme: this.theme.id
    };
    this.libraryService.deleteAllFilesFromLessonTheme(reqData).subscribe(response => {
      this.classesService.updateJournalLessonData$.next(response);
      this.classesService.changeTheme$.next(this.themeId);
    });
  }

  anotherTheme(theme_id) {
    const params = this.route.snapshot.params;
    let route;

    if (this.rootService.role.isStudent() || this.rootService.role.isParent()) {
        route = this.parentsService.getSectionPath(`tasks/`);
    } else {
        route = this.classesService.getSectionPath(`${params.classId}/journal/${params.id}/planning/`);
    }

    this.router.navigate([route, theme_id], {queryParams: this.route.snapshot.queryParams});
  }

  toggleSectionMaterials() {
    this.hideMaterials = !this.hideMaterials;
  }

  isImage(url) {
    if (url) {
      const ext = url.split(/[#?]/)[0].split('.').pop().trim();
      return ['jpg', 'jpeg', 'gif', 'png'].indexOf(ext.toLowerCase()) !== -1;
    }
  }

  openMaterialsPopup(task, type) {
    let files;
    if (type === 'homework') {
      files = this.themeHomeWorks.map(tasks => tasks.files)
        .flat(1)
        .filter(fileInfo => this.isImage(fileInfo.file_url))
        .map(fileInfo => fileInfo.file_url);
    } else if (type === 'control-work') {
        files = this.themeControlWorks.map(tasks => tasks.files)
            .flat(1)
            .filter(fileInfo => this.isImage(fileInfo.file_url))
            .map(fileInfo => fileInfo.file_url);
    }  else {
      files = this.themeMaterials.map(tasks => tasks.files)
        .flat(1)
        .filter(fileInfo => this.isImage(fileInfo.file_url))
        .map(fileInfo => fileInfo.file_url);
    }

    this.ngxSmartModalService.resetModalData('homeworks-popup');
    this.ngxSmartModalService.getModal('homeworks-popup').setData(
      {
        role: 'teacher',
        photos: files,
        selected_photo: task.value.material,
        showHomeworkButtons: type === 'homework' || type === 'control-work',
        isControl: type === 'control-work'
      }
    );
    this.ngxSmartModalService.getModal('homeworks-popup').open();
  }

}
