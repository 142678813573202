import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {takeWhile} from "rxjs/operators";
import {Validators} from "@angular/forms";

@Component({
  selector: 'app-modify-lesson-materials-popup',
  templateUrl: './modify-lesson-materials-popup.component.html',
  styleUrls: ['./modify-lesson-materials-popup.component.scss']
})
export class ModifyLessonMaterialsPopupComponent implements AfterViewInit, OnDestroy {

  modalData: any;
  work = true;
  loaded = false;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public classesService: ClassesService) {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('modify-lesson-materials').onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.modalData = this.ngxSmartModalService.getModal('modify-lesson-materials').getData();
        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this.work = false;
  }

  saveMaterials(response) {
    if (this.modalData.type === 'save-modified') {
      this.classesService.saveMaterialsPopupAnswer$.next(response);
    } else {
      this.modalData.onSave(response);
    }
  }

}
