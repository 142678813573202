import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription, zip } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClassEducationPlanModel } from '../../models/school-management/class-education-plan.model';
import { ManagementService } from '../../../functional-modules/management/management.service';
import { ClassesService } from '../../../functional-modules/classes/classes.service';

@Component({
  selector: 'schedule-board',
  templateUrl: './schedule-board.component.html',
  styleUrls: ['./schedule-board.component.scss', '../../style/modules-shared.scss']
})
export class ScheduleBoardComponent implements OnInit, OnDestroy, OnChanges {

  @Input() classId: number;
  @Input() fromClass: boolean;
  @Input() weeksCountEdit = false;
  @Input() isPublished = true;

  classInfo = null;
  requestSubscription: Subscription;
  classTeachers: any[] = [];
  classRooms: any[] = [];
  classGroups: any[] = [];
  classLessons: any[] = [];
  gradeLessons: ClassEducationPlanModel = null;
  gradeEvents: any = [];
  scheduleInfo = null;
  tabs = [];
  classes = [];
  loaded = false;
  schedule: any = {
    '1': {},
    '2': {}
  };
  currentWeek: 1 | 2 = 1;
  weekDays = [
    'Понеділок',
    'Вівторок',
    'Ceреда',
    'Четвер',
    "П'ятниця",
    'Cубота'
  ];
  viewType: 'grid' | 'column' = 'grid';

  @Output() onNoContent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onElemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInit: EventEmitter<any> = new EventEmitter<any>();
  @Output() editWeeksCount: EventEmitter<any> = new EventEmitter<any>();

  constructor(public route: ActivatedRoute,
    public router: Router,
    public managementService: ManagementService,
    public ngxSmartModalService: NgxSmartModalService,
    public classesService: ClassesService) {
  }

  ngOnInit(): void {
    this.loadSchedule();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.classId.firstChange) {
      this.loadSchedule();
    }
  }

  ngOnDestroy(): void {
    this.dropRequest();
  }

  onEditWeeksCount() {
    this.currentWeek = 1;
    this.editWeeksCount.emit();
  }

  switchView(type) {
    this.viewType = type;
  }

  dropRequest() {
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
      this.requestSubscription = null;
    }
  }

  loadSchedule() {
    this.dropRequest();
    this.loaded = false;
    this.requestSubscription = this.requestSubscription = zip(
      this.managementService.getSchedulePlaning(this.classId, true),
      this.getExtraInfo()
    ).subscribe(([plaining]) => {
      this.scheduleInfo = plaining;
      this.formSchedule();

      if (!this.classInfo.is_published) {
        this.onNoContent.emit();
      } else {
        setTimeout(() => {
          this.onInit.emit();
        }, 450);
      }

      this.loaded = true;
    });
  }

  getExtraInfo() {
    if (this.fromClass) {
      return this.getClassInfo().pipe(
        concatMap(() => {
          return zip(
            this.getTeachers(),
            this.getGetClassRooms(),
            this.getClassGroups(),
            this.getGradeLessons(),
            this.getGradeEvents()
          );
        })
      ).pipe(
        concatMap(() => this.getClassLessons()),
      );
    } else {
      return this.getClassesScheduleProgress().pipe(
        concatMap(() => {
          return zip(
            this.getTeachers(),
            this.getGetClassRooms(),
            this.getClassGroups(),
            this.getGradeLessons(),
            this.getGradeEvents()
          );
        })
      ).pipe(
        concatMap(() => this.getClassLessons()),
      );
    }
  }

  getClassesScheduleProgress() {
    return this.managementService.getClassesScheduleProgress().pipe(tap((classes) => {
      this.classes = classes;
      this.classInfo = _.find(this.classes, (classItem) => classItem.class.id === (+this.classId));
    }));
  }

  getClassInfo() {
    return this.classesService.getClassById(+this.classId).pipe(tap((class_info) => {
      this.classInfo = class_info;
    }));
  }

  getTeachers() {
    return this.managementService.getAllTeachers().pipe(
      tap((rez) => {
        this.classTeachers = rez.map((i) => {
          return {
            id: i.uuid,
            text: `${i.last_name} ${i.first_name}`,
            data: i
          };
        });
      })
    );
  }

  getGetClassRooms() {
    return this.managementService.getClassRoms().pipe(
      tap((rez) => {
        this.classRooms = rez.map((i) => {
          return {
            id: String(i.id),
            text: `${i.name}`,
            data: i
          };
        });
      })
    );
  }

  getClassGroups() {
    return this.managementService.getClassGroups(this.classId).pipe(
      tap((rez) => {
        this.classGroups = rez.map((i) => {
          return {
            id: String(i.id),
            text: `${i.name}`,
            data: i
          };
        });
      })
    );
  }

  getClassLessons() {
    return this.managementService.getGradeLessons(this.fromClass ? this.classInfo.grade.id : this.classInfo.class.grade.id, true)
      .pipe(concatMap((gradeLessons: any[]) => {
        return this.managementService.getClassLessons(this.fromClass ? this.classInfo.id : this.classInfo.class.id).pipe(
          tap((rez: any) => {
            this.classLessons = [];

            _.first(gradeLessons).lessons.forEach((l) => {
              const savedItems = rez.filter((savedItem) => savedItem.lesson.id === l.lesson_id);
              let lesson = null;
              let teacher = null;
              let group = null;
              let classroom = null;

              if (savedItems.length) {
                savedItems.forEach(savedItem => {
                  const info = this.getLessonExtraData({
                    lessonId: savedItem.lesson.id,
                    teacherUuid: savedItem.teacher_user.uuid,
                    groupId: savedItem.class_group ? savedItem.class_group.id : null,
                    classroomId: savedItem.classroom
                  });

                  lesson = info.lesson;
                  teacher = info.teacher;
                  group = info.group;
                  classroom = info.classroom;

                  this.classLessons.push({
                    id: l.id ? String(l.id) : '',
                    lesson_id: lesson.lesson_id,
                    lesson,
                    teacher,
                    group,
                    classroom,
                    teacherName: `${teacher.last_name} ${teacher.first_name.slice(0, 1).toUpperCase()}.`,
                    week_load: 0,
                    count_minutes: 10,
                    type: 'lesson',
                    colorIndex: this.classLessons.length + 1
                  });
                });
              }
            });
          })
        );
      }));
  }

  getGradeLessons() {
    return this.managementService.getGradeLessons(this.fromClass ? this.classInfo.grade.id : this.classInfo.class.grade.id)
      .pipe(
        tap((rez) => {
          this.gradeLessons = _.first(rez);
        })
      );
  }

  getLessonExtraData({ lessonId, teacherUuid, groupId, classroomId }) {
    const lesson = _.find(this.gradeLessons.lessons, ['lesson_id', lessonId]);
    const teacher = teacherUuid ? _.find(this.classTeachers, ['id', teacherUuid]).data : null;
    const group = groupId ? _.find(this.classGroups, ['id', String(groupId)]).data : null;
    const classroom = classroomId ? _.find(this.classRooms, ['id', String(classroomId)]).data : null;

    return {
      lesson,
      teacher,
      group,
      classroom
    };
  }

  getGradeEvents() {
    return this.managementService.getGradeEvents(this.fromClass ? this.classInfo.grade.id : this.classInfo.class.grade.id, true)
      .pipe(
        tap((rez) => {
          this.gradeEvents = _.chain(rez)
            .map(({ id, name, grade, count_minutes, is_kitchen }) => {

              return {
                id,
                is_kitchen,
                grade,
                count_minutes,
                name,
                type: 'event',
              };
            })
            .orderBy(['id'], ['asc'])
            .value();
        })
      );
  }

  formSchedule() {
    _.keys(this.schedule).forEach((week_num) => {
      this.scheduleInfo.days.forEach((d) => {
        this.schedule[String(week_num)][String(d)] = {};
      });
    });

    this.scheduleInfo.schedule.forEach((lessonItem) => {
      const [startCallHour, startCallMinutes] = this.scheduleInfo.calls[lessonItem.order_num - 1].start_time.split(':');
      const callStartTime = this.getMomentModel(startCallHour, startCallMinutes);
      const {
        lesson,
        teacher,
        group,
        classroom
      } = this.getLessonExtraData({
        lessonId: lessonItem.lesson ? lessonItem.lesson.id : null,
        teacherUuid: lessonItem.teacher_user ? lessonItem.teacher_user.uuid : null,
        groupId: lessonItem.class_group,
        classroomId: lessonItem.classroom ? lessonItem.classroom.id : null
      });
      let colorIndex = null;

      if (lessonItem.event) {
        colorIndex = this.getColorIndex(lessonItem.event.id, 'event');
      } else {
        colorIndex = this.getColorIndex(lessonItem.lesson.id, 'lesson', group);
      }

      if (this.schedule[String(lessonItem.week_num)][String(lessonItem.week_day)][String(lessonItem.order_num)]) {
        this.schedule[String(lessonItem.week_num)][String(lessonItem.week_day)][String(lessonItem.order_num)]
          .parallelGroups
          .push(
            this.getScheduleItem(!lessonItem.event ? 'lesson' : 'event', {
              lessonItem,
              callStartTime,
              colorIndex,
              lesson,
              teacher,
              group,
              classroom,
              parallelGroups: []
            })
          );

        if (!lessonItem.event) {
          this.getLesson(lessonItem.lesson.id, group).week_load++;
        }
      } else {
        this.schedule[String(lessonItem.week_num)][String(lessonItem.week_day)][String(lessonItem.order_num)] = this.getScheduleItem(!lessonItem.event ? 'lesson' : 'event', {
          lessonItem,
          callStartTime,
          colorIndex,
          lesson,
          teacher,
          group,
          classroom,
          parallelGroups: []
        });
        if (!lessonItem.event) {
          console.log(lessonItem.lesson.id, group);
          if (this.getLesson(lessonItem.lesson.id, group)) {
            this.getLesson(lessonItem.lesson.id, group).week_load++;
          }
        }
      }
    });
  }

  getScheduleItem(type, {
    lessonItem,
    callStartTime,
    colorIndex,
    classroom,
    lesson,
    teacher,
    group,
    parallelGroups
  }: any) {

    if (type === 'lesson') {
      return {
        isDropped: true,
        lesson,
        teacher,
        group,
        classroom,
        teacherName: `${lessonItem.teacher_user.last_name} ${lessonItem.teacher_user.first_name.slice(0, 1).toUpperCase()}.`,
        type: 'lesson',
        id: lessonItem.id,
        week_num: lessonItem.week_num,
        day_num: lessonItem.week_day,
        lesson_num: lessonItem.order_num,
        start_time: callStartTime.add(lessonItem.start_diff_minutes, 'minutes').format('HH:mm'),
        start_diff_minutes: lessonItem.start_diff_minutes,
        end_time: callStartTime.add(lessonItem.count_minutes, 'minutes').format('HH:mm'),
        calls: {
          start_time: this.scheduleInfo.calls[lessonItem.order_num - 1].start_time,
          end_time: this.scheduleInfo.calls[lessonItem.order_num - 1].end_time
        },
        count_minutes: lessonItem.count_minutes,
        break_minutes: lessonItem.break_minutes,
        colorIndex,
        parallelGroups
      };
    } else if (type === 'event') {
      return {
        isDropped: true,
        classroom,
        type,
        id: lessonItem.id,
        event: lessonItem.event,
        name: lessonItem.event.name,
        week_num: lessonItem.week_num,
        day_num: lessonItem.week_day,
        lesson_num: lessonItem.order_num,
        start_time: callStartTime.add(lessonItem.start_diff_minutes, 'minutes').format('HH:mm'),
        start_diff_minutes: lessonItem.start_diff_minutes,
        end_time: callStartTime.add(lessonItem.count_minutes, 'minutes').format('HH:mm'),
        calls: {
          start_time: this.scheduleInfo.calls[lessonItem.order_num - 1].start_time,
          end_time: this.scheduleInfo.calls[lessonItem.order_num - 1].end_time
        },
        count_minutes: lessonItem.count_minutes,
        break_minutes: lessonItem.break_minutes,
        colorIndex,
        parallelGroups
      };
    }
  }

  getElemHeight(countMinutes) {
    return countMinutes / 5;
  }

  getScheduleElem(day_num, lesson_num, parallelGroupIndex?) {
    const elem = this.schedule[String(this.currentWeek)][String(day_num)][String(lesson_num)];

    if (elem && _.isNumber(parallelGroupIndex)) {
      return elem.parallelGroups[parallelGroupIndex];
    }
    return elem;
  }

  switchWeek(direction: 'prev' | 'next') {
    this.currentWeek = (direction === 'prev' ? (this.currentWeek - 1) : (this.currentWeek + 1)) as 1 | 2;
  }

  onElemClickHandler(elem) {
    this.onElemClick.emit(elem);
  }

  hasDayAnyLessons(day_num) {
    return _.keys(this.schedule[String(this.currentWeek)][String(day_num)]).length;
  }

  getColorIndex(itemId, type, group?) {
    if (type === 'lesson') {
      if (group) {
        return _.findIndex(this.classLessons, ({ lesson_id, group: groupData }) => {
          return lesson_id === itemId && groupData && groupData.id === group.id;
        }) + 1;
      } else {
        return _.findIndex(this.classLessons, ['lesson_id', itemId]) + 1;
      }
    } else if (type === 'event') {
      return this.classLessons.length + _.findIndex(this.gradeEvents, ['id', itemId]) + 1;
    }
  }

  getLesson(itemId, group) {
    if (group) {
      return _.find(this.classLessons, ({ lesson_id, group: groupData }) => {
        return lesson_id === itemId && groupData && group.id === groupData.id;
      });
    } else {
      return _.find(this.classLessons, ['lesson_id', itemId]);
    }
  }

  hasTimeSkew(day, lesson_num, calls) {
    if (this.getScheduleElem(day, lesson_num)) {
      const elem = this.getScheduleElem(day, lesson_num);
      const parallelGroup = this.getScheduleElem(day, lesson_num).paralelGroup;

      return elem.start_time !== calls.start_time || parallelGroup && parallelGroup.start_time !== calls.start_time;
    }

    return false;
  }

  print() {
    window.print();
  }

  getMomentModel(h, m) {
    const currDate = new Date();

    return moment([currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), h, m]);
  }

  isShownCabinet(elem) {
    return !elem.parallelGroups.length && elem.count_minutes >= 35;
  }

}
