import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import * as _ from 'lodash';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {HelperService} from '../../../services/helper.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {RootService} from '../../../services/root.service';

@Component({
  selector: 'school-rules',
  templateUrl: './school-rules.component.html',
  styleUrls: ['./school-rules.component.scss']
})
export class SchoolRulesComponent implements OnInit {

  rules: SafeHtml = '';
  userRole: string;
  schoolRules: any;
  loaded = false;

  constructor(public managementService: ManagementService,
              public ngxSmartModalService: NgxSmartModalService,
              public sanitizer: DomSanitizer,
              public rootService: RootService,
              public detectChanges: ChangeDetectorRef) {
    this.userRole = this.rootService.role.getRoleType();
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.loaded = false;
    HelperService.upScroll();
    this.managementService.getSchoolRules().subscribe((rez) => {
      this.schoolRules = rez;
      this.rules = this.sanitizer.bypassSecurityTrustHtml(rez.rules ? _.cloneDeep(rez.rules) : '&ndash;');
      this.loaded = true;
    });
  }

  edit() {
    this.ngxSmartModalService.resetModalData('school-rules-popup');
    this.ngxSmartModalService.getModal('school-rules-popup').setData({
      onSave: () => {
        this.load();
      }
    });
    this.ngxSmartModalService.getModal('school-rules-popup').open();
  }

}
