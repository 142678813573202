import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss', '../../../style/popups-shared.scss']
})
export class NotificationAlertComponent {

  @Input() identifier = 'notification-alert';
  @Input() data: {
    title: string,
    text: string,
    isWarning?: boolean,
    buttons: {
      label: string,
      type: 'action' | 'cancel',
      cb?: Function,
    }[];
  };


  @Output() changeState: EventEmitter<any> = new EventEmitter<any>();

  constructor(public ngxSmartModalService: NgxSmartModalService) {
  }

  onBtnClick(btn) {
    if (btn.type === 'action') {
      btn.cb();
      this.ngxSmartModalService.getModal(this.identifier).close();
    } else {
      if (btn.cb) {
        btn.cb();
      }
      this.ngxSmartModalService.getModal(this.identifier).close();
    }
  }

}
