import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeWhile} from 'rxjs/operators';

import {NgxSmartModalService} from 'ngx-smart-modal';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {ClassModel} from '../../models/school-management/class.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'delete-class-modal',
  templateUrl: './delete-class-modal.component.html',
  styleUrls: ['./delete-class-modal.component.scss', '../../style/popups-shared.scss']
})
export class DeleteClassModalComponent implements AfterViewInit, OnDestroy {
  @Input() entityIdToRemove: string;
  @Input() msg: string;
  @Input() title: string;
  @Input() type: string;

  @Output() madeDecision: EventEmitter<{ type: string, id: string }> = new EventEmitter<any>();

  openedModal = true;
  classToRemove: ClassModel;
  reqSubs: Subscription;

  loaded = false;
  closing = false;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public classesService: ClassesService) {
  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('delete-modal')
      .onOpenFinished
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.loaded = false;

        switch (this.type) {
          case 'delete_class':
            this.reqSubs = this.classesService.getClassById(this.entityIdToRemove).subscribe((classData: ClassModel) => {
              this.loaded = true;
              this.classToRemove = classData;
            });
            break;
          default:
            this.loaded = true;
        }
      });
  }

  ngOnDestroy() {
    this.openedModal = false;
  }

  action(type: string) {
    if (type === 'cancel') {
      this.madeDecision.emit({type, id: this.entityIdToRemove});
    } else {
      this.madeDecision.emit({type, id: this.entityIdToRemove});
    }
  }
}
