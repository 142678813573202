import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss']
})
export class BtnComponent implements OnDestroy, AfterViewInit {

  @Input() theme: string;
  @Input() text: string;
  @Input() disabled = false;
  @Input() with_icon: boolean;
  @Input() icon: string;
  @Input() onEnterAction = false;

  @ViewChild('btn') btn: ElementRef;

  work = true;

  constructor() {
  }

  ngAfterViewInit(): void {
    if (this.onEnterAction) {
      fromEvent(document, 'keyup')
        .pipe(takeWhile(() => this.work))
        .subscribe((e: any) => {
          if (e.keyCode === 13) {
            (this.btn.nativeElement as HTMLButtonElement).click();
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.work = false;
  }

  isType(range) {
    return this.theme.split(' ').some(el => range.includes(el));
  }

  @HostBinding('class.disabled') get invalid() {
    return this.disabled;
  }
}
