import {Base} from '../base.model';

export class SchoolRoomModel extends Base {

  id: string;
  name: string;
  classes: any;
  teacher_user: any;
  lesson: any;
  building: string;
  floor: string;
  max_child: string;
  type: string;
  photo: string | File;
  is_active: boolean;

  constructor(items) {
    super();
    super.loadFields(items);
  }


}
