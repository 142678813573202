import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ManagementService} from '../../functional-modules/management/management.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {RootService} from '../services/root.service';

@Injectable()
export class GeneralInfoGuardGuard implements CanActivate {

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public rootService: RootService,
              public managementService: ManagementService,
              public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.managementService.checkSchoolSettings().pipe(map((hasInfo) => {
      if (!hasInfo) {
        this.ngxSmartModalService.resetModalData('system-info-popup');
        this.ngxSmartModalService.getModal('system-info-popup').setData({
          closeable: false,
          onSave: (step) => {
            if (step === 4) {
              this.managementService.setSchoolSettings().subscribe(() => {
                this.router.navigateByUrl(this.rootService.role.getSectionPath());
              });
            }
          },
          step: 1
        });
        this.ngxSmartModalService.getModal('system-info-popup').open();
        return false;
      }

      return true;
    })) as Observable<boolean>;
  }

}
