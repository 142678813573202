import { Component, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ToggleComponent),
  multi: true
};

@Component({
  selector: 'toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ToggleComponent {

  @Input() value: boolean;
  @Input() disabled: boolean;

  @Output() changeState: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  @HostListener('click')
  click() {
    this.onTouched();
  }

  onChange = (item) => {

  };

  onTouched = () => {

  };

  writeValue(v: boolean): void {
    this.value = v;
    this.onChange(this.value);
    this.changeState.emit(this.value);
  }

  registerOnChange(fn: (item) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
