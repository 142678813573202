import {Base} from '../base.model';

export class ServiceErrorModel extends Base {

  balance: number;
  contract_id: number;
  text: string;

  constructor(items) {
    super();
    super.loadFields(items);
  }

}
