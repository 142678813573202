import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {of, Subscription, zip} from 'rxjs';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {SchoolRoomModel} from '../../../models/school-management/school-room.model';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {HelperService} from '../../../services/helper.service';
import {concatMap, tap} from 'rxjs/operators';
import {ClassModel} from '../../../models/school-management/class.model';
import {UserModel} from '../../../models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {RootService} from '../../../services/root.service';
import {ClassesService} from '../../../../functional-modules/classes/classes.service';

@Component({
  templateUrl: './material-base.component.html',
  styleUrls: [
    './material-base.component.scss',
    '../../../style/popups-shared.scss',
    '../../../style/file-upload.scss'
  ]
})
export class MaterialBaseComponent implements OnInit, OnDestroy {

  @ViewChild('editingRoomModal', {static: false}) editingRoomModal: TemplateRef<any>;

  wasSubmitted = false;
  loaded = false;
  saving = false;

  schoolRooms: SchoolRoomModel[];
  itemDropdown = [
    {
      text: 'Редагувати',
    },
    {
      text: 'Видалити'
    }
  ];
  editingRoomForm: FormGroup;
  request: Subscription;
  formError: any = {
    type: 'тип ' + HelperService.formErrors.requiredField,
    name: HelperService.formErrors.requiredField,
    max_child: 'не менше 5-ти'
  };
  classes: any[] = [];
  teachers: any[] = [];
  lessons: any[] = [];
  roomsTypes: any[] = [];
  previewUrls = {};
  floorFilters = null;

  floorFilter: any = '';
  meta: any = {};

  constructor(public managementService: ManagementService,
              public changeDetectorRef: ChangeDetectorRef,
              public fb: FormBuilder,
              public route: ActivatedRoute,
              public router: Router,
              public rootService: RootService,
              public classesService: ClassesService,
              public ngxSmartModalService: NgxSmartModalService,
              public changeDetectionRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initForm();
    this.route.params.subscribe((params) => {
      this.meta.current = _.isNumber(+params.page) && !_.isNaN(+params.page) ? +params.page : '1';
      this.floorFilter = _.isNumber(+params.floor) && !_.isNaN(+params.floor) ? params.floor : null;

      this.load();
    });
  }

  ngOnDestroy(): void {
    this.dropRequest();
  }

  load() {
    const filter = {
      floor: this.floorFilter,
      page: this.meta.current
    };

    if (!filter.floor) {
      delete filter.floor;
    }

    this.dropRequest();
    this.wasSubmitted = false;
    this.loaded = false;
    HelperService.upScroll();
    this.request = zip(
      ...this.loadAdditionalData()
    ).pipe(
      concatMap(() => {
        return this.managementService.getSchoolRooms(filter);
      })
    ).subscribe((rez: any) => {
      this.schoolRooms = rez.results.map(r => new SchoolRoomModel(r));
      this.meta.count_pages = rez.pages.count_pages;
      this.meta.total = rez.count;
      this.loaded = true;
    });
  }

  dropRequest() {
    if (this.request) {
      this.request.unsubscribe();
      this.request = null;
    }
  }

  loadAdditionalData() {
    let lessonReq = of(null);
    let classesReq = of(null);
    let teacherReq = of(null);

    if (!this.lessons.length) {
      lessonReq = this.managementService.getLessons().pipe(tap((rez: any) => {
        rez.forEach(l => {
          this.lessons.push({
            text: l.name,
            id: String(l.id)
          });
        });
      }));
      this.lessons.unshift({
        text: 'Не призначено',
        id: '0'
      });
    }

    if (!this.classes.length) {
      classesReq = this.classesService.getAllClasses().pipe(tap((rez) => {
        rez.forEach(c => {
          const newClass = new ClassModel(c);

          this.classes.push({
            text: /\d/.test(newClass.grade.name) ? `${newClass.grade.name}-й ${newClass.name} (${newClass.grade.education_group})` :  `${newClass.grade.name} ${newClass.name} (${newClass.grade.education_group})`,
            id: newClass.id
          });
        });
      }));
      this.classes.unshift({
        text: 'Не призначено',
        id: '0'
      });
    }

    if (!this.teachers.length) {
      teacherReq = this.managementService.getAllUsers('teacher').pipe(tap((rez) => {
        rez.forEach(c => {
          const newTeacher = new UserModel(c);

          this.teachers.push({
            text: `${newTeacher.first_name} ${newTeacher.last_name}`,
            id: newTeacher.uuid
          });
        });
      }));
      this.teachers.unshift({
        text: 'Не призначено',
        id: '0'
      });
    }

    const roomsTypesReq = this.managementService.getRoomsFloorsInfo().pipe(tap(({types, floors}) => {
      this.roomsTypes = [];
      this.floorFilters = [];

      _.forOwn(types, (v, f) => {
        this.roomsTypes.push({
          text: v,
          id: f
        });
      });

      floors.forEach(floor => {
        this.floorFilters.push({
          id: String(floor),
          text: `${floor}-й поверх`
        });
      });
    }));

    return [lessonReq, roomsTypesReq, classesReq, teacherReq];
  }

  initForm() {
    this.editingRoomForm = this.fb.group({
      id: [''],
      type: ['1', Validators.required],
      name: ['', Validators.required],
      max_child: ['', Validators.min(5)],
      floor: [''],
      photo: [''],
      classes: [''],
      lesson: [''],
      teacher_user: ['']
    });
  }

  addRoomPopup() {
    _.forOwn(this.editingRoomForm.controls, (c: FormControl, f) => {
      if (f === 'type') {
        c.setValue('1');
      } else {
        c.setValue('');
      }
    });
    this.wasSubmitted = false;
    this.saving = false;
    this.ngxSmartModalService.resetModalData('editingRoomModal');
    this.ngxSmartModalService.setModalData({
      type: 'add'
    }, 'editingRoomModal');
    this.ngxSmartModalService.create('editingRoomModal', this.editingRoomModal).open();
  }

  editRoomPopup(room: SchoolRoomModel, itemIndex: number) {
    const roomData = {
      id: room.id,
      type: room.type,
      name: room.name,
      max_child: room.max_child,
      floor: room.floor,
      photo: room.photo,
      classes: room.classes ? String(room.classes.id) : '',
      lesson: room.lesson ? String(room.lesson.id) : '',
      teacher_user: room.teacher_user ? String(room.teacher_user.uuid) : ''
    };

    _.forOwn(this.editingRoomForm.controls, (c: FormControl, f) => {
      c.setValue(roomData[f]);
    });
    this.saving = false;
    this.wasSubmitted = false;
    this.ngxSmartModalService.resetModalData('editingRoomModal');
    this.ngxSmartModalService.setModalData({
      type: 'edit',
      itemIndex,
    }, 'editingRoomModal');
    this.ngxSmartModalService.create('editingRoomModal', this.editingRoomModal).open();
  }

  editItem(action, room: SchoolRoomModel, itemIndex: number) {
    if (action === 0) {
      this.editRoomPopup(room, itemIndex);
    } else if (action === 1) {
      _.remove(this.schoolRooms, ['id', room.id]);
      this.managementService.deleteSchoolRoom(room.id).subscribe(() => {
        this.onChangeFilter('refresh').then((rez) => {
          if (!rez) {
            this.load();
          }
        });
      }, () => {
        alert('Помилка під час видалення!');
      });
    }
  }

  saveRoom(type) {
    this.wasSubmitted = true;
    if (this.editingRoomForm.valid) {

      if (type === 'add') {
        const newModel = new SchoolRoomModel(this.editingRoomForm.value);
        const reqData = {
          name: newModel.name,
          class_id: newModel.classes,
          lesson: newModel.lesson,
          teacher_uuid: newModel.teacher_user,
          floor: newModel.floor,
          max_child: newModel.max_child,
          type: newModel.type,
          photo: newModel.photo,
          is_active: false
        };

        this.saving = true;
        this.managementService.addSchoolRoom(reqData).subscribe(() => {
          this.ngxSmartModalService.getModal('editingRoomModal').close();
          this.onChangeFilter('refresh').then((rez) => {
            if (!rez) {
              this.load();
            }
          });
        });
      } else if (type === 'edit') {
        const newModel = new SchoolRoomModel(this.editingRoomForm.value);
        const reqData = {
          id: newModel.id,
          name: newModel.name,
          class_id: newModel.classes,
          lesson: newModel.lesson,
          teacher_uuid: newModel.teacher_user,
          floor: newModel.floor,
          max_child: newModel.max_child,
          type: newModel.type,
          photo: newModel.photo,
          is_active: false
        };

        if ((typeof reqData.photo) === 'string' && (reqData.photo as string).length) {
          delete reqData.photo;
        }

        this.saving = true;
        this.managementService.updateSchoolRoom(reqData).subscribe(() => {
          this.ngxSmartModalService.getModal('editingRoomModal').close();
          this.onChangeFilter('refresh').then((rez) => {
            if (!rez) {
              this.load();
            }
          });
        });
      }
    }
  }

  getFieldState(f) {
    return this.wasSubmitted ? this.editingRoomForm.get(f).valid : null;
  }

  onLoadPhoto([{file, url}]) {
    this.editingRoomForm.get('photo').setValue(file);
    this.formPhotoPreview(file, url);
  }

  formPhotoPreview(file: File, url: string) {
    this.previewUrls[this.getFilePreviewKey(file)] = {
      url,
      fileName: file.name.split('.').slice(0, -1).join('') + '.',
      fileExtension: _.last(file.name.split('.')) + '.',
      fileSize: `${String(HelperService.getFileSizeInMB(file))} Mb`
    };
  }

  getFilePreviewKey(file: File) {
    return `${file.name}-${file.size}`;
  }

  isPhotoFile(photo: string | File) {
    return photo instanceof File;
  }

  getSrcExtension(url) {
    const lastFragment = (_.last(url.split('/')) as string);

    return {
      fileName: lastFragment.split('.')[0] + '.',
      fileExtension: lastFragment.split('.')[1] + '.',
    };
  }

  removePhoto() {
    if (this.editingRoomForm.get('photo').value instanceof File) {
      delete this.previewUrls[this.getFilePreviewKey(this.editingRoomForm.get('photo').value)];
    }

    this.editingRoomForm.get('photo').reset('');
  }

  deny() {
    this.ngxSmartModalService.toggle('editingRoomModal', true);
  }

  onChangePage(page) {
    const path = this.managementService.getSectionPath('about-school/material-base');
    const params: any = {};

    if (page !== 1) {
      params.page = page;
    }

    if (this.floorFilter) {
      params.floor = this.floorFilter;
    }

    this.router.navigate([path, params]);
  }

  onChangeFilter(type, newValue?) {
    const params: any = {};

    _.forOwn({
      page: +this.meta.current !== 1 ? this.meta.current : null,
      floor: this.floorFilter
    }, (val, paramName) => {
      if (val) {
        params[paramName] = val;
      }
    });

    if (newValue) {
      if (type !== 'page') {
        delete params.page;
      }

      params[type] = newValue;
    } else {
      if (type !== 'page' && this.route.snapshot.params[type] != newValue) {
        delete params.page;
      }
    }

    this.changeDetectionRef.detectChanges();
    if (type === 'refresh') {
      return this.router.navigate([this.managementService.getSectionPath('about-school/material-base')]);
    } else {
      return this.router.navigate([this.managementService.getSectionPath('about-school/material-base'), params]);
    }
  }

  getDropdownList(item) {
    if (item.is_protected) {
      return this.itemDropdown.slice(0, 1);
    }

    return this.itemDropdown;
  }

}
