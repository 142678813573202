import {Injectable,} from '@angular/core';
import {AjaxService} from '../../+shared/services/ajax.service';
import {RootService} from '../../+shared/services/root.service';
import {of, Subject, zip} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import * as _ from 'lodash';
import {AuthService} from '../../+shared/services/auth.service';


@Injectable()
export class UsersService {

  tabs = [];
  changeUsersList$: Subject<any> = new Subject();
  updateUser$: Subject<any> = new Subject<any>();

  constructor(public ajaxService: AjaxService,
              public rootService: RootService,
              public authService: AuthService,
              public ngxSmartModalService: NgxSmartModalService) {
    this.rootService.roleChanging$.pipe(filter(role => !!role)).subscribe(() => {
      this.tabs = [
        {
          path: this.getSectionPath('list/pupils'),
          name: 'Учні',
        },
        {
          path: this.getSectionPath('list/parents'),
          name: 'Батьки',
        },
        {
          path: this.getSectionPath('list/teachers'),
          name: 'Вчителі',
        },
        {
          path: this.getSectionPath('list/staff'),
          name: 'Персонал',
        },
        {
          path: this.getSectionPath('list/new-users'),
          name: 'Нові користувачі'
        },
        {
          path: this.getSectionPath('list/archive'),
          name: 'Архів',
        }
      ];
    });
  }

  getSectionPath(path) {
    const section = `${this.rootService.role.getSectionPath()}/users`;
    return `${section}/${path}`;
  }

  refreshNewUsersCount() {
    this.authService.loadUser().subscribe(() => {
      _.find(this.tabs, ['name', 'Нові користувачі']).count = this.authService.user.count_new.users;
    });
  }

  getPupilAccount(uuid) {
    return this.ajaxService.get(`/student/account/${uuid}/`);
  }

  updateNotificationStatus(id) {
    return this.ajaxService.put(`/user/notification/set-read/${id}/`);
  }

  getNotificationFilter(type) {
    return this.ajaxService.get(`/user/notification/distinct/`, {[type]: true});
  }

  addPupilAccount(data) {
    return this.ajaxService.post('/student/account/', data);
  }

  addUserAccount(data) {
    return this.ajaxService.post('/user/account/', data);
  }

  getUserAccount(uuid) {
    return this.ajaxService.get(`/user/account/${uuid}/`, {show_contacts: true});
  }

  updateUserAccount(data) {
    return this.ajaxService.put(`/user/account/${data.uuid}/`, data);
  }

  updateStudentAccount(data) {
    return this.ajaxService.put(`/student/account/${data.uuid}/`, data);
  }

  getPupilMedicalCard(uuid) {
    return this.ajaxService.get(`/student/medical-card/${uuid}/`);
  }

  updatePupilMedicalCard(data) {
    return this.ajaxService.put(`/student/medical-card/${data.uuid}/`, data);
  }

  getParentsTypes() {
    return this.ajaxService.get('/student/parent-type/');
  }

  getPupilParent(id) {
    return this.ajaxService.get(`/student/parent/${id}/`);
  }

  addPupilParent(data) {
    return this.ajaxService.post('/student/parent/', data);
  }

  updatePupilParent(data) {
    return this.ajaxService.put(`/student/parent/${data.id}/`, data);
  }

  deleteParent(id) {
    return this.ajaxService.delete(`/student/parent/${id}/`);
  }

  getClassesWithoutTeacher() {
    return this.ajaxService.get('/student/classes-without-teacher/');
  }

  getLessons(filter_lesson_grade_name = null) {
    return this.ajaxService.get('/school/lesson/', {filter_lesson_grade_name});
  }

  getDepartments() {
    return this.ajaxService.get('/school/department/');
  }

  getUsersList(type, filters?) {
    return this.ajaxService.get(`/user/all/${type}/`, filters);
  }

  getNameUsersList(type, filters?) {
    return this.ajaxService.get(`/user/all/${type}/name-list/`, filters);
  }

  getEmailStatus(email) {
    return this.ajaxService.post('/user/check-exist/email/', {email}).pipe(map((rez: any) => !rez.error));
  }

  getPhoneStatus(phone) {
    return this.ajaxService.post('/user/check-exist/phone/', {phone}).pipe(map((rez: any) => !rez.error));
  }

  generateTmpPassword(email) {
    return this.ajaxService.post('/user/password-tmp-generate/', {email});
  }

  generateStudentPassword(uuid) {
    return this.ajaxService.put('/user/password-student-generate/', {uuid}, false);
  }

  createNewPassword(passData) {
    return this.ajaxService.post('/user/my-password-update/', passData);
  }

  getTeacherAccount(uuid) {
    return this.ajaxService.get(`/teacher/account/${uuid}/`);
  }

  addTeacher(data) {
    return this.ajaxService.post(`/teacher/account/`, data);
  }

  updateTeacher(data) {
    return this.ajaxService.put(`/teacher/account/${data.uuid}/`, data);
  }

  deleteTeacher(uuid) {
    return this.ajaxService.delete(`/teacher/account/${uuid}/`);
  }

  addUserToDepartment(uuid, department) {
    return this.ajaxService.post('/school/department-user/', {uuid, department});
  }

  deleteUserFromDepartment(uuid, department) {
    return this.ajaxService.delete(`/school/department-user/${department}/`, {
      uuid, department
    });
  }

  getTeacherAccreditationQualifications() {
    return this.ajaxService.get('/teacher/accreditation-qualification/');
  }

  getTeacherEducation(uuid) {
    return this.ajaxService.get(`/teacher/education/${uuid}/`);
  }

  addTeacherEducation(data) {
    return this.ajaxService.post('/teacher/education/', data);
  }

  updateTeacherEducation(data) {
    return this.ajaxService.put(`/teacher/education/${data.id}/`, data);
  }

  deleteTeacherEducation(id) {
    return this.ajaxService.delete(`/teacher/education/${id}/`);
  }

  getRolePermission(roleId) {
    return this.ajaxService.get(`/user/permission/?filter_role=${roleId}`);
  }

  updateUserPermission({uuid, role, permissions}) {
    return this.ajaxService.put('/user/account-permission/', {uuid, role, permissions});
  }

  assignStuffRile({uuid, role_id}) {
    return this.ajaxService.post('/user/role-staff-assign/', {uuid, role_id});
  }

  getParentChildren(parent_uuid) {
    return this.ajaxService.get(`/student/parent-student/${parent_uuid}/`);
  }

  importFromSmls({username, password}) {
    return this.ajaxService.post('/user/import-smls/', {
      username,
      password
    });
  }

  getColumns({role_type, file}) {
    return this.ajaxService.post('/user/import-xls/column-get/', {role_type, file});
  }

  uploadColumns({tmp_filename, role_type, columns}) {
    return this.ajaxService.post('/user/import-xls/column-upload/', {tmp_filename, role_type, columns}, true);
  }

  runImport({tmp_filename, role_type, users}) {
    return this.ajaxService.post('/user/import-xls/run-import/', {tmp_filename, role_type, users}, true);
  }

  sendEmail({uuid_list, subject, text}) {
    return this.ajaxService.post('/user/email-send/', {uuid_list, subject, text});
  }

  refuseNewUserRole(roleId) {
    return this.ajaxService.delete(`/user/role-remove/${roleId}`);
  }

  archiveRole(roles, delete_reason) {
    return this.ajaxService.delete('/user/role-archive/', {
      roles, delete_reason
    });
  }

  replaceTeacher(old_uuid, new_uuid) {
    return this.ajaxService.put('/schedule/replace-teacher/', {
      old_uuid, new_uuid
    });
  }
  
  unArchiveRole(role) {
    return this.ajaxService.put('/user/role-unarchive/', {role});
  }

  getUserNotifications(filters?) {
    return this.ajaxService.get('/user/notification/', filters);
  }

  getUserNotificationsWithFilters(filters) {
    return this.ajaxService.get('/user/notification/filtration/', filters);
  }

  usersListSendMail(users, modal, checkboxList, uuid?) {
    const uuid_list = [];

    if (!uuid) {
      const selectedUsers = _.pickBy(checkboxList);
      users.filter(user => Object.keys(selectedUsers).find(id => id === user.uuid))
        .filter(user => user.email)
        .forEach(user => uuid_list.push({
          uuid: user.uuid,
          name: user.username
        }));
    } else {
      const matchedUser = users.find(user => user.uuid === uuid);

      uuid_list.push({
        uuid: matchedUser.uuid,
        name: matchedUser.username
      });
    }

    if (uuid_list) {
      modal.resetModalData('mail-editor-popup');
      modal.getModal('mail-editor-popup').setData({
        uuid_list
      });
      modal.getModal('mail-editor-popup').open();
    }
  }

  parentsListSendMail(users, modal, checkboxList, uuid?) {
    const uuid_list = [];

    if (!uuid) {
      const selectedPupils = _.pickBy(checkboxList);
      users.filter(pupil => Object.keys(selectedPupils).find(id => id === pupil.uuid))
        .map(pupil => pupil.parents)
        .flat(2)
        .filter(parent => parent.email)
        .forEach(parent => uuid_list.push({
          uuid: parent.uuid,
          name: parent.username
        }));
    } else {
      users
        .find(pupil => pupil.uuid === uuid).parents
        .filter(parent => parent.email)
        .forEach(parent => uuid_list.push({
          uuid: parent.uuid,
          name: parent.username
        }));
    }

    if (uuid_list) {
      modal.resetModalData('mail-editor-popup');
      modal.getModal('mail-editor-popup').setData({
        uuid_list
      });
      modal.getModal('mail-editor-popup').open();
    }
  }

  userHasEmail(users, checkboxList) {
    return _.keys(checkboxList)
      .filter(userUuid => checkboxList[userUuid])
      .some((userUuid) => !!_.find(users, ['uuid', userUuid]).email);
  }

  parentHasEmail(users, checkboxList) {
    const selectedPupils = _.pickBy(checkboxList);
    return users.filter(pupil => Object.keys(selectedPupils).find(uuid => uuid === pupil.uuid))
      .map(pupil => pupil.parents)
      .flat(2)
      .find(parent => parent.email);
  }

}


