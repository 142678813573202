import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../+shared/services/auth.service';
import {Router} from '@angular/router';
import {ManagementService} from '../functional-modules/management/management.service';


@Component({
  templateUrl: './login.component.html',
  styleUrls: ['../+shared/style/auth-shared.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  wasSubmitted = false;
  wrongData = false;
  formErrors: object = {};

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public managementService: ManagementService,
    public changeDetectionRef: ChangeDetectorRef,
    public userService: AuthService
  ) {
    this.managementService.setMetaTitle('Вхід');
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      login: [
        null,
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      password: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
  }

  getFieldState(field) {
    if (this.wrongData) {
      return false;
    }

    return this.wasSubmitted ? this.form.get(field).valid : null;
  }

  login() {
    this.wasSubmitted = true;
    this.wrongData = false;

    if (this.form.valid) {
      this.userService.login(this.form.value).subscribe(
        () => {
        },
        () => {
          const field_type =
            this.form.value.login.indexOf('@') != -1 ? 'email' : 'телефон';
          this.formErrors = {
            login: `перевірте ще раз свій ${field_type}`,
            password: 'неправильний логін або пароль',
          };
          this.changeDetectionRef.detectChanges();
          this.wrongData = true;
        }
      );
    } else {
      this.formErrors = {
        login: 'мінімум 6 символів',
        password: 'мінімум 6 символів',
      };
    }
  }
}
