import {Injectable} from '@angular/core';
import {AjaxService} from '../../+shared/services/ajax.service';
import {RootService} from '../../+shared/services/root.service';
import {AuthService} from '../../+shared/services/auth.service';
import {NgxSmartModalService} from 'ngx-smart-modal';


@Injectable()
export class SectionsService {

  constructor(public ajaxService: AjaxService,
              public rootService: RootService,
              public authService: AuthService,
              public ngxSmartModalService: NgxSmartModalService) {
  }

  getSectionTypes(withSections = false, withArchive = false) {
    if (withArchive) {
      return this.ajaxService.get(`/section/type/?with_archive=true`);
    }
    return this.ajaxService.get(`/section/type/?with_sections=${withSections}`);
  }

  getSection(id) {
    return this.ajaxService.get(`/section/group/${id}/`);
  }

  exportSection(id, selectedClass, onlyActive, nullFilter) {
    return this.ajaxService.get(`/section/group-export/${id}/`, {
      filter_class: selectedClass,
      filter_active: onlyActive,
      filter_null: nullFilter
    });
  }

  addSection(data) {
    return this.ajaxService.post(`/section/group/`, data);
  }

  updateSection(data) {
    return this.ajaxService.put(`/section/group/${data.id}/`, data);
  }

  restoreSection(sectionId) {
    return this.ajaxService.put(`/section/group-restore/${sectionId}/`, {});
  }

  removeSection(id) {
    return this.ajaxService.delete(`/section/group/${id}/`);
  }

  exportSectionsList() {
    return this.ajaxService.get(`/section/export-all/`);
  }

  exportSchoolTotal() {
    return this.ajaxService.get(`/section/export-school-total/`);
  }

}

