import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {takeWhile} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {IKtpTheme} from '../../interfaces/journal/ktp-theme.interface';
import {LibraryService} from '../../../functional-modules/library/library.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-copy-lesson-materials',
  templateUrl: './copy-lesson-materials.component.html',
  styleUrls: ['../../style/popups-shared.scss', './copy-lesson-materials.component.scss']
})
export class CopyLessonMaterialsComponent implements OnInit, AfterViewInit, OnDestroy {
  work = true;
  modalData: {
    onSave: any;
    grade: string;
    theme: IKtpTheme
  };
  assigning_list: {
    id: number,
    name: string,
    logo: null,
    selected?: boolean,
    class_groups?: {
      id: number,
      name: string,
      selected?: boolean
    }[]
  }[];
  isAllSelected: boolean;
  hasError: boolean;
  loaded = false;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public libraryService: LibraryService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('copy-lesson-materials')
      .onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.hasError = false;

        this.modalData = this.ngxSmartModalService.getModal('copy-lesson-materials').getData();
        this.libraryService.getAssigningList(this.modalData.theme.lesson_planning_id, true).subscribe(list => {
          this.assigning_list = Object.values(list);
          this.assigning_list.forEach(assign_class => {

            if (assign_class.class_groups) {
              assign_class.class_groups.forEach(class_group => {
                const selGroup = this.modalData.theme.materials[0].class_group_list
                  .find(one_class_group => one_class_group.id === class_group.id);
                selGroup ? class_group.selected = true : class_group.selected = false;
              });
            }

            const isSelClass = this.modalData.theme.materials[0].class_list.find(one_class => one_class.id === assign_class.id);
            isSelClass || (assign_class.class_groups && assign_class.class_groups.every(c => c.selected)) ?
              assign_class.selected = true : assign_class.selected = false;
          });
          this.assigning_list.every(assign_class => assign_class.selected) ? this.isAllSelected = true : this.isAllSelected = false;

          this.loaded = true;
        });
      });
  }

  syncTree() {
    this.isAllSelected = this.assigning_list.every((classData) => {
      return classData.selected || classData.class_groups && classData.class_groups.every((groupData) => groupData.selected);
    }) || null;
  }

  select(type: 'grade' | 'class' | 'group', item: any, val) {
    if (val) {
      this.hasError = false;
    }
    switch (type) {
      case 'grade':
        if (_.isBoolean(this.isAllSelected)) {
          this.assigning_list = this.assigning_list.map((classItem) => {
            classItem.selected = val;
            if (classItem.class_groups) {
              classItem.class_groups.map(classGroup => classGroup.selected = val);
            }
            return classItem;
          });
        }
        break;
      case 'class':
        if (!val) {
          this.isAllSelected = null;
        }
        if (item.class_groups) {
          item.class_groups = item.class_groups.map((groupItem) => {
            groupItem.selected = val;
            return groupItem;
          });
        }
        this.syncTree();
        break;
      case 'group':
        item.selected = item.class_groups.every(groupItem => groupItem.selected);
        this.syncTree();
    }
  }

  ngOnDestroy() {
    this.work = false;
  }

  cancel() {
    this.ngxSmartModalService.getModal('copy-lesson-materials').close();
  }

  assign() {
    const selClass = this.assigning_list.some(classItem => classItem.selected);
    const selClassGroup = this.assigning_list.find(classItem => {
      if (classItem.class_groups) {
        return classItem.class_groups.some(classGroup => classGroup.selected);
      } else {
        return null;
      }
    });
    if (!selClass && !selClassGroup) {
      this.hasError = true;
    } else {
      if (this.modalData.onSave) {
        this.modalData.onSave(this.assigning_list);
      }
      this.cancel();
    }
  }

}
