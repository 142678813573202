import {Base} from '../base.model';
import {PupilModel} from './pupil.model';

export class ParentModel extends Base {

  uuid: string;
  first_name: string;
  last_name: string;
  surname: string;
  gender: string;
  photo: string;
  birthday: string;
  phone: string;
  email: string;
  students: any[];
  id: string;
  type: string;
  work_place: string;
  work_position: string;
  is_primary: boolean;
  user_role: any;
  username: string;
  tmp_password: string;

  constructor(items) {
    super();
    super.loadFields(items);
    super.formatPhone('phone');
  }

}
