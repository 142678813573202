import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../functional-modules/management/management.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(public managementService: ManagementService) {
    this.managementService.setMetaTitle('Error');
  }

  ngOnInit(): void {
  }

}
