import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../../users/users.service';
import { RootService } from '../../../../+shared/services/root.service';

@Component({
    selector: 'voters',
    templateUrl: './voters.component.html',
    styleUrls: ['./voters.component.scss', '../../../../+shared/style/modules-shared.scss', '../../../../+shared/style/user.scss']
})
export class VotersComponent implements OnInit {

    @Input() voters = [];

    searchText = '';
    list = [];

    constructor(public usersService: UsersService,
                public rootService: RootService,
                public route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.list = this.voters;
    }

    getClassName(p) {
        if (!p.class) {
            return '&ndash;';
        }

        const grade = p.class.grade_name;

        return p.class ? (/\d/.test(grade) ? p.class.grade_name + '-й ' : grade + ' ') + p.class.name : '&ndash;';
    }

    search(text) {
        this.searchText = text;

        if (this.searchText) {
            this.list = this.voters.filter((vote) => {
                return `${vote.voter.first_name}  ${vote.voter.last_name}`.includes(this.searchText);
            });
        } else {
            this.list = this.voters;
        }
    }
}
