import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {NgxSmoothDnDModule} from 'ngx-smooth-dnd';
import {CommonModule} from '@angular/common';
import {ContactsComponent} from './contacts/contacts.component';
import {SchoolRulesComponent} from './school-rules/school-rules.component';
import {SchoolProfileComponent} from './school-profile/school-profile.component';
import {MaterialBaseComponent} from './material-base/material-base.component';
import {SchoolRulesPopupComponent} from './school-rules-popup/school-rules-popup.component';
import {SystemInfoPopupComponent} from './system-info-popup/system-info-popup.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {GeneralInfoPopupComponent} from './general-info-poup/general-info-popup.component';
import {ContactsInfoPopupComponent} from './contacts-info-popup/contacts-info-popup.component';
import {SchoolAddressPopupComponent} from './school-address-popup/school-address-popup.component';

@NgModule({
  declarations: [
    SchoolProfileComponent,
    MaterialBaseComponent,
    ContactsComponent,
    SchoolRulesComponent,
    SchoolRulesPopupComponent,
    GeneralInfoPopupComponent,
    SystemInfoPopupComponent,
    ContactsInfoPopupComponent,
    SchoolAddressPopupComponent
  ],
  imports: [
    NgxSmoothDnDModule,
    SharedModule,
    CommonModule,
    EditorModule,
  ],
  exports: [
    SchoolProfileComponent,
    MaterialBaseComponent,
    ContactsComponent,
    SchoolRulesComponent,
    SchoolRulesPopupComponent,
    GeneralInfoPopupComponent,
    SystemInfoPopupComponent,
    ContactsInfoPopupComponent,
    SchoolAddressPopupComponent
  ]
})
export class AboutSchoolModule {
}
