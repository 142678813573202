import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ManagementService } from '../../../functional-modules/management/management.service';

@Component({
    selector: 'slot-lessons-popup',
    templateUrl: './slot-lessons-popup.component.html',
    styleUrls: [
        './slot-lessons-popup.component.scss',
        '../../style/popups-shared.scss'
    ]
})
export class SlotLessonsPopupComponent implements AfterViewInit {

        loaded = true;
        modalData: any = {};
        lessons = [];
        canRemove = false;

        constructor(public ngxSmartModalService: NgxSmartModalService,
                    public managementService: ManagementService) {
        }

        ngAfterViewInit() {
            this.ngxSmartModalService.getModal('slot-lessons-popup')
                .onOpen.subscribe(() => {
                this.modalData = this.ngxSmartModalService.getModal('slot-lessons-popup').getData();
                this.canRemove = this.modalData.canRemove;
                this.lessons = [];
                this.lessons = this.modalData.lessons.map(l => {
                    const date = new Date();
                    date.setHours(l.start_time.split(':')[0]);
                    date.setMinutes(l.start_time.split(':')[1]);

                    return {
                        timestamp: date.getTime(),
                        data: l
                    };
                }).sort((l1, l2) => l1.timestamp - l2.timestamp)
                    .map(l => {
                    return l.data;
                });
            });
        }

        cancel() {
            this.ngxSmartModalService.getModal('slot-lessons-popup').close();
        }

        onOpenEvent(item) {
            if (this.modalData.onOpenEvent) {
                this.modalData.onOpenEvent(item);
            }

            this.cancel();
        }

        remove(lesson) {
            if (confirm('Видалення уроку приведе до стирання всіх оцінок, завдань для самопідготовки та відвідувань на уроці.')) {
                if (confirm('Ви впевнені що хочете видалити цей урок?')) {
                    if (this.modalData.onRemove) {
                        this.lessons = this.lessons.filter(l => l.id !== lesson.id);
                        this.modalData.onRemove(lesson.id);
                    }
                }}
        }
}
