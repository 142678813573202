import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sortDates'
})
export class SortDatesPipe implements PipeTransform {

  transform(value: string[], ...args: unknown[]): unknown {
    return _.sortBy(value, (date) => {
      return new Date(date);
    });
  }

}
