import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {RootService} from '../../services/root.service';
import {ManagementService} from '../../../functional-modules/management/management.service';
import {Router} from '@angular/router';
import {RoleModel} from '../../models/role.model';
import {ParentsService} from '../../../parents/parents.service';

@Component({
  selector: 'top-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  menuIsOpen = false;

  constructor(public authService: AuthService,
              public managementService: ManagementService,
              public rootService: RootService,
              public parentsService: ParentsService,
              public router: Router) {
    this.rootService.toggleOppenningMenu$.subscribe(isOpen => {
      this.menuIsOpen = isOpen;
    });
  }

  logout() {
    this.authService.logout().subscribe();
  }

  toggleOpenMenu() {
    this.menuIsOpen = !this.menuIsOpen;
    this.rootService.toggleOppenningMenu$.next(this.menuIsOpen);
  }

  changeRole(role: RoleModel) {
    this.rootService.changeRole(role);
  }

  showFinanceIcon() {
    if (this.rootService.role.name === 'parent' && !this.router.url.includes('parent-main-page')) {
      return this.parentsService.paymentBannerVisible() && this.parentsService.isRedPaymentBanner();
    }
  }
}
