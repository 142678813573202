import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { first, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements OnDestroy{

  @Input() usage: string;

  targetedElement: any;
  work = true;


  @Output('outsideClickHandler') close = new EventEmitter();

  constructor() {
  }

  ngOnDestroy() {
    this.work = false;
  }

  @HostListener('click', ['$event'])
  public handleClickOnTarget(event) {
    this.targetedElement = event.currentTarget;

    fromEvent(document.body, 'click').pipe(
        first(),
        takeWhile(() => this.work)
    ).subscribe(() => {
      if (this.targetedElement) {
        this.close.emit(false);
      }
      this.targetedElement = null;
    });
  }
}
