import {AfterViewInit, Component, OnInit} from '@angular/core';
import {takeWhile} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  selector: 'lesson-materials-remove',
  templateUrl: './lesson-materials-remove.component.html',
  styleUrls: ['./lesson-materials-remove.component.scss', '../../style/popups-shared.scss']
})
export class LessonMaterialsRemoveComponent implements OnInit, AfterViewInit {
  loaded = false;
  openedModal = true;
  selections: {
    lessonMaterials: boolean;
    homeWorkMaterials: boolean;
    controlWorkMaterials: boolean;
  };

  constructor(public ngxSmartModalService: NgxSmartModalService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('lesson-materials-remove')
      .onOpenFinished
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.selections = {
          lessonMaterials: true,
          homeWorkMaterials: true,
          controlWorkMaterials: true
        };
        this.loaded = true;
      });
  }

  changeState(state, checkbox) {
    this.selections[checkbox] = state;
  }

  action(type) {
    if (type === 'delete') {
      this.ngxSmartModalService.getModal('lesson-materials-remove').setData(this.selections);
    } else {
      this.ngxSmartModalService.getModal('lesson-materials-remove').setData(null);
    }
    this.ngxSmartModalService.getModal('lesson-materials-remove').close();
  }

}
