import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {RootService} from '../../../services/root.service';
import {UsersService} from '../../../../functional-modules/users/users.service';
import {AuthService} from '../../../services/auth.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {concatMap, debounceTime, first, takeWhile, tap} from 'rxjs/operators';
import {SchoolSettingsModel} from '../../../models/school-management/school-settings.model';
import {HelperService} from '../../../services/helper.service';
import {SettingsContactModel} from '../../../models/school-management/settings-contact.model';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Observer, of, zip} from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'general-info-popup',
  templateUrl: './general-info-popup.component.html',
  styleUrls: ['./general-info-popup.component.scss', '../../../style/popups-shared.scss']
})
export class GeneralInfoPopupComponent implements AfterViewInit, OnDestroy {

  loaded = false;
  modalData: any = {};
  facebookPattern = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/;
  workHoursPattern = /([01][0-9]|2[0-3]):([0-5][0-9])\b/;
  schoolInfo: SchoolSettingsModel;
  wasSubmitted = false;
  files: any = {};
  formErrors: any = {
    name: HelperService.formErrors.requiredField,
    type: HelperService.formErrors.requiredField,
    start_work_week: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    end_work_week: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    start_work_sat: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    end_work_sat: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    start_work_sun: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    end_work_sun: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    work_days: 'відсутні данні про робочі дні',
    accreditation: HelperService.formErrors.requiredField,
    website_url: HelperService.formErrors.incorrectFormat,
    facebook_url: HelperService.formErrors.incorrectFormat,
  };
  schoolInfoForm: FormGroup;
  settingsContactModel: SettingsContactModel;
  currentDate: NgbDate;
  minBirthDate;
  work = true;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public rootService: RootService,
              public usersService: UsersService,
              public changeDetectorRef: ChangeDetectorRef,
              public authService: AuthService,
              public fb: FormBuilder) {

  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('general-info-popup')
      .onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.initForm();
      });
  }

  ngOnDestroy() {
    this.work = false;
  }

  initForm() {
    const currDate = new Date();
    this.modalData = this.ngxSmartModalService.getModal('general-info-popup').getData();
    this.wasSubmitted = false;
    this.loaded = false;
    this.currentDate = new NgbDate(currDate.getFullYear(), currDate.getMonth() + 1, currDate.getDate());
    this.minBirthDate = new NgbDate(1900, 1, 1);
    this.managementService.getSchoolSettings().pipe(tap((data) => {
      this.schoolInfo = new SchoolSettingsModel(data);

      this.files.photo_url = this.schoolInfo.photo_url;
      this.files.logo_url = this.schoolInfo.logo_url;

      this.schoolInfoForm = this.fb.group({
        name: [this.schoolInfo.name, Validators.compose([Validators.required])],
        description: [this.schoolInfo.description],
        logo_url: [this.schoolInfo.logo_url],
        photo_url: [this.schoolInfo.photo_url],
        type: [this.schoolInfo.type, Validators.required],
        accreditation: [this.schoolInfo.accreditation, Validators.required],

        start_work_week: [this.schoolInfo.start_work_week, Validators.compose([Validators.required, Validators.pattern(this.workHoursPattern), this.validateTimeWork('weekdays')])],
        end_work_week: [this.schoolInfo.end_work_week, Validators.compose([Validators.required, Validators.pattern(this.workHoursPattern), this.validateTimeWork('weekdays')])],
        start_work_sat: [this.schoolInfo.start_work_sat, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('saturday')])],
        end_work_sat: [this.schoolInfo.end_work_sat, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('saturday')])],
        start_work_sun: [this.schoolInfo.start_work_sun, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('sunday')])],
        end_work_sun: [this.schoolInfo.end_work_sun, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('sunday')])],

        work_days: [this.schoolInfo.work_days, Validators.compose([Validators.required])],
        is_online: [this.schoolInfo.is_online],
        is_uniform: [this.schoolInfo.is_uniform],
        is_finance_integration: [this.schoolInfo.is_finance_integration],
        is_currency: [this.schoolInfo.is_currency],
        // inner_currency: [this.schoolInfo.inner_currency, this.isRequiredCurrency()],
        facebook_url: [this.schoolInfo.facebook_url, Validators.pattern(this.facebookPattern)],
        website_url: [this.schoolInfo.website_url, Validators.pattern(HelperService.linkPattern)]
      });

      // this.schoolInfoForm.get('is_currency').valueChanges.subscribe((val) => {
      //   this.schoolInfoForm.get('inner_currency').setValue('');
      //   this.schoolInfoForm.get('inner_currency').updateValueAndValidity();
      //   this.changeDetectorRef.detectChanges();
      // });

      this.schoolInfoForm.valueChanges
        .pipe(debounceTime(200))
        .subscribe((rez) => {
          _.forOwn(rez, (val, key) => {
            this.schoolInfo[key] = val;
          });
          this.changeDetectorRef.detectChanges();
        });
    })).subscribe(() => {
      this.loaded = true;
    });
  }

  save() {
    this.wasSubmitted = true;

    if (this.schoolInfoForm.valid) {
      this.schoolInfo.photo_url = this.files.photo_url;
      this.schoolInfo.logo_url = this.files.logo_url;

      let data: any = _.pick(this.schoolInfo, [
        'name',
        'description',
        'logo_url',
        'photo_url',
        'type',
        'start_work_week',
        'end_work_week',
        'work_days',
        'accreditation',
        'facebook_url',
        'is_online',
        'is_uniform',
        'is_currency',
        'is_finance_integration',
        // 'inner_currency',
        'website_url'
      ]);


      if (this.schoolInfo.start_work_sat && this.schoolInfo.end_work_sat) {
        data = {
          ...data,
          start_work_sat: this.schoolInfo.start_work_sat,
          end_work_sat: this.schoolInfo.end_work_sat
        };
      }

      if (this.schoolInfo.start_work_sun && this.schoolInfo.end_work_sun) {
        data = {
          ...data,
          start_work_sun: this.schoolInfo.start_work_sun,
          end_work_sun: this.schoolInfo.end_work_sun
        };
      }

      if (_.isString(data.photo_url) && data.photo_url.length) {
        delete data.photo_url;
      } else if (data.photo_url === null) {
        data.photo_url = '';
      }

      if (_.isString(data.logo_url) && data.logo_url.length) {
        delete data.logo_url;
      } else if (data.logo_url === null) {
        data.logo_url = '';
      }

      this.loaded = false;
      this.managementService.updateSchoolSettings(data)
        .pipe(concatMap(() => {
          return this.managementService.setSchoolSettings();
        })).subscribe(() => {
        if (this.modalData.onSave) {
          this.modalData.onSave();
        }
        this.cancel();
      });
    }
  }

  changeCheckboxValue(event, key) {
    event.preventDefault();
    const input = this.schoolInfoForm.get(key);
    input.setValue(!input.value);
  }

  cancel() {
    this.ngxSmartModalService.getModal('general-info-popup').close();
  }

  getFieldState(field) {
    return this.wasSubmitted ? this.schoolInfoForm.get(field).valid : null;
  }

  hasDays(day) {
    return (this.schoolInfoForm.get('work_days').value || '').split(',').findIndex((dayIndex) => {
      return dayIndex === day;
    }) !== -1;
  }

  toggleDay(day) {
    const days = (this.schoolInfoForm.get('work_days').value || '').split(',').filter(i => !!i);

    if (days.includes(day)) {
      _.remove(days, item => day === item);
    } else {
      days.push(day);
    }

    if (days.some(d => (+d) < 6)) {
      this.schoolInfoForm.get('work_days').setValue(days.length ? days.join(',') : '');
      this.changeDetectorRef.detectChanges();
    }
  }

  onLoadPhoto(type: 'logo_url' | 'photo_url', data) {
    const {url, file} = data;
    this.files[type] = file;
    this.schoolInfoForm.get(type).setValue(url);
  }

  removePhotoUrl() {
    this.files.photo_url = null;
    this.schoolInfoForm.get('photo_url').setValue('');
  }

  checkWorkTime() {
    const data = this.schoolInfoForm.value;

    if (data.start_work_week && data.end_work_week) {
      if (parseInt(data.start_work_week as string, 10) >= parseInt(data.end_work_week as string, 10)) {
        this.schoolInfoForm.get('end_work_week').reset('');
      }
    }

    if (data.start_work_sat && data.end_work_sat) {
      if (parseInt(data.start_work_sat as string, 10) >= parseInt(data.end_work_sat as string, 10)) {
        this.schoolInfoForm.get('end_work_sat').reset('');
      }
    }

    if (data.start_work_sun && data.end_work_sun) {
      if (parseInt(data.start_work_sun as string, 10) >= parseInt(data.end_work_sun as string, 10)) {
        this.schoolInfoForm.get('end_work_sun').reset('');
      }
    }

    if (!this.schoolInfo.hasWorkDays('weekdays')) {

      if (this.schoolInfoForm.get('start_work_week').value || this.schoolInfoForm.get('end_work_week').value) {
        this.schoolInfoForm.get('start_work_week').reset('');
        this.schoolInfoForm.get('end_work_week').reset('');
      }

    }

    if (!this.schoolInfo.hasWorkDays('saturday')) {
      if (this.schoolInfoForm.get('start_work_sat').value || this.schoolInfoForm.get('end_work_sat').value) {
        this.schoolInfoForm.get('start_work_sat').reset('');
        this.schoolInfoForm.get('end_work_sat').reset('');
      }
    }

    if (!this.schoolInfo.hasWorkDays('sunday')) {
      if (this.schoolInfoForm.get('start_work_sun').value || this.schoolInfoForm.get('end_work_sun').value) {
        this.schoolInfoForm.get('start_work_sun').reset('');
        this.schoolInfoForm.get('end_work_sun').reset('');
      }
    }
  }

  validateTimeWork(workType) {
    return (control: AbstractControl) => {

      if ((!control.value || control.value.length !== 5) && this.schoolInfo.hasWorkDays(workType)) {
        return {incorrectTime: true};
      }

      return null;
    };
  }

  // isRequiredCurrency() {
  //   return (control: AbstractControl) => {
  //     if (!this.schoolInfoForm) {
  //       return null;
  //     }
  //
  //     if (this.schoolInfoForm.get('is_inner_currency').value && (!control.value || !control.value.length)) {
  //       return {incorrectTime: true};
  //     }
  //
  //     return null;
  //   };
  // }

  selectSchoolPhoto(target, event) {
    const file = target.files[0];
    if (file && HelperService.isAllowedSize(file, 5120)) {
      HelperService.getDataImageFromFile(file as File).then((url: string) => {
        this.files.photo_url = file;
        this.schoolInfoForm.get('photo_url').setValue(url);
      });
    }
    event.preventDefault();
    event.stopPropagation();
  }

  getEmailValidation(excludedEmail, control: FormControl) {
    if (!control.value) {
      return of(null).pipe(first());
    }

    return new Observable((observer: Observer<any>) => {
      if (excludedEmail && control.value === excludedEmail) {
        observer.next(null);
      } else {
        this.usersService.getEmailStatus(control.value).subscribe(() => {
          observer.next(null);
        }, () => {
          observer.next({emailTaken: true});
        });
      }

    }).pipe(first());
  }

  getPhoneValidation(excludedPhone, control: FormControl) {
    const val = control.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

    if (!val) {
      return of(null).pipe(first());
    }

    return new Observable((observer: Observer<any>) => {
      if (excludedPhone && HelperService.formatPhone(val) === excludedPhone) {
        observer.next(null);
      } else {
        this.usersService.getPhoneStatus(val).subscribe(() => {
          observer.next(null);
        }, () => {
          observer.next({phoneTaken: true});
        });
      }
    }).pipe(first());
  }

}


