import {Base} from '../base.model';

export class ServiceModel extends Base {

  id: number;
  actual_price: number;
  month_price: number;
  full_price: number;
  balance: number;
  paid: number;
  title: string;
  contract_id: string;
  year_start_balance: number;
  finance_account_id: number;
  is_active: boolean;
  is_section: boolean;
  user?: any;
  vkh: number;
  user_year_payments: number;

  constructor(items) {
    super();
    super.loadFields(items);

    this.actual_price = parseFloat(items.actual_price);
    this.month_price = parseFloat(items.month_price);
    this.balance = parseFloat(items.balance);
    this.paid = parseFloat(items.paid);
  }

  getTotal() {
    let result = this.balance + this.paid;
    if (this.is_active) {
      result -= this.actual_price;
    }
    return result;
  }

}
