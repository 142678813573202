import {Injectable,} from '@angular/core';
import * as _ from 'lodash';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import animateScrollTo from 'animated-scroll-to';

@Injectable()
export class HelperService {

  static emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  static linkPattern = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,6}(:[0-9]{1,5})?(\\/.*)?$';
  static phonePattern = '^(\\+38* (\\( [0-9]{3} \\) )) ?[0]?[0-9][0-9\\- ]*$';
  static passwordPattern = '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d@$!%*#?&]{6,}$';

  static formErrors = {
    requiredField: 'обов\'язкове поле',
    incorrectFormat: 'невірний формат'
  };
  static phoneSample = '+38 ( 000 ) 000 00 00';
  static passwordError = 'Пароль повинен включаючи літери та цифри';
  static passwordLengthError = 'Пароль повинен містити не менше 6-ти символів';
  static phonePrefix = '+38 ( 0';

  constructor() {
  }

  static getDataImageFromFile(file: File) {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        res(reader.result);
      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
    });
  }

  static isLink(text: string) {
    return text.match('^(http|ftp|https):\\/\\/([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:\\/~+#-]*[\\w@?^=%&\\/~+#-])');
  }

  static isAllowedMimeType(files: File[] | File, mimetypes: string[]) {
    if (_.isArray(files)) {
      return Array.from(files).every((file: File) => mimetypes.includes(file.type));
    } else {
      return mimetypes.includes(files.type);
    }
  }

  static isAllowedSize(files: File[] | File, maxSizeKb: number) {
    if (_.isArray(files)) {
      return Array.from(files).every((file: File) => {
        const fileSize = Math.round((file.size / 1024));
        return fileSize <= maxSizeKb;
      });
    } else {
      const fileSize = Math.round((files.size / 1024));
      return fileSize <= maxSizeKb;
    }
  }

  static getErrMessage(text: string, suffix = '') {
    return text + suffix;
  }

  static formatPhone(val) {
    if (!val) {
      return '';
    }

    const code = val.slice(0, 2);
    const intCode = val.slice(2, 5);
    const group1 = val.slice(5, 8);
    const group2 = val.slice(8, 10);
    const group3 = val.slice(10, 12);

    return `+${code} ( ${intCode} ) ${group1} ${group2} ${group3}`;
  }

  static getFileSizeInMB(file: File) {
    return (file.size / Math.pow(1024, 2)).toFixed(3);
  }

  static getFileInfoFromUrl(url: string) {
    if (!url) {
      return {
        name: null,
        extension: null
      };
    }

    const fileName = _.last(url.split('/'));

    return {
      name: fileName.split('.').slice(0, fileName.split('.').length - 1).join('.'),
      extension: _.last(fileName.split('.'))
    };
  }

  static getDateFromNgbDate(date: NgbDate) {
    if (!date) {
      return '';
    }

    return moment(new Date(`${date.year}/${date.month}/${date.day}`)).locale('uk').format('YYYY-MM-DD');
  }

  static getNgbDateFormat(dateString) {
    if (!dateString) {
      return '';
    }

    const [year, month, date] = dateString.split('-');
    const dateModel = new Date(`${year}/${month}/${date}`);
    return new NgbDate(dateModel.getFullYear(), dateModel.getMonth() + 1, dateModel.getDate());
  }

  static upScroll() {
    if (window.scrollY < 300) {
      window.scrollTo({
        top: 0,
      });

      return Promise.resolve();
    }

    return animateScrollTo(0, {
      cancelOnUserAction: false,
      maxDuration: 100,
      minDuration: 0,
      speed: 400,
    });
  }

  static getFileFromDataUrl(dataUrl, size) {
    return new Promise((res) => {
      const tempImg = new Image();
      const MAX_WIDTH = size[0];
      const MAX_HEIGHT = size[1];

      tempImg.onload = function () {
        let tempW = tempImg.width;
        let tempH = tempImg.height;

        if (tempW !== size[0] || tempH !== size[1]) {
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = tempW;
          canvas.height = tempH;
          const ctx = canvas.getContext('2d');

          ctx.drawImage(this as any, 0, 0, tempW, tempH);

          setTimeout(() => {
            fetch(canvas.toDataURL())
              .then((res) => {
                return res.arrayBuffer();
              })
              .then((buf) => {
                res(new File([buf], 'file.png', {type: 'image/png'}));
              });
          }, 250);
        } else {
          fetch(dataUrl)
            .then((res) => {
              return res.arrayBuffer();
            })
            .then((buf) => {
              res(new File([buf], 'file.png', {type: 'image/png'}));
            });
        }
      };
      tempImg.src = dataUrl;
    });
  }
}
