import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatData'
})
export class FormatDataPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    return value.split('-').splice(1, 2).reverse().join('.');
  }
}
