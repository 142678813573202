import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import  * as _ from 'lodash';

@Component({
  selector: 'dots-dropdown',
  templateUrl: './dots-dropdown.component.html',
  styleUrls: ['./dots-dropdown.component.scss']
})
export class DotsDropdownComponent implements OnInit, OnChanges {

  @ViewChild('wrap') wrap: ElementRef;

  @Input() list: {
    text: string,
    iconSrc?: string
  }[];
  @Input() container: [
    string,
    string
  ] = [
    '40px',
    '37px'
  ];
  @Input() dropdownWidth = 175;

  isOpen = false;
  renderList: any = [];

  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list  && changes.list.currentValue) {
      const data = _.cloneDeep(changes.list.currentValue);
      if (!_.isEqual(this.renderList, data)) {
        this.renderList = data;
      }
    }
  }

  @HostBinding('class.opened') get valid() {
    return this.isOpen;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.wrap.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  chooseVariant(i) {
    this.select.emit(i);
    this.isOpen = false;
  }

}
