import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  @Input() data: {
    current: number,
    count: number,
    total?: number,
    perPage?: number
  };
  buttons = [];
  current = 0;

  @Output() changePage: EventEmitter<any> = new EventEmitter<any>();

  constructor(public router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.current = +this.data.current;

      if (this.buttons.length !== this.data.count) {
        this.buttons = [];
        this.makePagination(this.current, this.data.count);
      }
    }
  }

  makePagination(c, m) {
    const current = c;
    const last = m;
    const delta = 1;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || i >= left && i < right) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    this.buttons = rangeWithDots;
  }

  onChangePage(page) {
    if (page !== '...') {
      this.current = page;
      this.changePage.emit(page);
      this.makePagination(this.current, this.data.count);
    }
  }

  move(d: number) {
    if (d === 1) {
      this.onChangePage(this.current + 1);
    } else {
      this.onChangePage(this.current - 1);
    }
  }


}
