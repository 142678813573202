import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';

import {AjaxService} from '../../+shared/services/ajax.service';
import {RootService} from '../../+shared/services/root.service';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {ClassModel} from '../../+shared/models/school-management/class.model';
import {IClassStudentsList} from './_partial/interfaces/class-students-list.interface';
import {filter, tap} from 'rxjs/operators';

@Injectable()
export class ClassesService {

  getClassById$ = new BehaviorSubject(null);
  getClassGroupId$ = new BehaviorSubject(null);
  getLessonId$ = new BehaviorSubject(null);
  changeClassesList$ = new BehaviorSubject(null);
  updateClassInfo$ = new BehaviorSubject(null);
  updateStudentClassList$ = new BehaviorSubject(null);
  updateStudentClassGroupList$ = new BehaviorSubject(null);
  changePeriodFilter$ = new BehaviorSubject(localStorage.getItem('achievements-period'));
  changeStudentsFilter$ = new BehaviorSubject(localStorage.getItem('achievements-students'));
  changeTaskTypesFilter$ = new BehaviorSubject(localStorage.getItem('achievements-tasks'));
  updateJournalLessonData$ = new BehaviorSubject(null);
  changeTheme$ = new BehaviorSubject(null);
  saveMaterialsPopupAnswer$ = new Subject();
  updateClasses$ = new Subject();
  lesson$ = new BehaviorSubject(null);
  class: ClassModel;
  periods = [
    {
      id: 100,
      text: 'І семестр'
    },
    {
      id: 9,
      text: 'Вересень'
    },
    {
      id: 10,
      text: 'Жовтень'
    },
    {
      id: 11,
      text: 'Листопад'
    },
    {
      id: 12,
      text: 'Грудень'
    },
    {
      id: 200,
      text: 'ІІ семестр'
    },
    {
      id: 1,
      text: 'Січень'
    },
    {
      id: 2,
      text: 'Лютий'
    },
    {
      id: 3,
      text: 'Березень'
    },
    {
      id: 4,
      text: 'Квітень'
    },
    {
      id: 5,
      text: 'Травень'
    },
  ];
  hasKinderGarden = false;
  kinderGardenLink = '';

  constructor(public ajaxService: AjaxService,
              public rootService: RootService) {
    this.rootService.initialize$
      .pipe(
        filter((isReady) => {
          return isReady;
        })
      ).subscribe(() => {
      this.getAllClasses().subscribe((classes) => {
        const kinderGarden = classes.find(classItem => classItem.name.toLowerCase() === 'садочок');
        this.hasKinderGarden = !!kinderGarden;

        if (kinderGarden) {
          this.kinderGardenLink = `/administration/classes/${kinderGarden.id}`;
        }
      });
    });
  }

  getSectionPath(path) {
    const section = `${this.rootService.role.getSectionPath()}/classes`;
    return `${section}/${path}`;
  }

  getAllClasses(filter_grade_name = null) {
    return this.ajaxService.get('/student/classes/', {with_teachers: true, filter_grade_name});
  }

  getAllClassesWithoutTeachers() {
    return this.ajaxService.get('/student/classes/');
  }

  getClassById(classId, withStudents = false, withTeachers = false) {
    const params: any = {};
    if (withStudents) {
      params['with_students'] = true;
    }

    if (withTeachers) {
      params.with_teachers = true;
    }
    
    return this.ajaxService.get('/student/classes/' + classId + '/', params)
      .pipe(tap(class_data => {
        this.class = class_data;
        this.updateStudentClassList$.next(this.class);
      }));
  }

  getClassesGroups(classId: string) {
    return this.ajaxService.get('/student/class-group/', {filter_class: classId});
  }

  getAllTeachers() {
    return this.ajaxService.get('/user/all/teacher/');
  }

  getStudentsWithoutClass() {
    return this.ajaxService.get('/user/all/student/name-list/?filter_no_class=true');
  }

  getAllStudents() {
    return this.ajaxService.get('/user/all/student/');
  }

  addNewClass(data) {
    return this.ajaxService.post('/student/classes/', data);
  }

  getAvailableGrades() {
    return this.ajaxService.get('/school/grade/');
  }

  editClassGeneralInfo(classData: ClassModel, classId: string) {
    return this.ajaxService.put('/student/classes/' + classId + '/', classData);
  }

  addStudentAbsent(data) {
    return this.ajaxService.post('/student/absents/planning/', data);
  }

  editStudentAbsent(data, absentId) {
    return this.ajaxService.put(`/student/absents/planning/${absentId}/`, data);
  }

  deleteStudentAbsent(absentId) {
    return this.ajaxService.delete(`/student/absents/planning/${absentId}/`);
  }

  deleteClass(classId: string) {
    return this.ajaxService.delete('/student/classes/' + classId + '/');
  }

  updateClassStudentList(studentsList: IClassStudentsList) {
    return this.ajaxService.post('/student/class-user/', studentsList);
  }

  getStudentByUuid(uuid: string) {
    return this.ajaxService.get('/user/account/' + uuid + '/');
  }

  migrateStudentToAnotherClass(migrateData) {
    return this.ajaxService.put('/student/class-user/', migrateData);
  }

  dropStudentOutOfSchool(studentData) {
    return this.ajaxService.delete('/student/class-user/', studentData);
  }

  getAllTeachersListUrl() {
    return this.ajaxService.get('/user/all/teacher/name-list/');
  }

  deleteGroup(groupId: string) {
    return this.ajaxService.delete('/student/class-group/' + groupId + '/');
  }

  editGroup(groupId: string, groupData) {
    return this.ajaxService.put('/student/class-group/' + groupId + '/', groupData);
  }

  getClassLessons(filter_class: string) {
    return this.ajaxService.get('/student/class-lesson/', {filter_class});
  }

  getClassLesson(classId, lessonId) {
    return this.ajaxService.get(`/student/class-lesson/?filter_class=${classId}&filter_lesson=${lessonId}`);
  }

  getAllLessonsBySubject(lessonId, class_id, period_id: string | number, class_group = null) {
    if (class_group) {
      if (1 <= +period_id && +period_id <= 12) {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {
          filter_month: period_id,
          class: class_id,
          class_group
        });
      } else if (+period_id === 100) {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {
          filter_semester: 1,
          class: class_id,
          class_group
        });
      } else if (+period_id === 200) {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {
          filter_semester: 2,
          class: class_id,
          class_group
        });
      } else {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {class: class_id, class_group});
      }
    } else {
      if (1 <= +period_id && +period_id <= 12) {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {
          filter_month: period_id,
          class: class_id
        });
      } else if (+period_id === 100) {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {
          filter_semester: 1,
          class: class_id
        });
      } else if (+period_id === 200) {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {
          filter_semester: 2,
          class: class_id
        });
      } else {
        return this.ajaxService.get('/journal/lesson-list/' + lessonId + '/', {class: class_id});
      }
    }
  }

  getJournalDataByScheduleID(schedule_id) {
    return this.ajaxService.get('/journal/lesson-details/' + schedule_id + '/');
  }

  getStudentScheduleLessonData(student_uuid, schedule_id) {
    return this.ajaxService.get('/schedule/for-user/' + student_uuid + '/' + schedule_id + '/');
  }

  updateStudentAttendance(attendanceInfo) {
    return this.ajaxService.put('/journal/attendance/', attendanceInfo);
  }

  getScoreTypeByGrade(grade: number) {
    return this.ajaxService.get('/journal/score-type/', {filter_grade: grade, p: 1});
  }

  addCurrentLessonTheme(data, scheduleId) {
    return this.ajaxService.post(`/teacher/lesson-planning-theme/for-schedule/${scheduleId}/`, data);
  }

  updateCurrentLessonTheme(data, themeId) {
    return this.ajaxService.put(`/teacher/lesson-planning-theme/for-theme/${themeId}/`, data);
  }

  addNewScoreType(scoreInfo) {
    return this.ajaxService.post('/journal/score-type/', scoreInfo);
  }

  addStudentScore(scoreData) {
    return this.ajaxService.post('/journal/score/', scoreData);
  }

  editStudentScore(scoreData) {
    return this.ajaxService.put('/journal/score/' + scoreData.id + '/', scoreData, false);
  }

  deleteStudentScore(scoreId) {
    return this.ajaxService.delete('/journal/score/' + scoreId + '/');
  }

  createScoreColumn(score_type) {
    return this.ajaxService.post('/journal/score-column/', score_type);
  }

  deleteScoreColumn(score_type) {
    return this.ajaxService.delete('/journal/score-column/', score_type);
  }

  getAllStudentLessons(student_uuid) {
    return this.ajaxService.get('/student/lesson-list/' + student_uuid + '/');
  }

  getAllStudentScores(student_uuid, filter_month = null, filter_semester = null, filter_type = null) {
    return this.ajaxService.get('/journal/student-scores/' + student_uuid + '/', {
      filter_month,
      filter_semester,
      filter_type
    });
  }

  getClassRewards(class_id, lesson_id) {
    return this.ajaxService.get(`/student/reward-class/${class_id}/${lesson_id}/`);
  }

  getClassGroupRewards(group_id) {
    return this.ajaxService.get(`/student/reward-class-group/${group_id}/`);
  }

  getStudentRewards(uuid) {
    return this.ajaxService.get(`/student/reward/${uuid}/`);
  }

  updateStudentRewards(rewards_data, student_uuid) {
    return this.ajaxService.put(`/student/reward/${student_uuid}/`, rewards_data);
  }

  getClassStats(classId) {
    return this.ajaxService.get(`/statistic/class/${classId}/`);
  }

  getClassScoreStats(classId) {
    return this.ajaxService.get(`/statistic/class/${classId}/scores/`);
  }

  getClassAttendanceStats(classId) {
    return this.ajaxService.get(`/statistic/class/${classId}/attendance/`);
  }

  getClassRewardStats(classId) {
    return this.ajaxService.get(`/statistic/class/${classId}/rewards/`);
  }

  printJournal(classId) {
    return this.ajaxService.get(`/journal/print/${classId}/`);
  }

  getStudentGradeList(student_uuid) {
    return this.ajaxService.get('/journal/student-grade-list', {student_uuid});
  }

  getStudentsGradeList(class_id, with_students = true) {
    return this.ajaxService.get('/journal/students-grade-list', {class_id, with_students});
  }

  journalFileSize(classId) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/api/journal/print/check/${classId}/`, false);
    xhr.send();

    if (xhr.status !== 404) {
      const results = JSON.parse(xhr.response);
      return results.size;
    }

    return 0;
  }

  getStudentsUniform(classId) {
    return this.ajaxService.get(`/student/uniform/${classId}/`);
  }

  saveStudentsUniform(uuid, sizeObject) {
    return this.ajaxService.put(`/student/uniform/${uuid}/save/`, sizeObject);
  }

  getActiveStudentControlWorks(uuid, page?, just_next?) {
    return this.ajaxService.get(`/student/controlwork-tasks/${uuid}/active/`, {page, just_next});
  }

  getUnfinishedStudentControlWork(uuid, page) {
    return this.ajaxService.get(`/student/controlwork-tasks/${uuid}/unfinished/`, {page});
  }

  getEstimatedStudentControlWork(uuid, page, search) {
    return this.ajaxService.get(`/student/controlwork-tasks/${uuid}/with-score/`, {page, search});
  }

}
