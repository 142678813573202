import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef, HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputFieldComponent),
  multi: true
};

@Component({
  selector: 'input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss', '../../../style/modules-shared.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class InputFieldComponent implements OnInit, AfterViewInit {

  @Input() disabled = false;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean | number = false;
  @Input() state = true;
  @Input() videoIsValid = true;
  @Input() inputMask = '';
  @Input() maskPrefix = '';
  @Input() showMaskTyped = false;
  @Input() theme: string;
  @Input() classes: string;
  @Input() autocomplete = false;
  @Input() type: 'text' | 'password' | 'email' | 'date' | 'number' | 'textarea' = 'text';
  @Input() maxLength = 524288;
  @Input() invalidMessage: string;
  @Input() maxRows = 10;
  @Input() minRows = 1;
  @Input() dateMask: boolean | number;
  @Input() placeHolderCharacter = '';
  @Input() maskPattern: object;
  @Input() minDate: {
    year: number, month: number, day: number
  };
  @Input() maxDate: {
    year: number, month: number, day: number
  };
  @Input() autoCompleteItems: { text: string, id: string }[] = [];
  @Input() suffix: string;
  @Input() end_icon: string;
  @Input() clear_icon: string;
  @Input() remove_icon: string;
  @Input() send_icon: string;
  @Input() photo: boolean;
  @Input() removeBtn: boolean;
  @Input() min: number;
  @Input() max: number;

  @ViewChild('wrap') wrap: ElementRef;
  @ViewChild('input') input: ElementRef;

  @Output() onChangeValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChoseStudent: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearInputValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() addComment: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.opened') isOpenDatePicker = false;
  datePreview = '';
  searchText = '';
  chosenVariant: any;
  isFocused: boolean;

  value: string | NgbDate = '';
  isShowAutoComplete = false;


  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.input) {
      (this.input.nativeElement as HTMLInputElement).value = this.value as string;
    }
  }

  @HostListener('click')
  click() {
    this.onTouched();
  }

  onChange = (item) => {
  };

  onTouched = () => {

  };

  writeValue(v): void {
    if (v instanceof NgbDate) {
      this.value = v;
    } else {
      this.value = typeof v === 'object' && v !== null ? v.target.value : v;
      this.searchText = this.value as string;
    }

    if (this.theme === 'phone') {
      this.performPhone();
    }

    if (this.theme === 'date') {
      this.performDate();
    }
    this.onChange(this.value);
    this.onChangeValue.emit(this.value);
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.wrap.nativeElement.contains(targetElement);

    if (!clickedInside) {

      if (this.theme === 'date') {
        this.isOpenDatePicker = false;
      }

      this.isShowAutoComplete = false;
    }
  }

  registerOnChange(fn: (item) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  getPhoneMaskState() {
    if (!this.inputMask && this.theme !== 'phone') {
      return '';
    }
    return !this.value ? 'inactive' : 'active';
  }

  performPhone() {
    if ((this.value === `${this.maskPrefix}–– ) ––– –– ––`)) {
      this.value = null;
    }
  }

  performDate() {
    const date: NgbDate = this.value as NgbDate;

    if (this.value && date.year && date.month && date.day) {
      this.datePreview = moment(new Date(`${date.year}/${date.month}/${date.day}`)).format('DD.MM.YYYY');
    } else {
      this.datePreview = '';
    }
  }

  onChooseDate(e: NgbDate) {
    this.isOpenDatePicker = false;
    this.writeValue(e);
  }

  chooseSuggestion(item) {
    this.writeValue(item.text);
    this.chosenVariant = item;
    this.isShowAutoComplete = false;
    if (item.id) {
      this.onChoseStudent.emit(this.chosenVariant);
    }

    this.value = '';
  }

  clearDeleteInput(action) {
    this.clearInputValue.emit(action);
  }

  addNewComment() {
    if (this.send_icon.includes('active')) {
      this.addComment.emit();
    } else if (this.placeholder) {
      alert(this.placeholder);
    }
  }

  onInputFocus() {
    this.isShowAutoComplete = true;
    this.onFocus.emit();
    this.isFocused = true;
  }

  onInputBlur() {
    this.onBlur.emit('blur');
    this.isFocused = false;
  }

  isHideAutoComplete(filteredVariants) {
    if (!this.autocomplete) {
      return true;
    }
    return !this.isShowAutoComplete || filteredVariants.length === 1 && filteredVariants[0].text === this.value;
  }

  resetData() {
    this.writeValue(null);
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const key = event.key;

    if (this.type === 'text') {
      if (this.value && !(this.value instanceof NgbDate)) {
        this.value = (String(this.value)).replace(/(\r\n|\n|\r)/gm, '');
      }

      if (key && key.includes('Enter') && !event.shiftKey && this.value) {
        this.addComment.emit();
      }
    } else if (this.type === 'textarea') {
      if (key && key.includes('Enter') && !event.shiftKey && this.value) {
        this.addComment.emit();
      }
    }

  }

}
