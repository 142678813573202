import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {concatMap, debounceTime, first, takeWhile, tap} from 'rxjs/operators';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {Observable, Observer, of, zip} from 'rxjs';
import {SchoolSettingsModel} from '../../../models/school-management/school-settings.model';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HelperService} from '../../../services/helper.service';
import * as _ from 'lodash';
import {RootService} from '../../../services/root.service';
import {UserModel} from '../../../models/user.model';
import {SettingsContactModel} from '../../../models/school-management/settings-contact.model';
import {UsersService} from '../../../../functional-modules/users/users.service';
import {AuthService} from '../../../services/auth.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'system-info-popup',
  templateUrl: './system-info-popup.component.html',
  styleUrls: ['./system-info-popup.component.scss', '../../../style/popups-shared.scss']
})
export class SystemInfoPopupComponent implements AfterViewInit, OnDestroy {

  work = true;
  loaded = false;
  step = 1;
  modalData: any = {};
  facebookPattern = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/;
  workHoursPattern = /([01][0-9]|2[0-3]):([0-5][0-9])\b/;
  schoolInfo: SchoolSettingsModel;
  wasSubmitted = false;
  files: any = {};
  formErrors: any = {
    name: HelperService.formErrors.requiredField,
    type: HelperService.formErrors.requiredField,
    start_work_week: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    end_work_week: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    start_work_sat: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    end_work_sat: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    start_work_sun: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    end_work_sun: HelperService.getErrMessage(HelperService.formErrors.requiredField, ' (приклад - гг:хх)'),
    work_days: 'відсутні данні про робочі дні',
    accreditation: HelperService.formErrors.requiredField,
    website_url: HelperService.formErrors.incorrectFormat,
    facebook_url: HelperService.formErrors.incorrectFormat,
    // inner_currency: HelperService.formErrors.requiredField
  };
  contactsFormError: any = {
    region_id: HelperService.formErrors.requiredField,
    city: HelperService.formErrors.requiredField,
    email: HelperService.formErrors.incorrectFormat,
    zip: HelperService.getErrMessage(HelperService.formErrors.incorrectFormat, ' (приклад - 00000)'),
    phone: HelperService.getErrMessage(HelperService.formErrors.incorrectFormat, `<br> (приклад - ${HelperService.phoneSample})`),
    first_name: HelperService.formErrors.requiredField,
    last_name: HelperService.formErrors.requiredField,
    gender: HelperService.formErrors.requiredField,
    principal_email: HelperService.formErrors.incorrectFormat,
    vice_principal_email: HelperService.formErrors.incorrectFormat,
  };
  schoolInfoForm: FormGroup;
  contactsFormGroup: FormGroup;
  personsFormGroup: {
    principal: FormGroup,
    vice_principal: FormGroup
  };
  persons: {
    principal: UserModel,
    vice_principal: UserModel
  };
  savedPersons: {
    principal: UserModel,
    vice_principal: UserModel
  };
  regions = [];
  settingsContactModel: SettingsContactModel;
  zipMask = '00000';
  currentDate: NgbDate;
  minBirthDate;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public rootService: RootService,
              public usersService: UsersService,
              public changeDetectorRef: ChangeDetectorRef,
              public authService: AuthService,
              public fb: FormBuilder) {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('system-info-popup')
      .onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.initForm();
      });
  }

  ngOnDestroy() {
    this.work = false;
  }

  initForm() {
    const currDate = new Date();
    this.modalData = this.ngxSmartModalService.getModal('system-info-popup').getData();
    this.wasSubmitted = false;
    this.step = this.modalData.step;
    this.loaded = false;
    this.currentDate = new NgbDate(currDate.getFullYear(), currDate.getMonth() + 1, currDate.getDate());
    this.minBirthDate = new NgbDate(1900, 1, 1);
    this.initStep(this.step).subscribe(() => {
      this.loaded = true;
    });
  }

  moveStep(direction: 'prev' | 'next') {
    if (this.loaded) {
      if (direction === 'next') {
        this.wasSubmitted = true;
        this.loaded = false;
        this.onLeaveStep(this.step).subscribe((isValid) => {

          if (isValid && this.step <= 3) {
            this.wasSubmitted = false;

            this.save(this.step).subscribe(() => {
              this.step++;
              this.initStep(this.step).subscribe(() => {
                this.loaded = true;
              });
            });
          } else if (isValid && this.step === 4) {
            this.save(this.step).subscribe(() => {
              this.cancel();
              this.modalData.onSave(this.step);
            });
          } else {
            this.loaded = true;
          }
        });
      } else if (direction === 'prev') {
        this.wasSubmitted = false;
        this.loaded = false;
        if (this.step === 1) {
          this.cancel();
        } else {
          this.step--;
          this.initStep(this.step).subscribe(() => {
            this.loaded = true;
          });
        }
      }
    }
  }

  onLeaveStep(step) {
    let isValid = true;

    if (step === 1 || step === 2) {
      isValid = this.schoolInfoForm.valid;
    } else if (step === 3) {
      isValid = this.contactsFormGroup.valid;
    } else if (step === 4) {
      isValid = this.personsFormGroup.principal.valid && this.personsFormGroup.vice_principal.valid;
    }

    return of(isValid);
  }

  initStep(step) {
    let req = of(null);

    if (step === 1) {
      req = this.managementService.getSchoolSettings().pipe(tap((data) => {
        this.schoolInfo = new SchoolSettingsModel(data);
        this.files.photo_url = this.schoolInfo.photo_url;
        this.files.logo_url = this.schoolInfo.logo_url;

        this.schoolInfoForm = this.fb.group({
          name: [this.schoolInfo.name, Validators.compose([Validators.required])],
          description: [this.schoolInfo.description],
          logo_url: [this.schoolInfo.logo_url],
          photo_url: [this.schoolInfo.photo_url],
          type: [this.schoolInfo.type, Validators.required],
          accreditation: [this.schoolInfo.accreditation, Validators.required],

          start_work_week: [this.schoolInfo.start_work_week, Validators.compose([Validators.required, Validators.pattern(this.workHoursPattern), this.validateTimeWork('weekdays')])],
          end_work_week: [this.schoolInfo.end_work_week, Validators.compose([Validators.required, Validators.pattern(this.workHoursPattern), this.validateTimeWork('weekdays')])],
          start_work_sat: [this.schoolInfo.start_work_sat, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('saturday')])],
          end_work_sat: [this.schoolInfo.end_work_sat, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('saturday')])],
          start_work_sun: [this.schoolInfo.start_work_sun, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('sunday')])],
          end_work_sun: [this.schoolInfo.end_work_sun, Validators.compose([Validators.pattern(this.workHoursPattern), this.validateTimeWork('sunday')])],

          work_days: [this.schoolInfo.work_days, Validators.compose([Validators.required])],
          // inner_currency: [this.schoolInfo.inner_currency, this.isRequiredCurrency()],
          facebook_url: [this.schoolInfo.facebook_url, Validators.pattern(this.facebookPattern)],
          website_url: [this.schoolInfo.website_url, Validators.pattern(HelperService.linkPattern)]
        });

        // this.schoolInfoForm.get('is_currency').valueChanges.subscribe((val) => {
        //   this.schoolInfoForm.get('inner_currency').setValue('');
        //   this.schoolInfoForm.get('inner_currency').updateValueAndValidity();
        //   this.changeDetectorRef.detectChanges();
        // });

        this.schoolInfoForm.valueChanges
          .pipe(debounceTime(200))
          .subscribe((rez) => {
            _.forOwn(rez, (val, key) => {
              this.schoolInfo[key] = val;
            });
            this.changeDetectorRef.detectChanges();
          });
      }));


    } else if (step === 2) {
      req = zip(
        this.managementService.getSchoolContacts(),
        this.loadRegions()
      ).pipe(tap(([data]) => {
        this.persons = {
          principal: data.principal ? new UserModel(_.cloneDeep(data.principal)) : new UserModel(),
          vice_principal: data.vice_principal ? new UserModel(_.cloneDeep(data.vice_principal)) : new UserModel(),
        };

        this.savedPersons = {
          principal: data.principal ? new UserModel(_.cloneDeep(data.principal)) : new UserModel(),
          vice_principal: data.vice_principal ? new UserModel(_.cloneDeep(data.vice_principal)) : new UserModel(),
        };

        this.settingsContactModel = new SettingsContactModel(_.omit(_.cloneDeep(data), ['principal', 'vice_principal']));

        this.contactsFormGroup = this.fb.group({
          region_id: [this.settingsContactModel.region_id, Validators.required],
          address: [this.settingsContactModel.address],
          city: [this.settingsContactModel.city, Validators.required],
          zip: [this.settingsContactModel.zip, Validators.minLength(5)],
          email: [
            this.settingsContactModel.email,
            Validators.pattern(HelperService.emailPattern),
          ],
          phone: [this.settingsContactModel.phone, Validators.compose([Validators.minLength(21), Validators.pattern(HelperService.phonePattern)])],
        });

        this.personsFormGroup = {
          principal: this.fb.group({
            photo: [this.persons.principal ? this.persons.principal.photo : ''],
            first_name: [this.persons.principal ? this.persons.principal.first_name : '', Validators.required],
            last_name: [this.persons.principal ? this.persons.principal.last_name : '', Validators.required],
            surname: [this.persons.principal ? this.persons.principal.surname : ''],
            birthday: [this.persons.principal ? HelperService.getNgbDateFormat(this.persons.principal.birthday) : ''],
            gender: [this.persons.principal.gender || '1', Validators.required],
            email: [
              this.persons.principal ? this.persons.principal.email : '',
              Validators.pattern(HelperService.emailPattern),
              this.getEmailValidation.bind(this, this.persons.principal ? this.persons.principal.email : '')
            ],
            phone: [
              this.persons.principal ? this.persons.principal.phone : '',
              Validators.compose([Validators.minLength(21), Validators.pattern(HelperService.phonePattern)]),
              this.getPhoneValidation.bind(this, this.persons.principal ? this.persons.principal.phone : '')
            ]
          }),
          vice_principal: this.fb.group({
            photo: [this.persons.vice_principal ? this.persons.vice_principal.photo : null],
            first_name: [this.persons.vice_principal ? this.persons.vice_principal.first_name : '', Validators.required],
            last_name: [this.persons.vice_principal ? this.persons.vice_principal.last_name : '', Validators.required],
            surname: [this.persons.vice_principal ? this.persons.vice_principal.surname : ''],
            birthday: [this.persons.vice_principal ? HelperService.getNgbDateFormat(this.persons.vice_principal.birthday) : ''],
            gender: [this.persons.vice_principal.gender || '1', Validators.required],
            email: [
              this.persons.vice_principal ? this.persons.vice_principal.email : '',
              Validators.pattern(HelperService.emailPattern),
              this.getEmailValidation.bind(this, this.persons.vice_principal ? this.persons.vice_principal.email : '')
            ],
            phone: [this.persons.vice_principal ? this.persons.vice_principal.phone : '',
              Validators.compose([Validators.minLength(21), Validators.pattern(HelperService.phonePattern)]),
              this.getPhoneValidation.bind(this, this.persons.vice_principal ? this.persons.vice_principal.phone : '')
            ]
          })
        };

        this.contactsFormGroup.get('email').valueChanges.subscribe((email) => {
          const allValues = [this.personsFormGroup.principal.get('email').value, this.personsFormGroup.vice_principal.get('email').value];

          if (allValues.includes(email)) {
            this.contactsFormGroup.get('email').setValue('');
          }
        });

        this.contactsFormGroup.valueChanges
          .pipe(debounceTime(200))
          .subscribe((rez) => {
            _.forOwn(rez, (val, key) => {
              this.settingsContactModel[key] = val;
            });
          });

        this.personsFormGroup.principal.valueChanges
          .pipe(debounceTime(200))
          .subscribe((rez) => {
            _.forOwn(rez, (val, key) => {
              this.persons.principal[key] = val;
            });
          });

        this.personsFormGroup.vice_principal.valueChanges
          .pipe(debounceTime(200))
          .subscribe((rez) => {
            _.forOwn(rez, (val, key) => {
              this.persons.vice_principal[key] = val;
            });
          });
      }));
    }
    return req;
  }

  save(step) {
    const requests = [];

    if (step === 1) {
      this.schoolInfo.photo_url = this.files.photo_url;
      this.schoolInfo.logo_url = this.files.logo_url;

      const data: any = _.pick(this.schoolInfo, [
        'name',
        'description',
        'logo_url',
        'photo_url',
        'type',
        'start_work_week',
        'end_work_week',
        'start_work_sat',
        'end_work_sat',
        'start_work_sun',
        'end_work_sun',
        'work_days',
        'accreditation',
        'facebook_url',
        // 'inner_currency',
        'website_url'
      ]);

      if (_.isString(data.photo_url) && data.photo_url.length) {
        delete data.photo_url;
      } else if (data.photo_url === null) {
        data.photo_url = '';
      }

      if (_.isString(data.logo_url) && data.logo_url.length) {
        delete data.logo_url;
      } else if (data.logo_url === null) {
        data.logo_url = '';
      }

      requests.push(this.managementService.updateSchoolSettings(data));
    } else if (step === 2 || step === 3 || step === 4) {
      if (step === 2) {
        this.schoolInfo.photo_url = this.files.photo_url;

        const data: any = _.pick(this.schoolInfo, [
          'name',
          'description',
          'logo_url',
          'photo_url',
          'type',
          'start_work_week',
          'end_work_week',
          'start_work_sat',
          'end_work_sat',
          'start_work_sun',
          'end_work_sun',
          'work_days',
          'accreditation',
          'facebook_url',
          // 'inner_currency',
          'website_url'
        ]);

        if (_.isString(data.photo_url) && data.photo_url.length) {
          delete data.photo_url;
        } else if (data.photo_url === null) {
          data.photo_url = '';
        }

        if (_.isString(data.logo_url) && data.logo_url.length) {
          delete data.logo_url;
        } else if (data.logo_url === null) {
          data.logo_url = '';
        }

        requests.push(this.managementService.updateSchoolSettings(data));
      } else if (step === 3) {
        const contactsInfo = _.pick(this.settingsContactModel, [
          'region_id',
          'city',
          'address',
          'zip',
          'phone',
          'email'
        ]);

        requests.push(this.managementService.updateSchoolContacts(contactsInfo));
      } else if (step === 4) {
        const principal: any = _.cloneDeep(this.persons.principal);
        const vice_principal: any = _.cloneDeep(this.persons.vice_principal);

        principal.photo = this.files.principal;
        principal.role_id = _.find(this.rootService.allRoles, ['title', 'Директор']).id;
        vice_principal.photo = this.files.vice_principal;
        vice_principal.role_id = _.find(this.rootService.allRoles, ['title', 'Заступник директора']).id;

        if (this.persons.principal.birthday && this.persons.principal.birthday instanceof NgbDate) {
          principal.birthday = HelperService.getDateFromNgbDate(this.persons.principal.birthday as any);
        }

        if (this.persons.vice_principal.birthday && this.persons.vice_principal.birthday instanceof NgbDate) {
          vice_principal.birthday = HelperService.getDateFromNgbDate(this.persons.vice_principal.birthday as any);
        }

        if (!this.savedPersons.principal.uuid) {
          requests.push(
            this.usersService.addUserAccount(principal).pipe(concatMap((rez: any) => {
              return this.usersService.assignStuffRile({
                uuid: rez.uuid,
                role_id: _.find(this.rootService.allRoles, ['name', 'director']).id
              });
            }))
          );
        } else {
          requests.push(this.usersService.updateUserAccount(principal));
        }

        if (!this.savedPersons.vice_principal.uuid) {
          requests.push(
            this.usersService.addUserAccount(vice_principal).pipe(concatMap((rez: any) => {
              return this.usersService.assignStuffRile({
                uuid: rez.uuid,
                role_id: _.find(this.rootService.allRoles, ['name', 'assistant_director']).id
              });
            }))
          );
        } else {
          requests.push(this.usersService.updateUserAccount(vice_principal));
        }
      }
    }

    return zip(...requests);
  }

  cancel() {
    this.ngxSmartModalService.getModal('system-info-popup').close();
  }

  getFieldState(field) {
    return this.wasSubmitted ? this.schoolInfoForm.get(field).valid : null;
  }

  getContactsFieldState(field, group?) {
    if (!this.wasSubmitted) {
      return null;
    }

    return group ? this.personsFormGroup[group].get(field).valid : this.contactsFormGroup.get(field).valid;
  }

  hasDays(day) {
    return (this.schoolInfoForm.get('work_days').value || '').split(',').findIndex((dayIndex) => {
      return dayIndex === day;
    }) !== -1;
  }

  toggleDay(day) {
    const days = (this.schoolInfoForm.get('work_days').value || '').split(',').filter(i => !!i);

    if (days.includes(day)) {
      _.remove(days, item => day === item);
    } else {
      days.push(day);
    }

    if (days.some(d => (+d) < 6)) {
      this.schoolInfoForm.get('work_days').setValue(days.length ? days.join(',') : '');
      this.changeDetectorRef.detectChanges();
    }
  }

  onLoadPhoto(type: 'vice_principal' | 'principal' | 'logo_url' | 'photo_url', data) {
    const {url, file} = data;

    this.files[type] = file;

    if (type === 'logo_url' || type === 'photo_url') {
      this.schoolInfoForm.get(type).setValue(url);
    } else if (type === 'vice_principal' || type === 'principal') {
      this.personsFormGroup[type].get('photo').setValue(url);
    }
  }

  removePhotoUrl() {
    this.files.photo_url = null;
    this.schoolInfoForm.get('photo_url').setValue('');
  }

  checkWorkTime() {
    const data = this.schoolInfoForm.value;

    if (data.start_work_week && data.end_work_week) {
      if (parseInt(data.start_work_week as string, 10) >= parseInt(data.end_work_week as string, 10)) {
        this.schoolInfoForm.get('end_work_week').reset('');
      }
    }

    if (data.start_work_sat && data.end_work_sat) {
      if (parseInt(data.start_work_sat as string, 10) >= parseInt(data.end_work_sat as string, 10)) {
        this.schoolInfoForm.get('end_work_sat').reset('');
      }
    }

    if (data.start_work_sun && data.end_work_sun) {
      if (parseInt(data.start_work_sun as string, 10) >= parseInt(data.end_work_sun as string, 10)) {
        this.schoolInfoForm.get('end_work_sun').reset('');
      }
    }

    if (!this.schoolInfo.hasWorkDays('weekdays')) {

      if (this.schoolInfoForm.get('start_work_week').value || this.schoolInfoForm.get('end_work_week').value) {
        this.schoolInfoForm.get('start_work_week').reset('');
        this.schoolInfoForm.get('end_work_week').reset('');
      }

    }

    if (!this.schoolInfo.hasWorkDays('saturday')) {
      if (this.schoolInfoForm.get('start_work_sat').value || this.schoolInfoForm.get('end_work_sat').value) {
        this.schoolInfoForm.get('start_work_sat').reset('');
        this.schoolInfoForm.get('end_work_sat').reset('');
      }
    }

    if (!this.schoolInfo.hasWorkDays('sunday')) {
      if (this.schoolInfoForm.get('start_work_sun').value || this.schoolInfoForm.get('end_work_sun').value) {
        this.schoolInfoForm.get('start_work_sun').reset('');
        this.schoolInfoForm.get('end_work_sun').reset('');
      }
    }
  }

  validateTimeWork(workType) {
    return (control: AbstractControl) => {

      if ((!control.value || control.value.length !== 5) && this.schoolInfo.hasWorkDays(workType)) {
        return {incorrectTime: true};
      }

      return null;
    };
  }

  // isRequiredCurrency() {
  //   return (control: AbstractControl) => {
  //     if (!this.schoolInfoForm) {
  //       return null;
  //     }
  //
  //     if (this.schoolInfoForm.get('is_inner_currency').value && (!control.value || !control.value.length)) {
  //       return {incorrectTime: true};
  //     }
  //
  //     return null;
  //   };
  // }

  loadRegions() {
    return this.managementService.getRegions().pipe(tap((data) => {
      this.regions = data.map(({id, name}) => {
        return {
          id: String(id),
          text: name
        };
      });
    }));
  }

  selectSchoolPhoto(target, event) {
    const file = target.files[0];
    if (file && HelperService.isAllowedSize(file, 5120)) {
      HelperService.getDataImageFromFile(file as File).then((url: string) => {
        this.files.photo_url = file;
        this.schoolInfoForm.get('photo_url').setValue(url);
      });
    }
    event.preventDefault();
    event.stopPropagation();
  }

  getEmailValidation(excludedEmail, control: FormControl) {
    if (!control.value) {
      return of(null).pipe(first());
    }

    return new Observable((observer: Observer<any>) => {
      if (excludedEmail && control.value === excludedEmail) {
        observer.next(null);
      } else {
        this.usersService.getEmailStatus(control.value).subscribe(() => {
          observer.next(null);
        }, () => {
          observer.next({emailTaken: true});
        });
      }

    }).pipe(first());
  }

  getPhoneValidation(excludedPhone, control: FormControl) {
    const val = control.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

    if (!val) {
      return of(null).pipe(first());
    }

    return new Observable((observer: Observer<any>) => {
      if (excludedPhone && HelperService.formatPhone(val) === excludedPhone) {
        observer.next(null);
      } else {
        this.usersService.getPhoneStatus(val).subscribe(() => {
          observer.next(null);
        }, () => {
          observer.next({phoneTaken: true});
        });
      }
    }).pipe(first());
  }

  onEmailChange(formType) {
    const allControls = {
      contacts: this.contactsFormGroup.get('email'),
      principal: this.personsFormGroup.principal.get('email'),
      vice_principal: this.personsFormGroup.vice_principal.get('email')
    };
    const controlVal = allControls[formType].value;

    _.forOwn(allControls, (control, fieldName) => {
      if (formType !== fieldName) {
        if (control.value === controlVal) {
          allControls[formType].setValue('');
        }
      }
    });
  }

  onPhoneChange(formType) {
    const allControls = {
      contacts: this.contactsFormGroup.get('phone'),
      principal: this.personsFormGroup.principal.get('phone'),
      vice_principal: this.personsFormGroup.vice_principal.get('phone')
    };
    const controlVal = (allControls[formType].value || '').replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

    _.forOwn(allControls, (control, fieldName) => {
      if (formType !== fieldName) {
        const val = control.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

        if (val === controlVal) {
          allControls[formType].setValue('');
        }
      }
    });
  }

  get stepTitle() {
    switch (this.step) {
      case 1:
        return 'Загальна інформація';
      case 2:
        return 'Школа в інтернеті';
      case 3:
        return 'Контактна інформація';
      case 4:
        return 'Адміністрація школи';
    }
  }

}
