import { Injectable } from '@angular/core';
import { RootService } from '../+shared/services/root.service';

@Injectable({
    providedIn: 'root'
})
export class PupilsService {

   constructor(public rootService: RootService) {
   }

   getSectionPath(path) {
     const section = `${this.rootService.role.getSectionPath()}`;
     return `${section}/${path}`;
   }
}
