import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ManagementService } from '../../../functional-modules/management/management.service';
import { FormBuilder } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'deleting-popup',
  templateUrl: './deleting-popup.component.html',
  styleUrls: ['./deleting-popup.component.scss', '../../style/popups-shared.scss']
})
export class DeletingPopupComponent implements AfterViewInit {

  modalData: any = {};
  work = true;
  deleting = false;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public fb: FormBuilder) {

  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('deleting-popup')
      .onOpen
      .pipe(takeWhile(() => {
        return this.work;
      })).subscribe(() => {
      this.deleting = false;
      this.modalData = this.ngxSmartModalService.getModal('deleting-popup').getData();
    });
  }

  chooseVariant(variant) {
    if (this.modalData.deleting$ instanceof Subject) {
      (this.modalData.deleting$ as Subject<void>).subscribe(() => {
        this.cancel();
      });
      this.modalData.onResult(variant);

      if (variant) {
        this.deleting = true;
      }
    } else {
      this.modalData.onResult(variant);
      this.cancel();
    }
  }

  cancel() {
    this.ngxSmartModalService.getModal('deleting-popup').close();
  }


}


