import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'youtubeVideoId'
})
export class YoutubeVideoIdPipe implements PipeTransform {

  transform(link: string, ...args: unknown[]): unknown {

    if (link.includes('https://youtu.be')) {
      return _.last(link.split('?')[0].split('/'));
    } else {
      return link.split('&')[0].split('=')[1];
    }
  }
}
