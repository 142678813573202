import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'studentMark',
})
export class StudentMarkPipe implements PipeTransform {

  transform(studentScores: any, scoreType: any): unknown {
    return [studentScores.find(score => score.score_type === scoreType.id)];
  }
}
