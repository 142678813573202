import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {RootService} from '../services/root.service';

@Injectable()
export class RolePermissionGuard implements CanActivate {
  constructor(public rootService: RootService,
              public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const module = state.url.split('/')[2];

    switch (module) {
      case 'users':
        return this.performAccessCheck('user_view');
      case 'staff':
        return this.performAccessCheck('user_view') && !this.rootService.role.isDoctor();
      case 'management':
        return this.performAccessCheck('school_view');
      case 'library':
        return this.performAccessCheck('library_view');
      case 'kitchen':
        return this.performAccessCheck('kitchen_view');
      case 'finances':
        return this.performAccessCheck('finance_view');
      default:
        return true;
    }
  }


  performAccessCheck(permissionKey) {
    const access = this.rootService.role.hasPermission(permissionKey);

    if (!access) {
      this.router.navigateByUrl(this.rootService.role.getSectionPath());
    }

    return access;
  }
}
