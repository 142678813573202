import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'preloader',
    templateUrl: 'preloader.component.html',
    styleUrls: ['preloader.component.scss']
})
export class PreloaderComponent implements OnInit, OnDestroy {

    @Input() container: [string, string];
    @Input() position: ['top' | 'center' | 'bottom', 'left' | 'center' | 'right'] = ['top', 'center'];
    @Input() delay: number;
    @Input() spinnerColor = '999';
    @Input() bcgColor = 'transparent';
    @Input() size = 1;

    visible = true;
    private timerId: any;

    @HostBinding('class.with-container') private hasContainer = false;

    constructor() {
    }

    ngOnInit() {
        this.visible = !this.delay;
        this.hasContainer = this.container && this.container.length === 2;

        if (this.delay) {
            this.timerId = setTimeout(() => {
                this.visible = true;
            }, this.delay);
        }
    }

    ngOnDestroy(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    }
}
