import {Base} from './base.model';
import * as _ from 'lodash';
import {RoleModel} from './role.model';
import {HelperService} from '../services/helper.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export class UserModel extends Base {

  uuid: string;
  first_name: string;
  last_name: string;
  surname: string;
  birthday: string | NgbDate;
  photo: string;
  roles?: RoleModel[];
  gender: string;
  email: string;
  phone: string;
  updated_password_at: string;
  passport_number: string;
  passport_author: string;
  passport_date: string;
  nationality: string;
  address: string;
  address_reg: string;
  id_number: string;
  tmp_password: string;
  birthdayDateString: string;
  phoneString: string;
  user_role?: any;
  count_new: { messages: number, notifications: number, users: number };
  unreaded_comments_count: number;
  username: string;

  constructor(items?) {
    super();
    if (items && items.roles) {
      super.loadRelations({
        roles: [RoleModel],
      }, items);
    }

    super.loadFields(items);
    super.formatPhone('phone');
    this.birthdayDateString = super.formatDate(this.birthday, 'D MMMM YYYY');
  }

  getHighestRole() {
    return _.chain(this.roles).orderBy(['id'], ['desc']).first().value();
  }

  hasSection(section) {
    return this.roles.some((role) => section === role.getRoleType());
  }

  getWorkerRole() {
    return _.first(this.roles.filter((role: RoleModel) => {
      return role.staff_group && role.staff_group !== '1';
    }));
  }
}
