import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {takeWhile} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../../functional-modules/users/users.service';
import {environment} from '../../../../environments/environment';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'mail-editor-popup',
  templateUrl: './mail-editor-popup.component.html',
  styleUrls: ['./mail-editor-popup.component.scss', '../../style/popups-shared.scss']
})
export class MailEditorPopupComponent implements OnDestroy, AfterViewInit {

  work = true;
  form: FormGroup;
  uuid_list: {
    uuid: string,
    name: string
  }[];
  wasSubmitted = false;
  formError = {
    subject: 'мінімум 5 символів',
    text: 'мінімум 10 символів'
  };
  isExpanded = false;
  loaded = false;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public usersService: UsersService,
              public fb: FormBuilder) {

  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('mail-editor-popup').onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.isExpanded = false;
        this.loaded = false;
        this.form = this.fb.group({
          subject: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
          text: ['', Validators.compose([Validators.required, this.getTextLengthValidation])]
        });
        this.initForm();
      });

    this.ngxSmartModalService.getModal('mail-editor-popup').onClose.pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.form = null;
      });
  }

  ngOnDestroy() {
    this.work = false;
  }

  initForm() {
    this.wasSubmitted = false;
    this.uuid_list = this.ngxSmartModalService.getModal('mail-editor-popup').getData().uuid_list;
    this.form.reset('');
    this.loaded = true;
  }

  cancel() {
    this.ngxSmartModalService.getModal('mail-editor-popup').close();
  }

  getFieldState(field) {
    return this.wasSubmitted ? this.form.get(field).valid : null;
  }

  getTextLengthValidation(control: FormControl) {
    if (!control.value) {
      return {lengthErr: true};
    }
    return control.value.replace(/<\/?[^>]+(>|$)/g, '').length >= 10 ? null : {lengthErr: true};
  }

  removeAddressee(addressee_to_remove) {
    this.uuid_list = this.uuid_list.filter(addressee => addressee.uuid !== addressee_to_remove.uuid);
    if (this.uuid_list.length < 7) {
      this.isExpanded = false;
    }
  }

  expandAddresseeList() {
    this.isExpanded = true;
  }

  send() {
    this.wasSubmitted = true;

    if (this.form.valid) {
      const uuidList = this.uuid_list.map(user => user.uuid).join(',');
      if (!uuidList) {
        this.cancel();
      } else {
        this.usersService.sendEmail({
          text: this.form.value.text,
          subject: this.form.value.subject,
          uuid_list: uuidList
        }).subscribe(() => {
          this.cancel();
        });
      }
    }
  }

  get tinyApiKey() {
    return environment.tinyMceApiKey;
  }
}
