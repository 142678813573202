import {Base} from '../base.model';
import {ParentModel} from './parent.model';

export class PupilModel extends Base {

  id: number;
  name: string;
  uuid: string;
  first_name: string;
  last_name: string;
  surname: string;
  gender: string;
  photo: string;
  parents: any;
  class: any;
  class_id: string;
  email: string;
  user_role: any;
  attendance?: {
    is_absent: boolean;
    absent_reason: string | null;
  };
  planning_absent?: {
    absent_reason: string,
    end_date: string,
    start_date: string
  };
  tmp_password: string;
  migrations?: any;

  constructor(items) {
    super();
    if (items.parents) {
      super.loadRelations({
        parents: [ParentModel],
      }, items);
    }

    super.loadFields(items);
  }

}
