import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {HelperService} from '../../services/helper.service';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  private dragStarted = false;

  @Input() sizeLimitationMb = 5;
  @Input() types: string[];
  @Input() multi = false;
  @Input() text: string;
  @Input() disabled = false;

  @Output() uploadImages: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }


  @HostListener('drag', ['$event'])
  @HostListener('dragstart', ['$event'])
  onDrag(event: any) {
    this.preventEvent(event);
  }

  @HostListener('dragover', ['$event'])
  @HostListener('dragenter', ['$event'])
  onDragover(event: any) {
    this.dragStarted = true;
    this.preventEvent(event);
  }

  @HostListener('dragleave', ['$event'])
  @HostListener('dragend', ['$event'])
  onDragleave(event: any) {
    this.dragStarted = false;

    this.preventEvent(event);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: any) {
    if (this.dragStarted) {
      const files = Array.from(event.dataTransfer.files);
      if (files.length) {
        this.dragStarted = false;
        this.getDataImage(files).then((urls: string[]) => {
          const rez = Array.from(files as File[]).map((file, i) => {
            return {
              file,
              url: urls[i]
            };
          });
          this.uploadImages.emit(rez);
        });
      }
    }
    this.preventEvent(event);
  }

  loadFile(target, event) {
    const files = target.files;
    if (files.length) {
      this.getDataImage(files).then((urls: string[]) => {
        const rez = Array.from(files as File[]).map((file, i) => {
          return {
            file,
            url: urls[i],
          };
        });
        this.uploadImages.emit(rez);
      });
    }
    this.preventEvent(event);
  }

  checkFiles(files) {
    const allowSize = HelperService.isAllowedSize(files as File[], 1024 * this.sizeLimitationMb);
    const allowType = HelperService.isAllowedMimeType(files as File[], this.types);
    return allowSize && allowType;
  }

  getDataImage(files) {
    const pArray = Array.from(files).filter((file) => {
      return this.checkFiles(file);
    }).map((file) => HelperService.getDataImageFromFile(file as File));

    return Promise.all(pArray);
  }

  preventEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

}
