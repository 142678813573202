import {AfterViewInit, Component, OnInit, HostListener, ChangeDetectorRef} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {switchMap, takeWhile, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MyCabinetService} from '../../../functional-modules/my-cabinet/my-cabinet.service';
import {RootService} from '../../services/root.service';

@Component({
  selector: 'app-homeworks-popup',
  templateUrl: './homeworks-popup.component.html',
  styleUrls: ['./homeworks-popup.component.scss']
})
export class HomeworksPopupComponent implements OnInit, AfterViewInit {
  openedModal = true;
  loaded = false;
  userRole;

  modalData: {
    role: string,
    photos: string[],
    selected_photo: string,
    showHomeworkButtons: boolean,
    isControl: boolean
  };
  mode: 'view' | 'edit' = 'view';

  slideIndex = 1;

  constructor(private ngxSmartModalService: NgxSmartModalService,
              private changeDetectionRef: ChangeDetectorRef,
              private myCabinetService: MyCabinetService,
              private rootService: RootService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.userRole = this.rootService.role.getRoleType();
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('homeworks-popup')
      .onOpenFinished
      .pipe(
        takeWhile(() => this.openedModal)
      ).subscribe(() => {
      this.loaded = true;

      this.modalData = this.ngxSmartModalService.getModal('homeworks-popup').getData();
      this.slideIndex = this.modalData.photos.findIndex(photo => photo === this.modalData.selected_photo);
      if (this.modalData.photos.length) {
        this.showSlides(this.slideIndex);
      }
    });

    this.ngxSmartModalService.getModal('homeworks-popup')
      .onClose
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.modalData = null;
        this.slideIndex = null;
      });
  }

  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }


  showSlides(n) {
    if (n + 1 > this.modalData.photos.length) {
      this.slideIndex = 0;
    }
    if (n < 0) {
      this.slideIndex = this.modalData.photos.length - 1;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const key = event.key;

    if (key === 'ArrowLeft' && this.modalData) {
      this.plusSlides(-1);
    }

    if (key === 'ArrowRight' && this.modalData) {
      this.plusSlides(+1);
    }
  }

  close() {
    this.ngxSmartModalService.getModal('homeworks-popup').close();
  }

  editHw() {
    this.mode = 'edit';

    const curFile = {
      file_url: this.modalData.photos[this.slideIndex],
      id: null,
    };
    const newFiles = this.modalData.photos.map(photo => {
      return {
        file_url: photo,
        id: null,
      };
    });

    window.localStorage.setItem('homework-files', JSON.stringify(newFiles));
    window.localStorage.setItem('current-homework-file', JSON.stringify(curFile));
    window.localStorage.setItem('is-control', this.modalData.isControl ? '1' : '');
    const params = {
      lesson: this.route.snapshot.queryParams.lesson
    };

    if (this.route.snapshot.queryParams.class_group) {
      params['class_group'] = this.route.snapshot.queryParams.class_group;
    }

    this.router.navigate(['do-homework'], {
      relativeTo: this.route, queryParams: params
    });
  }

  cancel() {
    return;
  }

  save() {
    return;
  }
}


