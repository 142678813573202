import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {HelperService} from '../../../services/helper.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SettingsContactModel} from '../../../models/school-management/settings-contact.model';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {RootService} from '../../../services/root.service';
import {UsersService} from '../../../../functional-modules/users/users.service';
import {AuthService} from '../../../services/auth.service';
import {debounceTime, takeWhile, tap} from 'rxjs/operators';
import {zip} from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'school-address-popup',
  templateUrl: './school-address-popup.component.html',
  styleUrls: ['./school-address-popup.component.scss', '../../../style/popups-shared.scss']
})
export class SchoolAddressPopupComponent implements AfterViewInit, OnDestroy {

  work = true;
  loaded = false;
  modalData: any = {};
  wasSubmitted = false;
  contactsFormError: any = {
    region_id: HelperService.formErrors.requiredField,
    city: HelperService.formErrors.requiredField,
    email: HelperService.formErrors.incorrectFormat,
    zip: HelperService.getErrMessage(HelperService.formErrors.incorrectFormat, ' (приклад - 00000)'),
    phone: HelperService.getErrMessage(HelperService.formErrors.incorrectFormat, `<br> (приклад - ${HelperService.phoneSample})`),
  };
  contactsFormGroup: FormGroup;
  regions = [];
  settingsContactModel: SettingsContactModel;
  zipMask = '00000';

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public rootService: RootService,
              public usersService: UsersService,
              public changeDetectorRef: ChangeDetectorRef,
              public authService: AuthService,
              public fb: FormBuilder) {

  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('school-address-popup')
      .onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.initForm();
      });
  }

  ngOnDestroy(): void {
    this.work = false;
  }

  initForm() {
    this.modalData = this.ngxSmartModalService.getModal('school-address-popup').getData();
    this.wasSubmitted = false;
    this.loaded = false;

    zip(
      this.managementService.getSchoolContacts(),
      this.loadRegions()
    ).subscribe(([data]) => {
      this.settingsContactModel = new SettingsContactModel(_.omit(_.cloneDeep(data), ['principal', 'vice_principal']));

      this.contactsFormGroup = this.fb.group({
        region_id: [this.settingsContactModel.region_id, Validators.required],
        address: [this.settingsContactModel.address],
        city: [this.settingsContactModel.city, Validators.required],
        zip: [this.settingsContactModel.zip, Validators.minLength(5)],
        email: [
          this.settingsContactModel.email,
          Validators.pattern(HelperService.emailPattern),
        ],
        phone: [this.settingsContactModel.phone, Validators.compose([Validators.minLength(21), Validators.pattern(HelperService.phonePattern)])],
      });

      this.contactsFormGroup.valueChanges
        .pipe(debounceTime(200))
        .subscribe((rez) => {
          _.forOwn(rez, (val, key) => {
            this.settingsContactModel[key] = val;
          });
        });

      this.loaded = true;
    });
  }

  loadRegions() {
    return this.managementService.getRegions().pipe(tap((data) => {
      this.regions = data.map(({id, name}) => {
        return {
          id: String(id),
          text: name
        };
      });
    }));
  }

  save() {
    this.wasSubmitted = true;

    if (this.contactsFormGroup.valid) {
      const contactsInfo = _.pick(this.settingsContactModel, [
        'region_id',
        'city',
        'address',
        'zip',
        'phone',
        'email'
      ]);

      this.loaded = false;
      this.managementService.updateSchoolContacts(contactsInfo).subscribe(() => {
        if (this.modalData.onSave) {
          this.modalData.onSave();
        }
        this.cancel();
      });
    }
  }

  cancel() {
    this.ngxSmartModalService.getModal('school-address-popup').close();
  }

  getContactsFieldState(field) {
    if (!this.wasSubmitted) {
      return null;
    }

    return this.contactsFormGroup.get(field).valid;
  }

}
