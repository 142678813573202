import {Injectable} from '@angular/core';
import {CanDeactivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {CanComponentDeactivate} from '../interfaces/journal/can-component-deactivate';


@Injectable()
export class DiactivateRouteGuard implements CanDeactivate<any> {

  constructor(public router: Router,
              public authService: AuthService) {

  }

  canDeactivate(component: CanComponentDeactivate) {
    if (!this.authService.isAuth()) {
      return true;
    }
    return component.canDeactivate ? component.canDeactivate() : true;
  }

}
