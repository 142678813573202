import {Base} from '../base.model';
import {HelperService} from '../../services/helper.service';


export class SettingsContactModel extends Base {

  address: string;
  zip: string;
  email: string;
  region_id: string;
  city: string;
  phone: string;

  constructor(items) {
    super();
    super.loadFields(items);
    super.formatPhone('phone');
  }

  haveKeyProps() {
    return ['city', 'region_id'].every(p => !!this[p]);
  }

}
