import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {UsersService} from '../../../functional-modules/users/users.service';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss']
})
export class CreateNewPasswordComponent implements AfterViewInit, OnDestroy {
  loaded = false;
  isMarkedErrors = false;
  openedModal = true;

  form: FormGroup;
  formError: any = {
    required: HelperService.formErrors.requiredField
  };

  constructor(private usersService: UsersService,
              private fb: FormBuilder,
              private ngxSmartModalService: NgxSmartModalService,
              public changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('change-password')
      .onOpenFinished
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.isMarkedErrors = false;

        this.initForm();
        this.loaded = true;
      });

    this.ngxSmartModalService.getModal('change-password')
      .onClose
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.form = null;
        this.isMarkedErrors = false;
      });
  }

  ngOnDestroy() {
    this.openedModal = false;
  }

  initForm() {
    this.form = this.fb.group({
      oldPassword: ['', Validators.compose([Validators.required]
      )],
      newPasswordSection: this.fb.group({
          newPassword: ['', Validators.compose([Validators.required,
            Validators.pattern(HelperService.passwordPattern)]
          )],
          checkNewPassword: ['', Validators.required]
        },
        {
          validator: this.checkNewPasswords('newPassword', 'checkNewPassword')
        })
    });
  }

  getFieldState(field, group = null) {

    if (!this.form || !this.isMarkedErrors) {
      return null;
    }

    if (group === null) {
      return this.form.get(field).valid;
    } else {
      return this.form.get(group).get(field).valid && this.form.get(group).valid;
    }
  }

  getErrorMessage(control, group) {
    if (this.form) {
      if (group) {
        const controlHasError = this.form.get(group).get(control).errors;
        const groupHasError = this.form.get(group).errors;
        if (controlHasError && this.form.get(group).get(control).errors.pattern) {
          if (this.form.get(group).get(control).value.length < 6) {
            return HelperService.passwordLengthError;
          } else {
            return HelperService.passwordError;
          }
        } else if (controlHasError && this.form.get(group).get(control).errors.required) {
          return this.formError.required;
        } else if (!controlHasError && groupHasError && this.form.get(group).errors.newPasswordsValidation) {
          return 'Нові паролі не співпадають';
        }
      } else {
        const controlHasError = this.form.get(control).errors;
        if (controlHasError && this.form.get(control).errors.required) {
          return this.formError.required;
        } else if (controlHasError && this.form.get(control).errors.incorrect) {
          return 'Неправильний старий пароль';
        }
      }
    }
  }

  checkNewPasswords = (newPas, confirmPas): ValidatorFn => (control: AbstractControl) => {
    if (control.get(newPas).value === control.get(confirmPas).value) {
      return null;
    }
    return {newPasswordsValidation: {same: false}};
  }

  save() {
    if (this.form.valid) {

      const sendData = {
        old_password: this.form.get('oldPassword').value,
        new_password: this.form.get('newPasswordSection').get('newPassword').value
      };
      this.usersService.createNewPassword(sendData).subscribe(response => {
        this.ngxSmartModalService.getModal('change-password').getData().onSave();
        this.ngxSmartModalService.getModal('change-password').close();
      }, error => {
        if (error.error.old_password) {
          this.form.controls.oldPassword.setErrors({incorrect: true});
          this.save();
        }
      });
      return;
    } else {
      this.isMarkedErrors = true;
    }
  }

  cancel() {
    this.ngxSmartModalService.getModal('change-password').close();
  }

}
