import {Base} from '../base.model';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export class ExcludedDaysModel extends Base {
  id: string;
  start_day: string;
  end_day: string;
  name: string;
  startTimeStamp: number;
  endTimeStamp: number;

  public static getTypeId(type) {
    switch (type) {
      case 'vacations':
        return 1;
      case 'holidays':
        return 2;
      case 'quarantines':
        return 3;
    }
  }

  constructor(itemData) {
    super();
    super.loadFields(itemData);
    this.startTimeStamp = this.getUnixTimestamp('start_day');
    this.endTimeStamp = this.getUnixTimestamp('end_day');
  }

  getNgDateFormat(name): NgbDate {
    const [year, month, date] = this[name].split('-');
    const dateModel = new Date(`${year}/${month}/${date}`);

    return new NgbDate(dateModel.getFullYear(), dateModel.getMonth() + 1, dateModel.getDate());
  }

  setDate(dateField, date: NgbDate) {
    this[dateField] = moment(new Date(`${date.year}/${date.month}/${date.day}`)).format('YYYY-MM-DD');
  }

  getUnixTimestamp(name) {
    const [year, month, date] = this[name].split('-');
    const dateModel = new Date(`${year}/${month}/${date}`);

    return dateModel.getTime();
  }

}
