import {Injectable,} from '@angular/core';
import {AjaxService} from '../../+shared/services/ajax.service';
import {RootService} from '../../+shared/services/root.service';
import * as _ from 'lodash';
import { RoleModel } from '../../+shared/models/role.model';
import { ClassesService } from '../classes/classes.service';
import { AuthService } from '../../+shared/services/auth.service';
import * as moment from 'moment/moment';

@Injectable()
export class PollsService {

    tabs = [];
    rolesParams = {};
    allRoles = [];
    allPolls = [];
    allClasses = [];
    lastQuestion = null;

    constructor(public ajaxService: AjaxService,
                public classesService: ClassesService,
                public authService: AuthService,
                public rootService: RootService) {
        this.rootService.initialize$
            .subscribe(() => {
            this.initFilters();
            this.updatePolls();
            this.getClasses();
        });

        this.authService
            .auth$
            .subscribe((isAuth) => {
                if (isAuth) {
                    this.updatePolls();
                }
            });

        this.classesService.updateClasses$.subscribe(() => {
            this.getClasses();
        });
    }

    updatePolls() {
        if (this.rootService.role.isAdmin()) {
            return [];
        }

        this.getQuestions().subscribe((rez) => {
            this.allPolls = rez;
            this.getLastQuestion();
        });
    }

    missedPolls() {
        if (this.rootService.role.isAdmin()) {
            return [];
        }

        return this.allPolls.filter(q => {
            const isVoted = q.choices.some(c => !!c.user_vote);

            return !q.is_finished && !isVoted;
        });
    }

    getLastQuestion() {
        this.lastQuestion = this.missedPolls().map((q) => {
            q.timeStamp = moment(q.created_at).unix();

            return q;
        }).sort((q) => {
            return q.timeStamp > q.timeStamp ? 1 : -1;
        })[0];
    }

    initFilters() {
        this.rolesParams = {
            teachers: [
                _.find(this.rootService.allRoles, ['name', 'teacher']).id,
                _.find(this.rootService.allRoles, ['name', 'teacher_tutor']).id
            ],
            parents: [
                _.find(this.rootService.allRoles, ['name', 'parent']).id,
            ],
            students: [
                _.find(this.rootService.allRoles, ['name', 'student']).id,
            ],
            administration: [
                ...(_.chain(this.rootService.allRoles).filter((role: RoleModel) => role.staff_group === '4').map(role => role.id).value())
            ],
            others: [
                ...(_.chain(this.rootService.allRoles).filter((role: RoleModel) => role.staff_group === '2').map(role => role.id).value())
            ]
        };

        _.forOwn(this.rolesParams, (array: any[]) => {
            this.allRoles.push(...array);
        });
    }

    getClasses() {
        this.classesService.getAllClasses().subscribe((classes) => {
            this.allClasses = classes.map((i) => {
                return i.id;
            });
        });
    }

    getSectionPath(path = '') {
        const section = `${this.rootService.role.getSectionPath()}/polls`;
        return `${section}/${path}`;

    }

    getQuestions(data = {}) {
        return this.ajaxService.get('/school/questions/', data);
    }

    createQuestion(data) {
        return this.ajaxService.post('/school/questions/', data, false, true);
    }

    updateQuestion(data) {
        return this.ajaxService.put('/school/questions/' + data.id + '/', data, true, true);
    }

    deleteQuestion(data) {
        return this.ajaxService.delete('/school/questions/' + data.id);
    }

    vote(choice, userVote) {
        if (userVote) {
            return this.ajaxService.put('/school/votes/' + userVote + '/', {choice});
        } else {
            return this.ajaxService.post('/school/votes/', {choice});
        }
    }

    createChoice(data) {
        return this.ajaxService.post('/school/choices/', data);
    }

    deleteChoice(id) {
        return this.ajaxService.delete('/school/choices/' + id);
    }


    updateChoice(data) {
        return this.ajaxService.put('/school/choices/' + data.id + '/', data);
    }

}
