import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeZeroDate'
})
export class RemoveZeroDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const [dayDate, month] = value.split(' ');
    const isNumber = parseInt(dayDate, 10);
    if (isNumber) {
      return isNumber + ' ' + month;
    } else {
      return value;
    }
  }

}
