import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { ManagementService } from '../../../functional-modules/management/management.service';
import { ExcludedDaysModel } from '../../models/school-management/excluded-days.model';
import { SemesterModel } from '../../models/school-management/semester.model';
import { HelperService } from '../../services/helper.service';
import { RootService } from '../../services/root.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription, zip } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-education-schedule',
  templateUrl: './education-schedule.component.html',
  styleUrls: ['./education-schedule.component.scss', '../../style/modules-shared.scss']
})
export class EducationScheduleComponent implements OnInit, OnDestroy {

  educationYears = [];
  loaded = false;
  requestSubscription: Subscription;

  semesters: SemesterModel[] = [];
  vacations: ExcludedDaysModel[] = [];
  holidays: ExcludedDaysModel[] = [];
  quarantines: ExcludedDaysModel[] = [];

  @Output() openPopup: EventEmitter<any> = new EventEmitter<any>();

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public rootService: RootService) {
  }

  ngOnInit() {
    this.educationYears = [
      this.managementService.schoolSettings.semesters[0].start_date.split('-')[0],
      this.managementService.schoolSettings.semesters[1].end_date.split('-')[0],
    ];

    this.load();
  }

  ngOnDestroy(): void {
    this.dropRequest();
  }

  load() {
    this.loaded = false;
    this.dropRequest();

    this.vacations = [];
    this.holidays = [];
    this.quarantines = [];
    this.semesters = [];

    HelperService.upScroll();
    this.requestSubscription = zip(
      this.managementService.getSchoolSemesters(),
      this.managementService.getSchoolExcludedDays()
    ).subscribe(([semesters, excludedDays]) => {
      _.forOwn(excludedDays, (days, type_id) => {
        days = days.map((item) => {
          return new ExcludedDaysModel(item);
        });
        if (type_id === '1') {
          this.vacations.push(...days);
        } else if (type_id === '2') {
          this.holidays.push(...days);
        } else if (type_id === '3') {
          this.quarantines.push(...days);
        }
      });

      this.vacations = _.orderBy(this.vacations, ['endTimeStamp'], ['asc']);
      this.holidays = _.orderBy(this.holidays, ['startTimeStamp'], ['asc']);
      this.quarantines = _.orderBy(this.quarantines, ['startTimeStamp'], ['asc']);
      this.semesters = semesters.map((semester) => new SemesterModel(semester));
      this.loaded = true;
    });
  }

  dropRequest() {
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
      this.requestSubscription = null;
    }
  }

  edit(type) {
    this.openPopup.emit(type);
    switch (type) {
      case 'semesters':
        this.ngxSmartModalService.resetModalData('school-semesters');
        this.ngxSmartModalService.getModal('school-semesters').setData({
          onSave: () => {
            this.load();
          }
        });
        this.ngxSmartModalService.getModal('school-semesters').open();
        break;
      case 'vacations':
        this.ngxSmartModalService.resetModalData('school-vacations');
        this.ngxSmartModalService.getModal('school-vacations').setData({
          onSave: () => {
            this.load();
          }
        });
        this.ngxSmartModalService.getModal('school-vacations').open();
        break;
      case 'holidays':
        this.ngxSmartModalService.resetModalData('school-holidays');
        this.ngxSmartModalService.getModal('school-holidays').setData({
          onSave: () => {
            this.load();
          }
        });
        this.ngxSmartModalService.getModal('school-holidays').open();
        break;
      case 'quarantines':
        this.ngxSmartModalService.resetModalData('school-quarantines');
        this.ngxSmartModalService.getModal('school-quarantines').setData({
          onSave: () => {
            this.load();
          }
        });
        this.ngxSmartModalService.getModal('school-quarantines').open();
    }
  }

  getDate(date) {
    const [year, month, day] = date.split('-');
    const currDate = new Date();
    const dateModel = moment([currDate.getFullYear(), month - 1, day]);

    return dateModel.lang('uk').format('D MMMM');
  }

}
