import {AfterViewChecked, AfterViewInit, Component, Input, OnDestroy} from '@angular/core';

import {IDropdownData} from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';

import {switchMap, takeWhile, tap} from 'rxjs/operators';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import * as _ from 'lodash';
import {IClassStudentsList} from '../../../functional-modules/classes/_partial/interfaces/class-students-list.interface';

@Component({
  selector: 'update-class-student-list',
  templateUrl: './update-class-student-list.component.html',
  styleUrls: ['./update-class-student-list.component.scss', '../../../functional-modules/classes/_partial/components/new-class/new-class.component.scss', '../../style/popups-shared.scss']
})
export class UpdateClassStudentListComponent implements AfterViewInit, OnDestroy {
  openedModal = true;
  saving = false;
  loaded = false;
  closing = false;
  allStudentsWithoutClasses: IDropdownData<string>[] = [];
  allStudentsWithoutClassesStarted: IDropdownData<string>[] = [];
  selectedStudents: IDropdownData<string>[] = [];
  noStudentsWithoutClasses = [{
    id: null,
    text: 'Відсутні учні без присвоєного класу'
  }];

  pageCheckbox = 0;
  checkboxList = {};
  checkedStudent = false;

  @Input() classToEditId: string;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              private classesService: ClassesService) {
  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('update-class-student-list')
      .onOpen
      .pipe(
        takeWhile(() => this.openedModal),
        switchMap(() => {
          this.closing = false;
          this.loaded = false;
          return this.classesService.getStudentsWithoutClass()
            .pipe(
              tap(studentsData => {
                this.allStudentsWithoutClasses = studentsData
                  .map(student => {
                    return {
                      id: student.uuid,
                      photo: student.photo,
                      text: student.last_name + ' ' + student.first_name + ' ' + (student.surname ? student.surname : ''),
                      first_name: student.first_name,
                      last_name: student.last_name,
                    };
                  });
                this.allStudentsWithoutClassesStarted = _.cloneDeep(this.allStudentsWithoutClasses);
              })
            );
        })
      ).subscribe(() => {
      this.loaded = true;
    });

    this.ngxSmartModalService.getModal('update-class-student-list')
      .onClose
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.selectedStudents = [];
        this.closing = true;
      });
  }

  ngOnDestroy() {
    this.openedModal = false;
  }

  addStudentToClass(newStudent: IDropdownData<any>) {
    this.allStudentsWithoutClasses = this.allStudentsWithoutClasses.filter(student => student.id !== newStudent.id);
    this.selectedStudents.push(newStudent);
  }

  removeStudentFromClass(studentId: string, studentInfo) {
    this.allStudentsWithoutClasses = [...this.allStudentsWithoutClasses, {
      id: studentInfo.id,
      photo: studentInfo.photo,
      text: studentInfo.text,
      first_name: studentInfo.first_name,
      last_name: studentInfo.last_name,
    }];
    delete this.checkboxList[studentId];
    const index = this.selectedStudents.findIndex(student => student.id === studentId);
    return this.selectedStudents.splice(index, 1);
  }

  selectAllList(state) {
    this.selectedStudents.forEach(({id}) => {
      this.checkboxList[id] = state;
    });
    this.checkedStudent = true;
  }

  onChangeCheckbox() {
    _.forOwn(this.checkboxList, value => {
      if (this.pageCheckbox) {
        this.pageCheckbox = value;
      }
    });
  }

  removeAllSelected() {
    const filtered = this.selectedStudents.filter(student => {
      return !this.checkboxList[student.id];
    });
    if (filtered.length) {
      this.allStudentsWithoutClasses = this.allStudentsWithoutClassesStarted
        .filter(student => !filtered.find(selStudent => selStudent.id === student.id));
    } else {
      this.allStudentsWithoutClasses = this.allStudentsWithoutClassesStarted;
    }
    this.checkboxList = {};
    this.pageCheckbox = 0;
    return this.selectedStudents = filtered;
  }

  isChecked() {
    for (const key in this.checkboxList) {
      if (this.checkboxList[key]) {
        return true;
      }
    }
    return false;
  }

  action(type: string) {
    if (type === 'cancel') {
      this.ngxSmartModalService.get('update-class-student-list').close();
      this.closing = true;
    } else if (type === 'save' && this.selectedStudents.length === 0) {
      this.ngxSmartModalService.get('update-class-student-list').close();
      this.closing = true;
    } else {
      const students_uuid = this.selectedStudents.map(student => student.id);
      const updateClassStudentList: IClassStudentsList = {class_id: this.classToEditId, uuid_list: [...students_uuid]};

      this.saving = true;
      this.classesService.updateClassStudentList(updateClassStudentList).subscribe(response => {
        this.saving = false;
        this.ngxSmartModalService.get('update-class-student-list').close();
        this.closing = true;
        this.classesService.updateClassInfo$.next(response);
      }, (error => {
        console.log(error);
      }));
    }
  }
}
