import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'inline-btn',
  templateUrl: './inline-btn.component.html',
  styleUrls: ['./inline-btn.component.scss']
})
export class InlineBtnComponent implements OnInit {

  @Input() color = '#023b84';
  @Input() iconSrc: string;
  @Input() iconSize: string[];
  @Input() text: string;
  @Input() disabled: boolean|1 = false;
  @Input() fontSize = 16;

  constructor() {
  }

  ngOnInit(): void {
  }
}
