import {AfterViewInit, Component, OnDestroy} from '@angular/core';

import {takeWhile} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {HelperService} from '../../services/helper.service';
import {Subscription} from 'rxjs';
import {ParentsService} from "../../../parents/parents.service";

@Component({
  selector: 'student-attendance',
  templateUrl: './student-attendance.component.html',
  styleUrls: ['./student-attendance.component.scss', '../../style/popups-shared.scss']
})
export class StudentAttendanceComponent implements AfterViewInit, OnDestroy {
  openedModal = true;
  saving = false;
  loaded = false;
  closing = false;
  isMarkedErrors = false;
  dataSend = false;
  formError: any = {
    from_date: HelperService.formErrors.requiredField,
    to_date: HelperService.formErrors.requiredField,
    // other_reasons: HelperService.formErrors.requiredField,
  };
  datesError: string | null;
  modalData: any;

  selectedReason: any;
  currentDate: NgbDate;

  form: FormGroup;
  formSubs: Subscription;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              private fb: FormBuilder,
              public classesService: ClassesService,
              public parentsService: ParentsService) {
  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('student-attendance')
      .onOpenFinished
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.isMarkedErrors = false;
        this.closing = false;
        this.loaded = false;
        this.dataSend = false;
        const currDate = new Date();
        this.currentDate = new NgbDate(currDate.getFullYear(), currDate.getMonth() + 1, currDate.getDate());
        this.modalData = this.ngxSmartModalService.getModal('student-attendance').getData();

        this.initForm();
        this.loaded = true;
        // this.onChanges();
      });

    this.ngxSmartModalService.getModal('student-attendance')
      .onClose
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        if (!this.dataSend) {
          this.classesService.updateClassInfo$.next('status not changed');
        }
        this.isMarkedErrors = false;
        this.closing = true;
        this.form = null;
      });
  }

  ngOnDestroy() {
    this.openedModal = false;
  }

  initForm() {
    this.form = null;
    if (this.modalData.userRole === 'parent') {
      this.form = this.fb.group({
        reason: [this.modalData.edit ? this.modalData.planning_absence.absent_reason :
          'У зв’язку з поганим самопочуттям', Validators.required],
        // other_reasons: this.fb.array([]),
        dates: this.fb.group({
          from_date: [this.modalData.edit ? HelperService.getNgbDateFormat(this.modalData.planning_absence.start_date) :
            '', Validators.required],
          to_date: [this.modalData.edit ? HelperService.getNgbDateFormat(this.modalData.planning_absence.end_date) :
            '', Validators.required],
        }, {
          validators: this.checkDates.bind(this),
        })
      });
    } else {
      this.form = this.fb.group({
        reason: [this.modalData.edit ? this.modalData.planning_absence.absent_reason :
          'У зв’язку з поганим самопочуттям', Validators.required],
        // other_reasons: this.fb.array([]),
      });
    }
  }

  /*  onChanges() {
      this.formSubs = this.form.get('reason').valueChanges.subscribe(value => {
        this.selectedReason = value;
        if (this.selectedReason === 'Інше' && !this.other_reasons.controls.length) {
          return this.addOtherReasonField();
        } else if (this.selectedReason === 'Інше' && this.other_reasons.controls.length) {
          return this.other_reasons;
        } else {
          return this.removeOtherReasonField();
        }
      });
    }

    addOtherReasonField() {
      const control = new FormControl(null, Validators.required);
      this.other_reasons.push(control);
    }

    removeOtherReasonField() {
      this.other_reasons.removeAt(0);
    }*/

  get reason() {
    return this.form.get('reason');
  }

  get from_date() {
    return this.form.get('dates').get('from_date');
  }

  get to_date() {
    return this.form.get('dates').get('to_date');
  }

  /*  get other_reasons() {
      return this.form.get('other_reasons') as FormArray;
    }*/

  /*  getControls(form) {
      return (form.get('other_reasons') as FormArray).controls;
    }*/

  getFieldState(field, group = null) {

    if (!this.form || !this.isMarkedErrors) {
      return null;
    }

    if (group === null) {
      return this.form.get(field).valid;
    } else {
      return this.form.get(group).get(field).valid;
    }
  }

  action(type, form?: FormGroup) {
    if (type === 'cancel') {
      this.ngxSmartModalService.get('student-attendance').close();
      this.closing = true;
    } else if (type === 'save' && this.form.invalid) {
      this.isMarkedErrors = true;
    } else if (form.valid) {
      const uuid = this.ngxSmartModalService.getModal('student-attendance').getData().id;
      const absent_reason = form.value.reason;

      /* create planning absence */
      if (this.modalData.userRole === 'parent') {

        const fromDateModel: NgbDate = this.from_date.value as NgbDate;
        const toDateModel: NgbDate = this.to_date.value as NgbDate;
        const start_date = moment(new Date(`${fromDateModel.year}/${fromDateModel.month}/${fromDateModel.day}`)).format('YYYY-MM-DD');
        const end_date = moment(new Date(`${toDateModel.year}/${toDateModel.month}/${toDateModel.day}`)).format('YYYY-MM-DD');

        const requestData = {
          uuid,
          start_date,
          end_date,
          absent_reason
        };
        this.modalData.edit ? this.sendRequest(requestData, 'edit') : this.sendRequest(requestData, 'create');

      } else {
        const requestData = {
          uuid,
          absent_reason
        };
        this.sendRequest(requestData, 'create');
      }
    }
  }

  sendRequest(reqData, type, absentId = null) {
    this.saving = true;
    if (type === 'create') {
      this.classesService.addStudentAbsent(reqData).subscribe(response => {
        this.closing = true;
        this.saving = false;
        this.dataSend = true;
        this.ngxSmartModalService.get('student-attendance').close();
        this.classesService.updateClassInfo$.next(response);
        this.parentsService.changeChild$.next(this.modalData.child);
      });
    } else {
      this.classesService.editStudentAbsent(reqData, this.modalData.planning_absence.id).subscribe(response => {
        this.closing = true;
        this.saving = false;
        this.dataSend = true;
        this.ngxSmartModalService.get('student-attendance').close();
        this.classesService.updateClassInfo$.next(response);
        this.parentsService.changeChild$.next(this.modalData.child);
      });
    }
  }

  checkDates(formGroup: AbstractControl): { [error: string]: boolean } {
    const fromDate = formGroup.value.from_date;
    const toDate = formGroup.value.to_date;
    const fromDateValue = new Date(fromDate.year, fromDate.month - 1, fromDate.day).getTime();
    const toDateValue = new Date(toDate.year, toDate.month - 1, toDate.day).getTime();
    const isInvalid = fromDateValue > toDateValue;
    
    if (isInvalid) {
      this.datesError = 'початкова дата не може бути пізніше кінцевої';
    } else {
      this.datesError = null;
    }

    return isInvalid ? {incorrectDates: true} : null;
  }
}
