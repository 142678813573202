import {Base} from './base.model';

export class RoleGroupModel extends Base {

  id: string;
  name: string;

  constructor(items) {
    super();
    super.loadFields(items);
  }

}
