import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {IDropdownData} from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';
import {ClassModel} from '../../models/school-management/class.model';
import {HelperService} from '../../services/helper.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ClassesService} from '../../../functional-modules/classes/classes.service';

import {Subscription, zip} from 'rxjs';
import {takeWhile, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {UsersService} from '../../../functional-modules/users/users.service';

@Component({
  selector: 'edit-general-class-info',
  templateUrl: './edit-general-class-info.component.html',
  styleUrls: [
    './edit-general-class-info.component.scss',
    '../../../functional-modules/classes/_partial/components/new-class/new-class.component.scss',
    '../../style/popups-shared.scss'
  ]
})
export class EditGeneralClassInfoComponent implements AfterViewInit, OnDestroy {
  openedModal = true;
  saving = false;
  loaded = false;
  closing = false;

  allTeachers: IDropdownData<string>[];
  allTutors: IDropdownData<string>[];
  selectedTeacherList: IDropdownData<string>[];
  selectedTutorList: IDropdownData<string>[];
  classGradeList: IDropdownData<number>[];
  subs: Subscription;
  classToEdit: ClassModel;
  formChanges = {};

  previewUrls = {};
  form: FormGroup;
  isMarkedErrors = false;
  logo: {
    file: File,
    url: string
  } = {file: null, url: null};
  formError: any = {
    name: HelperService.formErrors.requiredField,
  };
  nothingChangedError: string;

  @Input() classToEditId: string;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              private formBuilder: FormBuilder,
              public usersService: UsersService,
              private classesService: ClassesService) {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('edit-general-class-info')
      .onOpenFinished
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.isMarkedErrors = false;
        this.closing = false;
        this.loaded = false;

        const classToEditReq = this.classesService.getClassById(this.classToEditId, false, true)
          .pipe(
            tap((classData: ClassModel) => {
              this.classToEdit = classData;
              this.classGradeList = [{
                id: this.classToEdit.grade.id,
                text: /\d/.test(this.classToEdit.grade.name) ? this.classToEdit.grade.name + ' - й' : this.classToEdit.grade.name
              }];
            })
          );

        const getAllTeachersReq = zip(
          this.usersService.getNameUsersList('teacher', {filter_role: 16}),
          this.usersService.getNameUsersList('just_teacher')
        ).pipe(
          tap(([tutors, teachers]) => {
            this.allTeachers = teachers.map(teacher => {
              return {
                id: teacher.uuid,
                text: teacher.last_name + ' ' + teacher.first_name + ' ' + (teacher.surname || '')
              };
            });
            this.allTeachers.unshift({
              id: null,
              text: 'Немає'
            });
            this.allTutors = tutors
              .map(teacher => {
                return {
                  id: teacher.uuid,
                  text: teacher.last_name + ' ' + teacher.first_name + ' ' + (teacher.surname || '')
                };
              });
            this.allTutors.unshift({
              id: null,
              text: 'Немає'
            });
          })
        );

        this.subs = zip(getAllTeachersReq, classToEditReq).subscribe(() => {
          this.selectedTeacherList = this.allTeachers.filter(teacher => {
            if (this.classToEdit.teacher_user) {
              return teacher.id === this.classToEdit.teacher_user.uuid;
            } else {
              return null;
            }
          });
          this.selectedTutorList = this.allTutors.filter(tutor => {
            if (this.classToEdit.tutor_user) {
              return tutor.id === this.classToEdit.tutor_user.uuid;
            } else {
              return null;
            }
          });
          this.initForm();
          this.loaded = true;
        });
      });

    this.ngxSmartModalService.getModal('edit-general-class-info')
      .onClose
      .pipe(takeWhile(() => this.openedModal))
      .subscribe(() => {
        this.isMarkedErrors = false;
        this.closing = true;
      });
  }

  ngOnDestroy() {
    this.openedModal = false;
  }

  initForm() {
    this.form = null;
    this.form = this.formBuilder.group({
      grade: [this.classGradeList[0], Validators.required],
      name: [this.classToEdit.name, Validators.required],
      photo: [this.classToEdit.photo],
      logo: [this.classToEdit.logo],
      teacher_uuid: [this.selectedTeacherList[0] ? this.selectedTeacherList[0].id : ''],
      tutor_uuid: [this.selectedTutorList[0] ? this.selectedTutorList[0].id : '']
    });
  }

  get grade() {
    return this.form.get('grade');
  }

  get name() {
    return this.form.get('name');
  }

  get teacher_uuid() {
    return this.form.get('teacher_uuid');
  }

  get tutor_uuid() {
    return this.form.get('tutor_uuid');
  }

  /* photo and logo parts */

  get classPhoto() {
    return this.form.get('photo');
  }

  onLoadLogo(rez) {
    this.logo = {
      url: rez.url,
      file: rez.file
    };
    this.form.get('logo').setValue(rez.file);
  }

  isPhotoFile(photo: string | File) {
    return photo instanceof File;
  }

  removePhoto() {
    if (this.classPhoto.value instanceof File) {
      delete this.previewUrls[this.getFilePreviewKey(this.classPhoto.value)];
    }

    this.classPhoto.reset('');
  }

  getFilePreviewKey(file: File) {
    return `${file.name}-${file.size}`;
  }

  onLoadPhoto([{file, url}]) {
    this.classPhoto.setValue(file);
    this.formPhotoPreview(file, url);
  }

  getSrcExtension(url) {
    const lastFragment = (_.last(url.split('/')) as string);

    return {
      fileName: lastFragment.split('.')[0] + '.',
      fileExtension: lastFragment.split('.')[1] + '.',
    };
  }

  formPhotoPreview(file: File, url: string) {
    this.previewUrls[this.getFilePreviewKey(file)] = {
      url,
      fileName: file.name.split('.').slice(0, -1).join('') + '.',
      fileExtension: _.last(file.name.split('.')) + '.',
      fileSize: `${String(HelperService.getFileSizeInMB(file))} Mb`
    };
  }

  /* Form errors */

  getFieldState(field, group = null) {

    if (!this.form || !this.isMarkedErrors) {
      return null;
    }

    if (group === null) {
      return this.form.valid;
    } else {
      return this.form.get(group).get(field).valid;
    }
  }

  action(type) {
    if (type === 'cancel') {
      this.ngxSmartModalService.get('edit-general-class-info').close();
      this.closing = true;
    } else if (type === 'save' && this.form.invalid) {
      this.isMarkedErrors = true;
    } else {
      const generalInfo = this.form.value;
      if (typeof generalInfo.logo === 'string') {
        delete generalInfo.logo;
      }
      if (typeof generalInfo.photo === 'string' && generalInfo.photo !== '') {
        delete generalInfo.photo;
      }
      delete generalInfo.class_info;
      const editClassData = {...generalInfo, id: this.classToEdit.id};

      this.saving = true;
      this.classesService.editClassGeneralInfo(editClassData, this.classToEdit.id).subscribe(response => {
        this.saving = false;
        this.ngxSmartModalService.get('edit-general-class-info').close();
        this.closing = true;
        this.classesService.updateClassInfo$.next(response);
        this.classesService.changeClassesList$.next(response);
      }, (error => {
        console.log(error);
      }));
    }
  }
}
