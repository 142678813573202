import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import {environment} from '../../../environments/environment';

@Injectable()
export class AjaxService {
  baseUrl: string = environment.api_url;

  constructor(private http: HttpClient,
              public router: Router) {
  }

  /*
   * AJAX - POST method
   *
   * @param url:string - url for request
   * @param paramsObj: json - optional params
   * @return Observable
   */
  post(url: string, paramsObj?: any, json = false, useArrays = false) {
    const params = !json ? this.formReqData(paramsObj, useArrays) : paramsObj;
    const headers = {enctype: !json ? 'multipart/json' : 'application/json'};

    return this.http.post(this.baseUrl + url, params, {headers: new HttpHeaders(headers)});
  }

  /*
   * AJAX - POST method with file
   *
   * @param url:string - url for request
   * @param paramsObj: json - optional params
   * @return Observable
   */
  file(url: string, paramsObj?: object) {
    const params = new FormData();
    const headers = {enctype: 'multipart/form-data'};

    // Request params
    if (paramsObj && !_.isEmpty(paramsObj)) {
      _.forEach(paramsObj, (val, key) => {
        if (!_.isUndefined(val)) {
          params.append(key, val);
        }
      });
    }

    return this.http.post(this.baseUrl + url, params, {headers: new HttpHeaders(headers)})
      .pipe(map((response: any) => response.result));
  }

  /*
  * AJAX - PUT method with file
  *
  * @param url:string - url for request
  * @param paramsObj: json - optional params
  * @return Observable
  */
  put(url, paramsObj = {}, isResult = true, useArray = false, leaveNull = false) {
    const params = this.formReqData(paramsObj, useArray, leaveNull);
    const headers = {enctype: 'multipart/form-data'};

    return this.http.put(this.baseUrl + url, params, {headers: new HttpHeaders(headers)})
        .pipe(map((response: any) => {
          if (isResult) {
            return response.result;
          } else {
            return response;
          }
        }));
  }

  /*
   * AJAX - PUT method with file
   *
   * @param url:string - url for request
   * @param paramsObj: json - optional params
   * @return Observable
   */
  patch(url, paramsObj) {
    const params = this.formReqData(paramsObj);
    const headers = {enctype: 'multipart/form-data'};

    return this.http.patch(this.baseUrl + url, params, {headers: new HttpHeaders(headers)})
      .pipe(map((response: any) => response.result));
  }

  /*
  * AJAX - GET method
  *
  * @param url:string - url for request
  * @param paramsObj: json - optional params
  * @return Observable
  */
  get(url: string, paramsObj?, endpoint = this.baseUrl): Observable<any> {
    const queryParams = {};

    if (paramsObj) {
      _.forOwn(paramsObj, (val, field) => {
        if (!_.isUndefined(val) && !_.isNull(val) && val !== '') {
          queryParams[field] = val;
        }
      });
    }

    return this.http.get(endpoint + url, {params: queryParams, responseType: 'json'});
  }

  /*
  * AJAX - DELETE method with file
  *
  * @param url:string - url for request
  * @return Observable
  */
  delete(url: string, paramsObj?): Observable<any> {
    const params: any = this.formReqData(paramsObj);
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: params
    };

    return this.http.delete(this.baseUrl + url, httpOptions);
  }

  private formReqData(paramsObj, useArrays = false, leaveNull = false): FormData {
    const params = new FormData();

    // Request params
    if (paramsObj && !_.isEmpty(paramsObj)) {
      _.forEach(paramsObj, (val, key) => {
        if (!_.isUndefined(val) && val !== null && val !== '' || leaveNull) {
          if (_.isArray(val)) {
            if (useArrays) {
              val.forEach((i) => {
                params.append(key, i);
              });
            } else {
              params.append(key, val as any);
            }
          } else {
            params.append(key, val);
          }
        }
      });
    }

    return params;
  }

}
