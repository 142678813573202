import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {takeWhile} from 'rxjs/operators';
import * as _ from 'lodash';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'school-rules-popup',
  templateUrl: './school-rules-popup.component.html',
  styleUrls: ['./school-rules-popup.component.scss', '../../../style/popups-shared.scss']
})
export class SchoolRulesPopupComponent implements AfterViewInit, OnDestroy {

  work = true;
  loaded = false;
  rules = '';
  modalData: any = {};

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService) {
  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('school-rules-popup')
      .onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        this.modalData = this.ngxSmartModalService.getModal('school-rules-popup').getData();
        this.load();
      });
  }

  ngOnDestroy() {
    this.work = false;
  }

  load() {
    this.loaded = false;
    this.managementService.getSchoolRules().subscribe((rez) => {
      this.rules = rez.rules ? _.cloneDeep(rez.rules) : '';
      this.loaded = true;
    });
  }

  cancel() {
    this.ngxSmartModalService.getModal('school-rules-popup').close();
  }

  save() {
    this.managementService.updateSchoolRules({rules: this.rules}).subscribe(() => {
      this.modalData.onSave();
      this.cancel();
    });
  }

  get tinyApiKey() {
    return environment.tinyMceApiKey;
  }

}
