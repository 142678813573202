import {Injectable,} from '@angular/core';
import {AjaxService} from '../../+shared/services/ajax.service';
import {RootService} from '../../+shared/services/root.service';
import {filter} from 'rxjs/operators';

@Injectable()
export class LibraryService {

  tabs = [];

  constructor(public ajaxService: AjaxService, public rootService: RootService) {
    this.rootService.roleChanging$.pipe(filter(role => !!role)).subscribe(() => {
      if (!this.rootService.role.isStudent()) {
        this.tabs = [
          {
            path: this.getSectionPath('/educational-literature'),
            name: 'Навчальна література',
          },
          {
            path: this.getSectionPath('/calendar-plannings'),
            name: 'Календарне планування',
          }
        ];
      } else {
        this.tabs = [
          {
            path: this.getSectionPath('/educational-literature'),
            name: 'Навчальна література',
          }
        ];
      }
    });
  }

  getSectionPath(path) {
    const section = `${this.rootService.role.getSectionPath()}/library`;

    return `${section}/${path}`;
  }

  getLessonPlannings(filter) {
    return this.ajaxService.get('/teacher/lesson-planning/', filter);
  }

  getLessonPlanning(id, for_class = null, for_class_group = null) {
    return this.ajaxService.get(`/teacher/lesson-planning/${id}/`, {for_class, for_class_group});
  }

  deleteLessonPlanning(id) {
    return this.ajaxService.delete(`/teacher/lesson-planning/${id}/`);
  }

  addLessonPlanning(data) {
    return this.ajaxService.post('/teacher/lesson-planning/', data);
  }

  updateLessonPlanning(data) {
    return this.ajaxService.put(`/teacher/lesson-planning/${data.id}/`, data);
  }

  importCPFile(file) {
    return this.ajaxService.post('/teacher/import-lesson-theme/column-get/', {file});
  }

  saveImportColumns(data) {
    return this.ajaxService.post('/teacher/import-lesson-theme/column-upload/', data, true);
  }

  addLessonPlanningTheme(lesson_planning, data) {
    return this.ajaxService.post(`/teacher/lesson-planning-theme/${lesson_planning}/`, data, true);
  }

  clonePlanning(planningId) {
    return this.ajaxService.post(`/teacher/lesson-planning/${planningId}/clone/`);
  }

  assignOneLessonPlanning(params) {
    return this.ajaxService.put(`/teacher/lesson-planning/${params.id}/assign-one/`, params);
  }

  assignLessonPlanning(params) {
    return this.ajaxService.put(`/teacher/lesson-planning/${params.id}/assign/`, params);
  }

  getPrivatePlannings() {
    return this.ajaxService.get('/teacher/private-lesson-planning/');
  }

  getAssigningList(planningId, justConnected) {
    const justConnectedParam = justConnected ? '?just_connected=true' : '';
    return this.ajaxService.get(`/teacher/lesson-planning/${planningId}/assign-list/${justConnectedParam}`);
  }

  addNewTaskToLessonTheme(task_data) {
    return this.ajaxService.post('/teacher/lesson-planning-material/', task_data);
  }

  editThemeTaskDescription(task_data, task_id) {
    return this.ajaxService.put('/teacher/lesson-planning-material/' + task_id + '/', task_data);
  }

  copyLessonMaterials(task_data) {
    return this.ajaxService.put('/teacher/lesson-planning-assign-material/', task_data);
  }

  deleteThemeTask(task_id) {
    return this.ajaxService.delete('/teacher/lesson-planning-material/' + task_id + '/');
  }

  addNewFileToLessonTheme(file_data) {
    return this.ajaxService.post('/teacher/lesson-planning-material-file/', file_data);
  }

  deleteFileFromLessonTheme(file_id) {
    return this.ajaxService.delete('/teacher/lesson-planning-material-file/' + file_id + '/');
  }

  getThemeContentById(theme_id, filter_class = null, filter_class_group = null, is_student = false) {
    return this.ajaxService.get('/teacher/lesson-planning-theme/details/' + theme_id + '/', {filter_class, filter_class_group, is_student});
  }

  changeLessonTheme(theme_data) {
    return this.ajaxService.put('/teacher/lesson-planning-theme/change/', theme_data);
  }

  deleteAllFilesFromLessonTheme(theme_id) {
    return this.ajaxService.delete('/teacher/lesson-planning-theme/delete-material/', theme_id);
  }

  getBooks(data) {
    return this.ajaxService.get('/library/book/', data);
  }

  getBook(id) {
    return this.ajaxService.get(`/library/book/${id}/`);
  }

  addBook(data) {
    return this.ajaxService.post(`/library/book/`, data);
  }

  updateBook(data) {
    return this.ajaxService.put(`/library/book/${data.id}/`, data);
  }

  deleteBook(id) {
    return this.ajaxService.delete(`/library/book/${id}/`);
  }

  exportKtpAsFile(id) {
    return this.ajaxService.get(`/teacher/lesson-planning/${id}/download/`);
  }
}
