import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SettingsContactModel} from '../../../models/school-management/settings-contact.model';
import {ManagementService} from '../../../../functional-modules/management/management.service';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {Subscription, zip} from 'rxjs';
import {UserModel} from '../../../models/user.model';
import {RootService} from '../../../services/root.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {HelperService} from '../../../services/helper.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss', '../../../style/modules-shared.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {

  loaded = false;
  persons: {
    principal: UserModel,
    vice_principal: UserModel
  };
  contacts: SettingsContactModel;
  regions = [];
  reqSubscription: Subscription;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public rootService: RootService,
              public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnDestroy() {
    this.dropRequest();
  }

  load() {
    this.loaded = false;
    HelperService.upScroll();
    this.reqSubscription = zip(this.managementService.getSchoolContacts(), this.loadRegions()).subscribe(([data]) => {
      this.persons = {
        principal: data.principal ? new UserModel(_.cloneDeep(data.principal)) : null,
        vice_principal: data.vice_principal ? new UserModel(_.cloneDeep(data.vice_principal)) : null,
      };
      this.contacts = new SettingsContactModel(_.omit(_.cloneDeep(data), ['principal', 'vice_principal']));
      this.loaded = true;
    });
  }

  loadRegions() {
    return this.managementService.getRegions().pipe(tap((data) => {
      this.regions = data.map(({id, name}) => {
        return {
          id: String(id),
          text: name
        };
      });
    }));
  }

  dropRequest() {
    if (this.reqSubscription) {
      this.reqSubscription.unsubscribe();
      this.reqSubscription = null;
    }
  }

  editAdministration() {
    this.ngxSmartModalService.resetModalData('contacts-info-popup');
    this.ngxSmartModalService.getModal('contacts-info-popup').setData({
      onSave: () => {
        this.load();
      },
    });
    this.ngxSmartModalService.getModal('contacts-info-popup').open();
  }

  editSchoolContacts() {
    this.ngxSmartModalService.resetModalData('school-address-popup');
    this.ngxSmartModalService.getModal('school-address-popup').setData({
      onSave: () => {
        this.load();
      },
    });
    this.ngxSmartModalService.getModal('school-address-popup').open();
  }

  get addressLine() {
    const street = this.contacts.address;
    const city = this.contacts.city;
    const region = _.find(this.regions, ['id', this.contacts.region_id]).text;
    const zip = this.contacts.zip;

    return [street, city, region, zip].filter(i => !!i).join(', ');
  }

}
