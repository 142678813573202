import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {UserModel} from '../models/user.model';
import {RootService} from '../services/root.service';

@Injectable()
export class SectionGuard implements CanActivate {
  constructor(public userService: AuthService,
              public rootService: RootService,
              public router: Router,
              public authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.canLoadAuthGuard()
      .pipe(
        map((isAuth: boolean | UserModel) => {
          if (_.isBoolean(isAuth) && !isAuth) {
            this.router.navigateByUrl('/login');
            return isAuth;
          } else if (_.isObject(isAuth)) {

            if (!isAuth.hasSection(route.data.name)) {
                this.router.navigateByUrl(this.rootService.role.getSectionPath());
                return false;
            }
            return true;
          }
        }),
      );
  }
}
